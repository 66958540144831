<audit-modal title="Actualizar Resultado">
  <form [formGroup]="resultFindingForm" class="main-form">
    <div class="actions-buttons">
      <span
        ngClass="{{ isApply ? 'apply-tag' : 'tag' }}"
        (click)="toggleStatus('apply')"
      >
        <mat-icon>check</mat-icon>
        CUMPLE
      </span>
      <span
        ngClass="{{ isNoApply ? 'no-apply-tag' : 'tag' }}"
        (click)="toggleStatus('no-apply')"
      >
        <mat-icon>close</mat-icon>
        NO CUMPLE
      </span>
      <span
        ngClass="{{ isNotApplicable ? 'not-applicable-tag' : 'tag' }}"
        (click)="toggleStatus('not-applicable')"
      >
        <mat-icon>not_interested</mat-icon>
        NO APLICA
      </span>
    </div>
    <div *ngIf="isNoApply && !isNoApplyFromDatabase">
      <p class="subtitle">Crear Hallazgo</p>
      <a-input label="Descripción">
        <mat-form-field appearance="outline">
          <textarea
            matInput
            placeholder="Descripción"
            formControlName="description"
          ></textarea>
        </mat-form-field>
      </a-input>
      <a-input label="Tipo de hallazgo">
        <mat-form-field appearance="outline">
          <mat-select formControlName="findingTypeId">
            <mat-option
              *ngFor="let option of findingTypesList"
              [value]="option.id"
            >
              {{ option.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </a-input>
      <a-input label="Plazo en días">
        <mat-form-field appearance="outline">
          <input
            matInput
            type="number"
            [min]="0"
            placeholder="Cantidad de días"
            formControlName="limit"
          />
        </mat-form-field>
      </a-input>
      <a-input label="Forma de solucionar">
        <mat-form-field appearance="outline">
          <textarea
            matInput
            placeholder="Forma de solucionar"
            formControlName="solution"
          ></textarea>
        </mat-form-field>
      </a-input>
    </div>
    <div class="button-container">
      <button class="close-button" (click)="cancel()">Cancelar</button>
      <button class="create-button" (click)="submit()" type="submit">
        Actualizar
      </button>
    </div>
  </form>
</audit-modal>
