import { Overlay, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
  ComponentRef,
  Directive, ElementRef, HostListener, Input, TemplateRef,
} from '@angular/core';
import { CustomToolTipComponent } from '../custom-tool-tip/custom-tool-tip.component';

@Directive({
  selector: '[customToolTip]',
})

export class ToolTipRendererDirective {
 /*
    This is used to show the tooltip conditionally
*/
 @Input() showToolTip: boolean = true;

 // If this is specified then text will be shown in the tooltip
 @Input('customToolTip') text: string;

 // If this is specified then template will be rendered in the tooltip
 @Input() contentTemplate: TemplateRef<any>;

 @Input('originX') originX = 'start';
 @Input('originY') originY = 'top';
 @Input('overlayX') overlayX = 'center';
 @Input('overlayY') overlayY = 'bottom';

 private _overlayRef: OverlayRef;

 constructor(
   private _overlay: Overlay,
   private _overlayPositionBuilder: OverlayPositionBuilder,
   private _elementRef: ElementRef,
 ) {}
 /**
   * Init life cycle event handler
   */
 ngOnInit() {
   if (!this.showToolTip) {
     return;
   }

   // You can take the position as an input to adjust the position,
   // for now, it will show at the bottom always; but you can adjust your code
   // as per your need
   const positionStrategy = this._overlayPositionBuilder
     .flexibleConnectedTo(this._elementRef)
     .withPositions([{
       originX: this.originX as any,
       originY: this.originY as any,
       overlayX: this.overlayX as any,
       overlayY: this.overlayY as any,
       offsetY: 5,
     }]);

   this._overlayRef = this._overlay.create({ positionStrategy });
 }

  /**
   * This method will be called whenever the mouse enters in the Host element
   * i.e. where this directive is applied
   * This method will show the tooltip by instantiating the
   * CustomToolTipComponent and attaching to the overlay
   */
  @HostListener('mouseenter')
 show() {
   // Attach the component if it has not already attached to the overlay
   if (this._overlayRef && !this._overlayRef.hasAttached()) {
     const tooltipRef: ComponentRef<CustomToolTipComponent> = this._overlayRef
       .attach(new ComponentPortal(CustomToolTipComponent));
     tooltipRef.instance.text = this.text;
     tooltipRef.instance.contentTemplate = this.contentTemplate;
   }
 }

  /**
   * This method will be called when the mouse goes out of the host element
   * i.e. where this directive is applied
   * This method will close the tooltip by detaching the overlay from the view
   */
  @HostListener('mouseleave')
  hide() {
    this.closeToolTip();
  }

  /**
   * Destroy lifecycle event handler
   * This method will make sure to close the tooltip
   */
  ngOnDestroy() {
    this.closeToolTip();
  }

  /**
   * This method will close the tooltip by detaching the component from the overlay
   */
  private closeToolTip() {
    if (this._overlayRef) {
      this._overlayRef.detach();
    }
  }
}
