import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss'],
})
export class TagsComponent {
  options = false;
  newTagValue: string;

  @Output('update') update = new EventEmitter<number>();
  @Output('delete') delete = new EventEmitter<number>();
  @Output('newTag') newTag = new EventEmitter<string>();

  @Input('tags') tags: Tag[];

  displayOptions() {
    this.options = !this.options;
  }

  createTag() {
    this.newTag.emit(this.newTagValue);
    this.newTagValue = '';
  }

  cancelTag() {
    this.options = !this.options;
  }

  updateTag(tag: Tag) {
    this.update.emit(tag.id);
  }

  deleteTag(tag: Tag) {
    this.delete.emit(tag.id);
  }
}

export interface Tag {
  id?: number;
  name: string;
  completed?: boolean;
}
