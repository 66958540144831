import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmDialogComponent } from 'src/app/modules/common/confirm-dialog/confirm-dialog.component';
import { SnackbarComponent } from 'src/app/modules/common/snackbar/snackbar.component';

const DEFAULT_DURATION = 3000;
const ERROR_DURATION = 8000;

@Injectable({
  providedIn: 'root',
})
export class AlertsService {
  constructor(private snackBar: MatSnackBar, public dialog: MatDialog) {}

  success(
    header: string,
    options?: {
      body?: string;
      duration?: number;
    },
  ) {
    return this.show('success', {
      header,
      body: options?.body,
      duration: options?.duration || DEFAULT_DURATION,
    });
  }

  error(
    header: string,
    options?: {
      body?: string;
      duration?: number;
    },
  ) {
    return this.show('error', {
      header,
      body: options?.body,
      duration: options?.duration || ERROR_DURATION,
    });
  }

  warn(
    header: string,
    options?: {
      body?: string;
      duration?: number;
    },
  ) {
    return this.show('warn', {
      header,
      body: options?.body,
      duration: options?.duration || DEFAULT_DURATION,
    });
  }

  info(
    header: string,
    options?: {
      body?: string;
      duration?: number;
    },
  ) {
    return this.show('info', {
      header,
      body: options?.body,
      duration: options?.duration || DEFAULT_DURATION,
    });
  }

  private show(
    view: string,
    options: {
      header: string;
      body?: string;
      duration: number;
    },
  ) {
    this.snackBar.openFromComponent(SnackbarComponent, {
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: view,
      duration: options.duration,
      data: {
        message: options.header,
        view,
        content: options.body,
      },
    });
  }

  async confirm(message: string, body?: string): Promise<boolean> {
    return this.dialog
      .open(ConfirmDialogComponent, {
        width: '30%',
        minWidth: '400px',
        maxWidth: '95%',
        data: {
          message,
          body,
        },
        autoFocus: false,
      })
      .afterClosed()
      .toPromise()
      .then((data: any): boolean => {
        if (data?.confirmed) {
          return true;
        }
        return false;
      });
  }
}
