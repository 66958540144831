import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EntityType } from 'src/app/enums/enums';
import { DocumentInterface } from 'src/app/interfaces/document.interface';
import { DocumentType } from 'src/app/interfaces/document-type.interface';
import { AlertsService } from 'src/app/services/alerts.service';
import { DocumentTypeService } from 'src/app/services/document-type.service';
import { DocumentService } from 'src/app/services/document.service';
import { EmployeesService } from 'src/app/services/employees.service';
import { VehiclesService } from 'src/app/services/vehicles.service';

const VALID_FILE_FORMAT = ['.png', '.jpg', '.jpeg', '.gif', '.svg'];

@Component({
  selector: 'app-document-form',
  templateUrl: './document-form.component.html',
  styleUrls: ['./document-form.component.scss'],
})
export class DocumentFormComponent {
  docTypes: DocumentType[];
  entities: any[];
  validFileFormat = VALID_FILE_FORMAT;
  image: File | null;
  imageChanged = false;
  preview: any;

  documentForm = new FormGroup({
    name: new FormControl<string | null>(null, Validators.required),
    issued: new FormControl<Date | null>(null, Validators.required),
    documentTypeId: new FormControl<number | null>(null, Validators.required),
    entityId: new FormControl<number | null>(null, Validators.required),
    expiration: new FormControl<Date | null>(null, Validators.required),
    imageUrl: new FormControl<string | null>(null),
  });

  constructor(
    private docTypeService: DocumentTypeService,
    private employeeService: EmployeesService,
    private docsService: DocumentService,
    private vehicleService: VehiclesService,
    private alertsService: AlertsService,
    public dialogRef: MatDialogRef<DocumentFormComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      isClient: boolean;
      isVehicle: boolean;
      document?: DocumentInterface;
    },
  ) {}

  ngOnInit() {
    this.docTypeService
      .findAll({
        entityType: this.data.isClient
          ? EntityType.CLIENT
          : this.data.isVehicle
            ? EntityType.VEHICLE
            : EntityType.COMPANY,
      })
      .subscribe((docTypes) => {
        this.docTypes = docTypes.data;
      });
    if (this.data.isClient) {
      this.employeeService.read().subscribe((employees) => {
        this.entities = employees.data;
      });
    } else if (this.data.isVehicle) {
      this.vehicleService.read().subscribe((vehicles) => {
        this.entities = vehicles.vehicles;
      });
    }
    if (!this.data.isClient && !this.data.isVehicle) {
      const selectedCompanyStr = localStorage.getItem('selectedCompany');
      if (selectedCompanyStr) {
        const selectedCompany = JSON.parse(selectedCompanyStr);
        const entityId = selectedCompany.id;
        this.documentForm.patchValue({ entityId: entityId });
      }
    }
    if (this.data?.document) {
      this.setForm();
    }
  }

  setForm() {
    this.documentForm.patchValue({
      documentTypeId: this.data.document?.documentType.id,
      entityId: this.data.document?.entityId,
      expiration: this.data.document?.expiration,
      name: this.data.document?.name,
      issued: this.data.document?.issued,
      imageUrl: this.data.document?.imageUrl,
    });
  }

  submit() {
    if (this.data?.document) {
      this.docsService
        .update(this.data.document.id, this.documentForm.value, this.image)
        .subscribe({
          next: () => {
            this.dialogRef.close();
            this.alertsService.success('Documento editado con éxito');
          },
          error: (error) => {
            console.log(error);
            this.alertsService.error('Error al editar documento');
          },
        });
    } else {
      this.docsService.create(this.documentForm.value, this.image).subscribe({
        next: () => {
          this.dialogRef.close(true);
          this.alertsService.success('Documento creado con éxito');
        },
        error: (error) => {
          console.log(error);
          this.alertsService.error('Error al crear documento');
        },
      });
    }
  }

  /**
   * Select file event.
   */
  onSelectFile(target: EventTarget | null): void {
    const { files } = target as HTMLInputElement;
    if (files && files.length > 0) {
      this.image = files[0] as File;
      const reader = new FileReader();
      this.imageChanged = true;
      reader.onload = (e: any) => {
        this.preview = e.target.result;
      };
      reader.readAsDataURL(this.image);
    }
  }

  /**
   * Remove file event.
   */
  onRemoveFile(): void {
    this.image = null;
    this.preview = null;
    if (this.data?.document) {
      this.documentForm.patchValue({ imageUrl: '' });
    }
  }

  cancel() {
    this.dialogRef.close();
  }
}
