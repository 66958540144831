import { Injectable } from '@angular/core';
import {
  AUDITABLE_OBJECT,
  AUDITS,
  CLIENTS,
  DASHBOARD,
  DOCUMENTS,
  RESOURCES,
  SERVICES,
  USERS,
} from '../utils/urls';
import { Roles } from '../enums/enums';
import { User } from '../interfaces';

@Injectable({
  providedIn: 'root',
})
export class SidebarService {
  getSidebar(user: User) {
    let sidebar: any[] = [];
    if (user.role === Roles.ADMIN || user.role === Roles.SUPER_ADMIN) {
      sidebar = [
        {
          title: 'Panel de control',
          icon: 'control-panel.svg',
          link: DASHBOARD,
        },
        {
          title: 'Auditorías',
          icon: 'audits.svg',
          collapsed: true,
          items: [
            {
              title: 'Mis Auditorías',
              icon: 'audits.svg',
              link: AUDITS,
            },
            {
              title: 'Servicios',
              icon: 'services.svg',
              link: SERVICES,
            },
            {
              title: 'Auditados',
              icon: 'clients.svg',
              link: CLIENTS,
            },
            {
              title: 'Objetos Auditables',
              icon: 'auditable-object.svg',
              link: AUDITABLE_OBJECT,
            },
          ],
        },
        {
          title: 'Vencimientos',
          icon: 'expirations.svg',
          collapsed: true,
          items: [
            {
              title: 'Recursos',
              icon: 'resources.svg',
              link: RESOURCES,
            },
            {
              title: 'Documentaciones',
              icon: 'documentation.svg',
              link: DOCUMENTS,
            },
          ],
        },
        {
          title: 'Usuarios',
          icon: 'users.svg',
          link: USERS,
        },
      ];
    } else if (user.role === Roles.AUDITOR) {
      sidebar = [
        {
          title: 'Panel de control',
          icon: 'control-panel.svg',
          link: DASHBOARD,
        },
        {
          title: 'Auditorías',
          icon: 'audits.svg',
          collapsed: true,
          items: [
            {
              title: 'Mis Auditorías',
              icon: 'audits.svg',
              link: AUDITS,
            },
            {
              title: 'Servicios',
              icon: 'services.svg',
              link: SERVICES,
            },
            {
              title: 'Auditados',
              icon: 'clients.svg',
              link: CLIENTS,
            },
            {
              title: 'Objetos Auditables',
              icon: 'auditable-object.svg',
              link: AUDITABLE_OBJECT,
            },
          ],
        },
      ];
    } else if (user.role === Roles.CLIENT) {
      sidebar = [
        {
          title: 'Panel de control',
          icon: 'control-panel.svg',
          link: DASHBOARD,
        },
        {
          title: 'Mis Auditorías',
          icon: 'audits.svg',
          link: AUDITS,
        },

      ];
    }
    return sidebar;
  }
}
