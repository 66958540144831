<mat-card class="list-card">
    <div class="finding-titles">
        <span class="audit-code" [routerLink]="onAuditCode()">{{ finding.auditCode }}</span>
        <span class="chip">POR RESOLVER</span>
    </div>
    <span [ngClass]="{'audit-date-expired': isExpired, 'audit-date': !isExpired}">
        <mat-icon>event</mat-icon>{{ finding.date }} ({{daysLeft}} días)
    </span>
    <span class="audit-audited" *ngIf="!isClient"><mat-icon>work_outline</mat-icon>{{ finding.audited }}</span>
    <span class="audit-audited"><mat-icon fontSet="material-icons-outlined">check_box</mat-icon>{{ finding.control }}</span>
    <span class="finding-description"><mat-icon fontSet="material-icons-outlined">error_outline</mat-icon><span
            class="finding-description-text">{{ finding.description }}</span></span>
    <button class="action-button" (click)="createCorrectiveAction()" *ngIf="isExpired && isClient">Generar acción</button>
    <!-- <a class="see-more-finding" *ngIf="(isExpired && !isClient) || (isClient && !isExpired)" (click)="seeMore()">Ver más <mat-icon>arrow_drop_down</mat-icon></a> -->
</mat-card>