<table class="bordered w-100 m-0 mt-4 p-0" align="center">
  <thead>
    <tr class="table-border">
      <th
        *ngFor="let column of displayedColumns"
        (click)="sort(column)"
        [ngClass]="clickableHeader ? 'click' : ''"
      >
        <div class="header-content">
          <span class="column-title">{{ column }}</span>
          <div class="icon-container">
            <mat-icon *ngIf="column == colName && order">
              arrow_drop_down
            </mat-icon>
            <mat-icon *ngIf="column == colName && !order">
              arrow_drop_up
            </mat-icon>
          </div>
        </div>
      </th>
      <th *ngIf="showActionColumn">Acción</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let dat of data; let i = index" align="left">
      <td
        *ngFor="let column of columnsNames"
        [ngClass]="column == 'solved' ? 'col-w' : ''"
        [ngStyle]="{ 'width.px': column === 'name' && showInfo ? 800 : null }"
      >
        <span *ngIf="showColumn(column)">{{ TypeOf(column, dat) }}</span>
        <span
          *ngIf="column == 'value'"
          [ngClass]="resultClass(TypeOf(column, dat))"
          >{{ TypeOf(column, dat) }}</span
        >
        <span
          *ngIf="column == 'approved'"
          class="nowrap"
          [ngClass]="resultClass(TypeOf(column, dat))"
          >{{ TypeOf(column, dat) }}</span
        >
        <span
          *ngIf="column == 'solved'"
          [ngClass]="resultClass(TypeOf(column, dat))"
          >{{ TypeOf(column, dat) }}</span
        >
        <a
          *ngIf="dat[column] && column == 'auditCode'"
          [routerLink]="getAuditsUrl(dat.id)"
          >{{ dat[column] }}</a
        >
        <a
          *ngIf="dat[column] && column == 'audit'"
          [routerLink]="getAuditsUrl(dat[column].id)"
          >{{ dat[column].code }}</a
        >
        <a
          *ngIf="dat[column] && column == 'code'"
          [routerLink]="getAuditsUrl(dat.id)"
          >{{ dat[column] }}</a
        >
        <span
          class="clickable-text"
          *ngIf="column === 'name' && showInfo"
          (click)="read(dat.id)"
          >{{ TypeOf(column, dat) }}</span
        >
        <span *ngIf="dat[column] && column == 'finding'">{{
          dat[column].description
        }}</span>
        <div *ngIf="column === 'locations'">
          <button mat-icon-button (click)="navigateToClientLocations(dat.id)">
            <mat-icon>control_point</mat-icon>
          </button>
        </div>
        <div *ngIf="dat[column] && column === 'fileUrl'">
          <mat-icon class="file-button" (click)="showFile(dat.fileUrl)"
            >file_present</mat-icon
          >
        </div>
        <div *ngIf="column === 'companyLogo'" class="company-logo">
          <img [src]="'../../assets/company-icons/global_icon.svg'" alt="" />
        </div>
      </td>

      <td
        (mouseover)="stylePencil(i)"
        (mouseout)="unstylePencil(i)"
        *ngIf="showActionColumn"
      >
        <div>
          <button
            mat-icon-button
            [matMenuTriggerFor]="menu"
            aria-label="Example icon-button with a menu"
          >
            <svg
              [id]="'pencil-' + i"
              width="16"
              height="17"
              viewBox="0 0 16 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.27 6.0802L10.5493 2.39801L11.775 1.17061C12.1106 0.834541 12.5229 0.666504 13.012 0.666504C13.5005 0.666504 13.9126 0.834541 14.2482 1.17061L15.4738 2.39801C15.8094 2.73408 15.9845 3.13971 15.9991 3.61489C16.0137 4.08948 15.8532 4.49481 15.5176 4.83089L14.27 6.0802ZM0.875463 16.6665C0.627415 16.6665 0.419639 16.5823 0.252133 16.414C0.0840446 16.2463 0 16.0382 0 15.7898V13.3131C0 13.1962 0.0218866 13.0831 0.0656598 12.9738C0.109433 12.8639 0.175093 12.7651 0.262639 12.6775L9.27991 3.64733L13.0006 7.37335L3.98336 16.4035C3.89581 16.4912 3.79747 16.5569 3.68833 16.6008C3.5786 16.6446 3.46537 16.6665 3.34865 16.6665H0.875463Z"
                fill="#D4D4D4"
              />
            </svg>
          </button>
          <mat-menu #menu="matMenu">
            <button
              (click)="update(dat.id)"
              mat-menu-item
              *ngIf="!isCorrectiveActions && !isApproval"
            >
              Modificar
            </button>
            <button (click)="duplicate(dat.id)" mat-menu-item *ngIf="isAudit">
              Duplicar
            </button>
            <button
              (click)="delete(dat.id)"
              mat-menu-item
              *ngIf="!isCorrectiveActions && !isApproval && !onlyEdit"
            >
              Eliminar
            </button>
            <button
              (click)="correctiveActions(dat.id)"
              mat-menu-item
              *ngIf="isCorrectiveActions"
            >
              Crear Acción Correctiva
            </button>
            <button (click)="approve(dat.id)" mat-menu-item *ngIf="isApproval">
              Evaluar
            </button>
          </mat-menu>
        </div>
      </td>
    </tr>
  </tbody>
</table>

<mat-paginator
  [pageSizeOptions]="pages"
  [length]="total"
  (page)="setPaginator($event)"
  [pageSize]="pageSize"
  class="paginator"
  *ngIf="showPaginator"
></mat-paginator>
