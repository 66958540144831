import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Vehicle } from 'src/app/interfaces/vehicle.interface';
import { IPaginatorEvent } from 'src/app/modules/common/table/table.component';
import { DateTransformService } from 'src/app/services/date-transform.service';
import { VehiclesService } from 'src/app/services/vehicles.service';
import { VehicleFormComponent } from './vehicle-form/vehicle-form.component';

@Component({
  selector: 'app-vehicles',
  templateUrl: './vehicles.component.html',
  styleUrls: ['./vehicles.component.scss'],
})
export class VehiclesComponent {
  columns = ['Nombre', 'Descripción', 'Creado por', 'Modificado por'];
  columnsNames = ['name', 'description', 'createdBy', 'updatedBy'];

  vehicles: Vehicle[];

  searchText: string;
  showLoading = false;
  usersList: any = [];
  total = 0;
  offset = 0;
  limit = 25;

  constructor(
    private vehicleService: VehiclesService,
    public dialog: MatDialog,
    private dateTransformService: DateTransformService,
  ) {}

  ngOnInit() {
    this.getVehicles();
  }

  search(query: string) {
    this.offset = 0;
    this.searchText = query;
    this.showLoading = true;
    this.getVehicles();
  }

  handlePageChange(event: IPaginatorEvent) {
    this.offset = event.offset;
    this.limit = event.limit;
    this.getVehicles();
  }

  getVehicles() {
    const params = {
      offset: this.offset,
      limit: this.limit,
      ...(this.searchText && { search: this.searchText }),
    };
    this.vehicleService.read(params).subscribe((res: any) => {
      this.showLoading = false;
      this.vehicles = this.dateTransformService.transformDates(res.vehicles);
      this.total = res.total;
    });
  }

  openModal() {
    this.dialog
      .open(VehicleFormComponent, {
        width: '30%',
        minWidth: '300px',
        autoFocus: false,
      })
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.getVehicles();
        }
      });
  }

  update(event: number) {
    this.vehicleService.findOne(event).subscribe((res) => {
      this.dialog
        .open(VehicleFormComponent, {
          width: '30%',
          minWidth: '300px',
          data: { vehicle: res },
          autoFocus: false,
        })
        .afterClosed()
        .subscribe(() => {
          this.getVehicles();
        });
    });
  }

  delete(event: any) {
    this.vehicleService.delete(event).subscribe(() => {
      this.getVehicles();
    });
  }
}
