<div class="padding-component">
    <h1 class="mb-4">
        Seleccione una compañía
    </h1>
    <div class="d-flex justify-content-between">
        <button class="create-button" (click)="create()">
            Nueva compañía
        </button>
        <button class="close-button" (click)="logout()">Cerrar sesión</button>
    </div>
    <div class="row">
        <!-- COLUMN 1 -->
        <div class="col-sm-12 col-lg-5">
            <app-table [data]="companiesList" [displayedColumns]="columns" [columnsNames]="columnsNames"
                (update)="update($event)" (delete)="delete($event)" (read)="setCompany($event)"
                [showInfo]="true" [showPaginator]="false"></app-table>
        </div>
        <div class="col-sm-12 col-lg-5" *ngIf="showCompanyForm">
            <app-companies-create-update [selectedCompany]="selectedCompany"
                [closeForm]="closeForm.bind(this)"></app-companies-create-update>
        </div>
    </div>
</div>