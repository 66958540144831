import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IQuery } from '../interfaces';
import { ControlsResponse } from '../interfaces/controls-response.interface';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class ControlService extends BaseService {
  constructor(private http: HttpClient) {
    super();
  }

  create(data: any) {
    return this.http.post(`${this.url}/controls`, data);
  }

  read(params: IQuery = {}): Observable<ControlsResponse> {
    return this.http.get<ControlsResponse>(`${this.url}/controls`, {
      params,
    });
  }

  readOne(id: number) {
    return this.http.get(`${this.url}/controls/${id}`);
  }

  update(id: number, data: any) {
    return this.http.put(`${this.url}/controls/${id}`, data);
  }

  updateControls(data: any) {
    return this.http.put(`${this.url}/controls`, data);
  }

  delete(id: number) {
    return this.http.delete(`${this.url}/controls/${id}`);
  }

  download(): Observable<Blob> {
    return this.http.get(`${this.url}/controls/download`, {
      responseType: 'blob',
    });
  }

  createFromCvsFile(file: File) {
    const formData = new FormData();
    formData.append('file', file);

    return this.http.post(`${this.url}/controls/create-from-csv`, formData);
  }
}
