import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DateTransformService } from 'src/app/services/date-transform.service';
import { UsersService } from 'src/app/services/users.service';
import { IPaginatorEvent } from '../../common/table/table.component';
import { UsersCreateUpdateComponent } from './users-create-update/users-create-update.component';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit {
  columns: string[] = [
    'Nombre',
    'Apellido',
    'Email',
    'Rol',
    'Creado por',
    'Modificado por',
  ];
  columnsNames: string[] = [
    'firstName',
    'lastName',
    'email',
    'role',
    'createdBy',
    'updatedBy',
  ];

  searchText: string = '';
  allUsersList: any[] = [];
  usersList: any = [];
  showLoading: boolean = false;
  total: number = 0;
  offset: number = 0;
  limit: number = 25;

  constructor(
    private usersService: UsersService,
    private dialog: MatDialog,
    private dateTransformService: DateTransformService,
  ) {}

  ngOnInit(): void {
    this.read();
  }

  handlePageChange(event: IPaginatorEvent) {
    this.offset = event.offset;
    this.limit = event.limit;
    this.read();
  }

  read() {
    const params = {
      offset: this.offset,
      limit: this.limit,
      param: this.searchText,
    };
    this.usersService.read(params).subscribe((res: any) => {
      this.showLoading = false;
      this.total = res.total;
      this.usersList = this.dateTransformService.transformDates(res.users);
      this.allUsersList = this.usersList;
    });
  }

  update(id: number) {
    this.usersService.readOne(id).subscribe((res) => {
      const dialogRef = this.dialog.open(UsersCreateUpdateComponent, {
        data: { user: res, id: id, isCreate: false },
        autoFocus: false,
        maxHeight: '90%',
        panelClass: 'modal-styles',
        minWidth: '30%',
      });
      dialogRef.afterClosed().subscribe((result) => {
        this.read();
      });
    });
  }

  create() {
    const dialogRef = this.dialog.open(UsersCreateUpdateComponent, {
      autoFocus: false,
      data: { isCreate: true },
      maxHeight: '90%',
      panelClass: 'modal-styles',
      minWidth: '30%',
    });
    dialogRef.afterClosed().subscribe(() => {
      this.read();
    });
  }

  delete(id: number) {
    this.usersService.delete(id).subscribe(() => {
      this.read();
    });
  }

  search(query: string) {
    this.searchText = query;
    this.showLoading = true;
    this.read();
  }
}
