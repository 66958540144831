<a-input [label]="label">
  <mat-form-field appearance="outline" class="search-input">
    <input matInput
      [formControl]="searcher"
      [placeholder]="placeHolder"
      autocomplete="off">
    <mat-hint *ngIf="showLoading && loading">Cargando...</mat-hint>
    <mat-icon matSuffix *ngIf="searcher.value" (click)="clear()">clear</mat-icon>
  </mat-form-field>
</a-input>
