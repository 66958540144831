import { Component, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { startWith } from 'rxjs/operators';
import { debounce } from 'lodash';

const DELAY = 500;
const MIN_LENGTH = 3;

@Component({
  selector: 'searcher',
  templateUrl: './searcher.component.html',
  styleUrls: ['./searcher.component.css'],
})
export class SearcherComponent {
  @Input() label: string;
  @Input('hint') hint = '';
  @Input('placeHolder') placeHolder = 'Buscar';
  @Input('showLoading') showLoading = true;
  @Output() onChange = new EventEmitter<string>();

  loading = false;

  searcher = new UntypedFormControl();

  ngOnInit(): void {
    this.search = debounce(this.search, DELAY);
    this.subscribeToSearcher();
  }

  private search(param: string) {
    this.loading = true;
    this.onChange.emit(param);
  }

  private subscribeToSearcher() {
    this.searcher.valueChanges.pipe(startWith('')).subscribe(() => {
      this.validateAndSearch();
    });
  }

  private validateAndSearch() {
    const input = this.searcher;
    if (typeof input.value !== 'string') {
      return;
    }
    if (input.valid) {
      this.search(input.value);
    }
  }

  clear() {
    this.searcher.reset();
    this.search('');
  }
}
