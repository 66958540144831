import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import {
  Company,
  IAuth,
  IGoogleCredentials,
  User,
} from 'src/app/interfaces';
import { BaseService } from './base.service';
import { Roles } from '../enums/enums';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends BaseService {
  constructor(private http: HttpClient, private router: Router) {
    super();
    this.setAuth = this.setAuth.bind(this);
  }

  getToken(): string | null {
    return this.token();
  }

  getName(): string | null {
    return this.username();
  }

  signIn(data: any): Observable<any> {
    return this.http
      .post<IAuth>(`${this.url}/auth/sign-in`, data)
      .pipe(tap(this.setAuth));
  }

  get(): User {
    const selectedCompany = localStorage.getItem('selectedCompany');
    return {
      email: localStorage.getItem('email'),
      role: Number(localStorage.getItem('role')),
      id: Number(localStorage.getItem('id')),
      selectedCompany: selectedCompany ? JSON.parse(selectedCompany) : null,
    } as User;
  }

  setSelectedCompany(selectedCompany: Company) {
    localStorage.setItem('selectedCompany', JSON.stringify(selectedCompany));
    localStorage.setItem('companyColor', selectedCompany.primaryColor);
    return this.http
      .post<{ token: string }>(`${this.url}/auth/company-sign-in`, {
        companyId: selectedCompany.id,
      })
      .pipe(tap(this.setCompanyToken));
  }

  private setCompanyToken({ token }: { token: string }) {
    localStorage.setItem('token', token);
  }

  private setAuth({
    token,
    user: {
      id, role, email, firstName, lastName, selectedCompany,
    },
  }: IAuth) {
    let username = '';
    if (firstName && lastName) {
      username = `${firstName[0]}${lastName[0]}`;
    }
    localStorage.setItem('token', token);
    localStorage.setItem('id', String(id));
    localStorage.setItem('role', String(role));
    localStorage.setItem('email', email);
    localStorage.setItem('name', username);
    if (role !== Roles.SUPER_ADMIN) {
      this.setSelectedCompany(selectedCompany);
    } else {
      localStorage.removeItem('selectedCompany');
    }
  }

  checkLogged(): Observable<any> {
    const token: string | null = this.token();
    return this.http
      .post(`${this.url}/auth/check-logged`, { ...(token && { token }) })
      .pipe(
        tap(async (response: any) => {
          if (response) {
            const { user } = response;
            localStorage.setItem('token', response.token);
            localStorage.setItem('email', user.email);
            localStorage.setItem('role', String(user.role));
            localStorage.setItem('id', String(user.id));
          }
        }),
      );
  }

  checkAuth(): Observable<boolean> {
    return this.http.post<boolean>(
      `${this.url}/auth/check`,
      {},
      this.options(),
    );
  }

  async logout() {
    localStorage.clear();
    this.router.navigate(['auth/login']);
  }

  googleAuth(googleCredentials: IGoogleCredentials): Observable<any> {
    return this.http
      .post<IAuth>(`${this.url}/auth/google-auth`, googleCredentials)
      .pipe(tap(this.setAuth));
  }

  getUserTypes() {
    return this.http.get(`${this.url}/auth/user-types`, this.options());
  }

  checkEmailForPasswordRestore(email: string): Observable<{ msg: string }> {
    return this.http.post<{ msg: string }>(
      `${this.url}/auth/check-email-for-reset`,
      { email },
      this.options(),
    );
  }

  sendPasswordReset(
    body: {
      code: string;
      password: string;
      confirm: string;
      email: string;
    },
    token: string,
  ): Observable<any> {
    return this.http.post(`${this.url}/auth/reset-password`, body, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }),
    });
  }

  sendAgreement(): Observable<any> {
    return this.http.post(
      `${this.url}/auth/accept-agreement`,
      {},
      this.options(),
    );
  }
}
