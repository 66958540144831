import { Component, Input, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Company, CreateCompany } from 'src/app/interfaces';
import { CompanyService } from 'src/app/services/company.service';

@Component({
  selector: 'app-companies-create-update',
  templateUrl: './companies-create-update.component.html',
  styleUrls: ['./companies-create-update.component.scss'],
})
export class CompaniesCreateUpdateComponent implements OnInit {
  @Input() selectedCompany?: Company | undefined = undefined;
  @Input() closeForm: () => void;

  companyForm = new UntypedFormGroup({
    name: new UntypedFormControl('', Validators.required),
    rut: new UntypedFormControl('', Validators.required),
    phone: new UntypedFormControl('', Validators.required),
    address: new UntypedFormControl('', Validators.required),
    companyLogo: new UntypedFormControl('', Validators.required),
    primaryColor: new UntypedFormControl('#1D65A1', Validators.required),
  });

  isCreate: boolean = true;

  constructor(private companyService: CompanyService) {}

  ngOnInit(): void {
    if (this.selectedCompany) {
      this.isCreate = false;
      this.companyForm.setValue({
        name: this.selectedCompany.name,
        rut: this.selectedCompany.rut,
        phone: this.selectedCompany.phone,
        address: this.selectedCompany.address,
        companyLogo: this.selectedCompany.companyLogo,
        primaryColor: this.selectedCompany.primaryColor,
      });
    }
  }

  submit(): void {
    if (!this.isCreate) {
      this.update();
    } else {
      this.create();
    }
  }

  create() {
    if (!this.companyForm.invalid) {
      const { value } = this.companyForm;
      const company: CreateCompany = {
        name: value.name,
        rut: value.rut,
        phone: value.phone,
        address: value.address,
        companyLogo: value.companyLogo,
        primaryColor: value.primaryColor,
      };
      this.companyService.create(company).subscribe(() => this.closeForm());
    }
  }

  update() {
    if (!this.companyForm.invalid && this.selectedCompany) {
      this.companyService
        .update(this.selectedCompany.id, this.companyForm.value)
        .subscribe(() => this.closeForm());
    }
  }

  updateColor(event: any) {
    const newColor = event.target.value;
    this.companyForm.get('selectedColor')?.setValue(newColor);
  }
}
