import { Injectable } from '@angular/core';

export interface Color {
  name: string;
  hex: string;
  darkContrast: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  updateCustomPrimaryColor() {
    const primaryColor = localStorage.getItem('companyColor') || '#1071C1';
    document.documentElement.style.setProperty('--primary-color', primaryColor);
  }
}
