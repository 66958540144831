import { moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AlertsService } from 'src/app/services/alerts.service';
import { CheckListsService } from 'src/app/services/check-lists.service';
import { ServicesService } from 'src/app/services/services.service';
import { ControlsComponent } from '../controls/controls.component';

@Component({
  selector: 'app-service-create-update',
  templateUrl: './service-create-update.component.html',
  styleUrls: ['./service-create-update.component.scss'],
})
export class ServiceCreateUpdateComponent implements OnInit {
  serviceForm = new FormGroup({
    name: new FormControl('', Validators.required),
    description: new FormControl(''),
    checklistIds: new FormControl(),
    checklistTypesIds: new FormControl(),
  });
  @Output('onCancel') onCancel = new EventEmitter();
  checklistList: any = [];
  isCreate = true;
  checklistOptions = false;
  checklistTypesAndChecklistSelected: any = [];
  checklistIds: any = [];

  constructor(
    private servicesService: ServicesService,
    private checklistService: CheckListsService,
    public alertsService: AlertsService,
    public dialogRef: MatDialogRef<ControlsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit(): void {
    this.initaliaze();
  }

  initaliaze() {
    this.read();
    // UPDATE
    if (this.data) {
      this.isCreate = false;
      const cheklistValues: any = [];
      const checklistIdsSelected: any = [];
      if (this.data.service.checklists) {
        this.data.service.checklists.forEach((element: any) => {
          cheklistValues.push({
            item: { id: element.checklist.id, name: element.checklist.name },
          });
          checklistIdsSelected.push(element.checklist.id);
        });
      }
      this.serviceForm.setValue({
        name: this.data.service.name,
        description: this.data.service.description,
        checklistIds: checklistIdsSelected,
        checklistTypesIds: [],
      });
      this.checklistTypesAndChecklistSelected = cheklistValues;
    }
  }

  submit(): void {
    if (this.data) {
      this.update();
    } else {
      this.create();
    }
    this.read();
    this.dialogRef.close();
  }

  read() {
    this.checklistService.read().subscribe((res: any) => {
      this.checklistList = res.checklists;
    });
  }

  async create() {
    if (this.serviceForm.valid) {
      this.servicesService.create(this.serviceForm.value).subscribe({
        next: () => {
          this.dialogRef.close();
          this.alertsService.success('Servicio creado con éxito');
        },
        error: (error) => {
          console.log(error);
          this.alertsService.error('Error al crear servicio');
        },
      });
    }
  }

  update() {
    if (this.serviceForm.valid) {
      this.servicesService
        .update(this.data.id, this.serviceForm.value)
        .subscribe({
          next: () => {
            this.dialogRef.close();
            this.alertsService.success('Servicio actualizado con éxito');
          },
          error: (error) => {
            console.log(error);
            this.alertsService.error('Error al actualizar servicio');
          },
        });
    }
  }

  cancel() {
    this.dialogRef.close();
  }

  // TREE-CHIPS
  createChips(evento: any) {
    this.checklistTypesAndChecklistSelected = evento;
  }

  drop(event: any) {
    moveItemInArray(
      this.checklistTypesAndChecklistSelected,
      event.previousIndex,
      event.currentIndex,
    );
  }

  remove(control: any): void {
    const index = this.checklistTypesAndChecklistSelected.indexOf(control);
    // this.deletedControls.push(control.id);
    if (index >= 0) {
      this.checklistTypesAndChecklistSelected.splice(index, 1);
    }
  }
}
