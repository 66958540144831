import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientsService } from 'src/app/services/clients.service';
import { LocationService } from 'src/app/services/locations.service';
import { DASHBOARD, PAGES } from 'src/app/utils/urls';
import { Location } from 'src/app/interfaces/location.interface';

@Component({
  selector: 'app-client-locations',
  templateUrl: './client-locations.component.html',
  styleUrls: ['./client-locations.component.scss'],
})
export class ClientLocationsComponent implements OnInit {
  id: number;
  client: any;
  locationsList: Location[];

  constructor(
    private route: ActivatedRoute,
    private locationService: LocationService,
    private clientService: ClientsService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      const clientId = params['id'];
      this.clientService.readOne(clientId).subscribe({
        next: (client) => {
          this.id = clientId;
          this.client = client;
          this.locationService
            .readByClientId(clientId)
            .subscribe((locations: Location[]) => {
              this.locationsList = locations;
            });
        },
        error: () => {
          this.router.navigate([`${PAGES}/${DASHBOARD}`]);
        },
      });
    });
  }
}
