import {
  Component, EventEmitter, Inject, Input, OnInit, Output,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AlertsService } from 'src/app/services/alerts.service';
import { ClientsService } from 'src/app/services/clients.service';
import { UsersService } from 'src/app/services/users.service';
import { UsersComponent } from '../users.component';

@Component({
  selector: 'app-users-create-update',
  templateUrl: './users-create-update.component.html',
  styleUrls: ['./users-create-update.component.scss'],
})
export class UsersCreateUpdateComponent implements OnInit {
  @Output('onCancel') onCancel = new EventEmitter();

  userForm = new FormGroup({
    firstName: new FormControl('', Validators.required),
    lastName: new FormControl('', Validators.required),
    password: new FormControl('', this.data?.isCreate ? Validators.required : null),
    email: new FormControl('', [Validators.required, Validators.email]),
    role: new FormControl(),
    clientId: new FormControl(null),
  });

  clientList: any = [];
  isSubmitting: boolean = false;
  isCreate: boolean = this.data.isCreate;
  
  roles = localStorage.getItem('role') === '1'
    ? [
      { id: 1, name: 'SuperAdminn' },
      { id: 2, name: 'Administrador' },
      { id: 3, name: 'Auditor' },
      { id: 4, name: 'Auditado' },
    ]
    : [
      { id: 2, name: 'Administrador' },
      { id: 3, name: 'Auditor' },
      { id: 4, name: 'Auditado' },
    ];

  constructor(
    private requirementsService: UsersService,
    private clientsService: ClientsService,
    private alertsService: AlertsService,
    public dialogRef: MatDialogRef<UsersComponent>,

    
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit(): void {
    this.read();
    if (this.data) {
      this.userForm.setValue({
        firstName: this.data.user.firstName,
        lastName: this.data.user.lastName,
        email: this.data.user.email,
        role: this.data.user.role,
        clientId: this.data.user.client != null ? this.data.user.client.id : null,
        password: '',
      });
    }
  }

  read() {
    this.readClients();
  }

  cancel() {
    this.dialogRef.close();
  }

  submit(): void {
    if (this.data.isCreate) {
      this.create();
    } else {
      this.update();     
    }
  }

  create() {
    this.requirementsService.create(this.userForm.value).subscribe(({
      next: () => {
        this.dialogRef.close();
        this.alertsService.success('Usuario creado con éxito');
        this.isSubmitting = false;
      },
      error: (error) => {
        console.log(error);
        this.alertsService.error('Error al crear usuario');
        this.isSubmitting = false;
      },
    }));
  }

  update() {
    this.requirementsService
      .update(this.data.id, this.userForm.value)
      .subscribe({
        next: () => {
          this.dialogRef.close();
          this.alertsService.success('Usuario actualizado con éxito');
        },
        error: (error) => {
          console.error(error);
          this.alertsService.error('Error al actualizar usuario');
        },
      });
  }

  keys(): Array<string> {
    const keys = Object.keys(this.roles);
    return keys.slice(keys.length / 2);
  }

  readClients() {
    this.clientsService.read().subscribe((res) => {
      this.clientList = res.clients;
    });
  }
}
