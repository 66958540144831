import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { Location } from 'src/app/interfaces';
import { Client } from 'src/app/interfaces/client.interface';
import { AuditableObjectCreateUpdateComponent } from 'src/app/modules/pages/auditable-object/auditable-object/auditable-object-create-update/auditable-object-create-update.component';
import { ClientsCreateUpdateComponent } from 'src/app/modules/pages/clients/client/clients-create-update/clients-create-update.component';
import { LocationsCreateUpdateComponent } from 'src/app/modules/pages/clients/locations/locations-create-update/locations-create-update.component';
import { AlertsService } from 'src/app/services/alerts.service';
import { AuditableObjectService } from 'src/app/services/auditable-object.service';
import { AuditsService } from 'src/app/services/audits.service';
import { ClientsService } from 'src/app/services/clients.service';
import { LocationService } from 'src/app/services/locations.service';
import { ServicesService } from 'src/app/services/services.service';
import { UsersService } from 'src/app/services/users.service';
import { ServiceCreateUpdateComponent } from '../../../services/service/service-create-update/service-create-update.component';
import { UsersCreateUpdateComponent } from '../../../users/users-create-update/users-create-update.component';
import { AuditsComponent } from '../audits.component';
import { FinishOptions } from 'src/app/enums/enums';
import { DatePipe } from '@angular/common';

function fourthFormValidator(form: AbstractControl) {
  const iterate = form.get('iterate')?.value;
  const repeatCount = form.get('repeatCount')?.value;
  const repeatType = form.get('repeatType')?.value;
  const finish = form.get('finish')?.value;
  const finishDate = form.get('finishDate')?.value;
  const finishAfter = form.get('finishAfter')?.value;

  if (!iterate) {
    return null;
  } else {
    if (!repeatCount || repeatCount <= 0) {
      return { valid: false };
    }
    if (!repeatType) {
      return { valid: false };
    }
    if (finish && finish == FinishOptions.NEVER) {
      return null;
    } else if (finish && finish == FinishOptions.DATE && finishDate) {
      return null;
    } else if (finish && finish == FinishOptions.AFTER && finishAfter > 0) {
      return null;
    } else {
      return { valid: false };
    }
  }
}

@Component({
  selector: 'app-audits-create-update',
  templateUrl: './audits-create-update.component.html',
  styleUrls: ['./audits-create-update.component.scss'],
})
export class AuditsCreateUpdateComponent implements OnInit {
  @Output('onCancel') onCancel = new EventEmitter();

  firstFormGroup = new FormGroup(
    {
      name: new FormControl(null, Validators.required),
      date: new FormControl(null, Validators.required),
      expirationDate: new FormControl(null, Validators.required),
    },
    { validators: this.dateValidator },
  );

  secondFormGroup = new FormGroup({
    clientId: new FormControl(null, Validators.required),
    auditableObjectId: new FormControl(null, Validators.required),
    servicesIds: new FormControl<number[]>([], Validators.required),
    auditorId: new FormControl('', Validators.required),
    extraAuditorsIds: new FormControl<number[]>([]),
  });

  thirdFormGroup = new FormGroup({
    report: new FormControl(true),
    usersToNotificateIds: new FormControl<number[]>([], Validators.required),
    locationId: new FormControl(null, Validators.required),
  });

  fourthFormGroup = new FormGroup(
    {
      observations: new FormControl(''),
      iterate: new FormControl(true),
      repeatCount: new FormControl(null),
      repeatType: new FormControl<number | null>(null),
      finish: new FormControl(''),
      finishDate: new FormControl(null),
      finishAfter: new FormControl(null),
    },
    { validators: fourthFormValidator },
  );

  repeatTypes: any[] = [
    { id: 1, type: 'Dia' },
    { id: 2, type: 'Semana' },
    { id: 3, type: 'Mes' },
  ];

  isCreate = true;
  isCopy = false;
  isSubmitting = false;
  isCompleted = false;

  auditorsList: any[] = [];
  extraAuditorsList: any[] = [];
  servicesIdList: number[] = [];
  adminsToNotificateIdList: number[] = [];
  extraAuditorsIdList: number[] = [];
  auditeesList: Client[] = [];
  adminsList: any = [];
  serviceList: any = [];
  auditableObjectList: any = [];
  locationsList: any = [];

  locationInitialize: Location = {
    address: '',
    latitude: -34.90262688965179,
    longitude: -56.16403940430713,
  } as Location;

  auditItereation = true;
  isMonday = false;
  isTuesday = false;
  isWednesday = false;
  isThursday = false;
  isFriday = false;
  isSaturday = false;
  isSunday = false;
  weeklyIteration = false;
  finishNever = true;
  finishDateValue = false;
  finishAfterValue = false;

  constructor(
    private auditsService: AuditsService,
    private clientsService: ClientsService,
    private servicesService: ServicesService,
    private auditableObjectService: AuditableObjectService,
    private locationsService: LocationService,
    private userService: UsersService,
    private dialog: MatDialog,
    private datePipe: DatePipe,
    private alertsService: AlertsService,
    public dialogRef: MatDialogRef<AuditsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit(): void {
    this.read();
    this.loadIdLists();
    if (this.data) {
      this.isCreate = false;

      this.firstFormGroup.setValue({
        name: this.data.res.name,
        date: this.data.res.date,
        expirationDate: this.data.res.expirationDate,
      });

      this.secondFormGroup.setValue({
        clientId: this.data.res.client.id,
        auditableObjectId: this.data.res.auditableObject.id,
        servicesIds: this.servicesIdList,
        auditorId: this.data.res.auditor.id,
        extraAuditorsIds: this.extraAuditorsIdList,
      });

      this.thirdFormGroup.setValue({
        report: this.data.res.report,
        usersToNotificateIds: this.adminsToNotificateIdList,
        locationId: this.data.res.locationId,
      });

      const repeatEvery = this.data.res.iteration?.repeatEvery;
      let repeatType: number | null;
      switch (repeatEvery) {
        case 'Day':
          repeatType = 1;
          break;
        case 'Week':
          repeatType = 2;
          this.weeklyIteration = true;
          break;
        case 'Month':
          repeatType = 3;
          break;
        default:
          repeatType = null;
      }

      this.fourthFormGroup.setValue({
        observations: this.data.res.observations,
        repeatCount: this.data.res.iteration?.repeatEveryNumber || 0,
        repeatType: repeatType,
        finishDate: this.data.res.iteration?.endDate || null,
        finishAfter: this.data.res.iteration?.endNumber || 0,
        iterate: this.data.res.iteration !== null,
        finish: null,
      });

      if (this.data.res.iteration) {
        this.setIteration();
      } else {
        this.auditItereation = false;
      }
      this.isCompleted = this.data.res.completed !== null;
      if (this.data?.isCopy) this.isCopy = true;
    }
  }

  loadIdLists() {
    if (this.data) {
      if (this.data.res.services != null) {
        this.servicesIdList = this.data.res.services.map(
          (s: any) => s.serviceId,
        );
      }
      if (this.data.res.usersToNotificate != null) {
        this.adminsToNotificateIdList = this.data.res.usersToNotificate.map(
          (a: any) => a.adminId,
        );
      }
      if (this.data.res.extraAuditors != null) {
        this.extraAuditorsIdList = this.data.res.extraAuditors.map(
          (e: any) => e.auditorId,
        );
      }
    }
  }

  read() {
    this.readAuditees();
    this.readAuditableObject();
    this.readAuditors();
    this.readAdmins();
    this.readLocation();
    this.readServices();
  }

  readAuditees() {
    this.clientsService.read().subscribe((res: any) => {
      this.auditeesList = res.clients;
    });
  }

  readServices() {
    const auditFormClientId = this.secondFormGroup.value.clientId;
    const dataResClientId = this.data?.res?.client?.id;
    const clientIdToUse = auditFormClientId || dataResClientId;
    this.servicesService.readByClientId(clientIdToUse).subscribe((res) => {
      this.serviceList = res;
    });
  }

  readAuditableObject() {
    const auditFormClientId = this.secondFormGroup.value.clientId;
    const dataResClientId = this.data?.res?.client?.id;

    const clientIdToUse = auditFormClientId || dataResClientId;
    this.auditableObjectService
      .readByClientId(clientIdToUse)
      .subscribe((res) => {
        this.auditableObjectList = res;
      });
  }

  readLocation() {
    const auditFormClientId = this.secondFormGroup.value.clientId;
    const dataResClientId = this.data?.res?.client?.id;

    const clientIdToUse = auditFormClientId || dataResClientId;
    this.locationsService.readByClientId(clientIdToUse).subscribe((res) => {
      this.locationsList = res;
      this.setLocation(this.data?.res?.locationId);
    });
  }

  readAuditors() {
    this.userService.readAuditors().subscribe((res: any) => {
      this.auditorsList = res;
      if (!this.isCreate) {
        this.extraAuditorsList = this.auditorsList.filter(
          (auditor: any) => auditor.id !== this.data.res.auditor.id,
        );
      }
    });
  }

  readAdmins() {
    this.userService.readAdmins().subscribe((res) => {
      this.adminsList = res;
    });
  }

  onClientChange() {
    this.readServices();
    this.readAuditableObject();
    this.readLocation();
  }

  createAuditableObject() {
    const dialogRef = this.dialog.open(AuditableObjectCreateUpdateComponent);
    dialogRef.afterClosed().subscribe(() => {
      this.readAuditableObject();
    });
  }

  createLocations() {
    const dialogRef = this.dialog.open(LocationsCreateUpdateComponent);
    dialogRef.afterClosed().subscribe(() => {
      this.readLocation();
    });
  }

  createAuditee() {
    const dialogRef = this.dialog.open(ClientsCreateUpdateComponent);
    dialogRef.afterClosed().subscribe(() => {
      this.readAuditees();
    });
  }

  createAuditor() {
    const dialogRef = this.dialog.open(UsersCreateUpdateComponent);
    dialogRef.afterClosed().subscribe(() => {
      this.readAuditors();
    });
  }

  createService() {
    const dialogRef = this.dialog.open(ServiceCreateUpdateComponent);
    dialogRef.afterClosed().subscribe(() => {
      this.readServices();
    });
  }

  submit() {
    if (this.isSubmitting) {
      return;
    }
    this.isSubmitting = true;
    if (this.isCreate) {
      this.create();
    } else if (!this.isCopy) {
      this.update();
    } else {
      this.duplicate();
    }
  }

  create() {
    if (
      this.firstFormGroup.valid &&
      this.secondFormGroup.valid &&
      this.thirdFormGroup.valid
    ) {
      const combinedFormValues = {
        ...this.firstFormGroup.value,
        ...this.secondFormGroup.value,
        ...this.thirdFormGroup.value,
        ...this.fourthFormGroup.value,
      };

      if (this.fourthFormGroup.value.iterate) {
        const repeatJson = {
          Monday: this.isMonday,
          Tuesday: this.isTuesday,
          Wednesday: this.isWednesday,
          Thursday: this.isThursday,
          Friday: this.isFriday,
          Saturday: this.isSaturday,
          Sunday: this.isSunday,
        };

        let repeatEveryType = '';
        if (
          this.fourthFormGroup.value.repeatType != null &&
          this.fourthFormGroup.value.repeatType != undefined
        ) {
          switch (this.fourthFormGroup.value.repeatType) {
            case 1:
              repeatEveryType = 'Day';
              break;
            case 2:
              repeatEveryType = 'Week';
              break;
            case 3:
              repeatEveryType = 'Month';
              break;
          }
        }
        const iteration = {
          endNever: this.finishNever,
          endDate: this.fourthFormGroup.value.finishDate,
          endNumber: this.fourthFormGroup.value.finishAfter,
          repeatEveryNumber: this.fourthFormGroup.value.repeatCount,
          repeatEvery: repeatEveryType,
          repeatJson: repeatJson,
        };
        this.auditsService
          .create({ ...combinedFormValues, iteration })
          .subscribe({
            next: () => {
              this.dialogRef.close();
              this.alertsService.success('Auditoría creada con éxito');
              this.isSubmitting = false;
            },
            error: (error) => {
              console.log(error);
              this.alertsService.error('Error al crear auditoría');
              this.isSubmitting = false;
            },
          });
      } else {
        this.auditsService.create(combinedFormValues).subscribe({
          next: () => {
            this.dialogRef.close();
            this.alertsService.success('Auditoría creada con éxito');
            this.isSubmitting = false;
          },
          error: (error) => {
            console.log(error);
            this.alertsService.error('Error al crear auditoría');
            this.isSubmitting = false;
          },
        });
      }
    }
  }

  update() {
    if (
      this.firstFormGroup.valid &&
      this.secondFormGroup.valid &&
      this.thirdFormGroup.valid
    ) {
      const combinedFormValues = {
        ...this.firstFormGroup.value,
        ...this.secondFormGroup.value,
        ...this.thirdFormGroup.value,
        ...this.fourthFormGroup.value,
      };
      if (this.fourthFormGroup.value.iterate) {
        const repeatJson = {
          Monday: this.isMonday,
          Tuesday: this.isTuesday,
          Wednesday: this.isWednesday,
          Thursday: this.isThursday,
          Friday: this.isFriday,
          Saturday: this.isSaturday,
          Sunday: this.isSunday,
        };

        let repeatEveryType = '';
        if (
          this.fourthFormGroup.value.repeatType != null &&
          this.fourthFormGroup.value.repeatType != undefined
        ) {
          switch (this.fourthFormGroup.value.repeatType) {
            case 1:
              repeatEveryType = 'Day';
              break;
            case 2:
              repeatEveryType = 'Week';
              break;
            case 3:
              repeatEveryType = 'Month';
              break;
          }
        }
        const iteration = {
          id: this.data.res.iteration.id,
          endNever: this.finishNever,
          endDate: this.fourthFormGroup.value.finishDate,
          endNumber: this.fourthFormGroup.value.finishAfter,
          repeatEveryNumber: this.fourthFormGroup.value.repeatCount,
          repeatEvery: repeatEveryType,
          repeatJson: repeatJson,
        };
        this.auditsService
          .update(this.data.res.id, { ...combinedFormValues, iteration })
          .subscribe({
            next: () => {
              this.dialogRef.close();
              this.alertsService.success('Auditoría actualizada con éxito');
              this.isSubmitting = false;
            },
            error: (error) => {
              console.log(error);
              this.alertsService.error('Error al actualizar auditoría');
              this.isSubmitting = false;
            },
          });
      } else {
        this.auditsService
          .update(this.data.res.id, combinedFormValues)
          .subscribe({
            next: () => {
              this.alertsService.success('Auditoría actualizada con éxito');
              this.dialogRef.close();
            },
            error: (error) => {
              console.log(error);
              this.alertsService.error('Error al actualizar auditoría');
            },
          });
      }
    }
  }

  duplicate() {
    if (
      this.firstFormGroup.valid &&
      this.secondFormGroup.valid &&
      this.thirdFormGroup.valid
    ) {
      this.create();
    }
  }

  onAuditorChange(event: any) {
    const selectedAuditorId = event.value;

    if (selectedAuditorId) {
      this.extraAuditorsList = this.auditorsList.filter(
        (auditor: any) => auditor.id !== selectedAuditorId,
      );
    }
  }

  close() {
    this.dialogRef.close();
  }

  setLocation(locationId: any) {
    const selectedLocation = this.locationsList.find(
      (location: any) => location.id === locationId,
    );
    if (selectedLocation) {
      this.locationInitialize.latitude = selectedLocation.latitude;
      this.locationInitialize.longitude = selectedLocation.longitude;
      this.locationInitialize.address = selectedLocation.address;
    }
  }

  onIterateSelected(value: boolean) {
    this.auditItereation = value;
  }

  repeatTypeChange(selectedValue: number) {
    if (selectedValue === 2) {
      this.weeklyIteration = true;
    } else {
      this.weeklyIteration = false;
    }
  }

  selectDay(day: string) {
    switch (day) {
      case 'L':
        this.isMonday = !this.isMonday;
        break;
      case 'Ma':
        this.isTuesday = !this.isTuesday;
        break;
      case 'Mi':
        this.isWednesday = !this.isWednesday;
        break;
      case 'J':
        this.isThursday = !this.isThursday;
        break;
      case 'V':
        this.isFriday = !this.isFriday;
        break;
      case 'S':
        this.isSaturday = !this.isSaturday;
        break;
      case 'D':
        this.isSunday = !this.isSunday;
        break;
      default:
        break;
    }
  }

  dateValidator(control: AbstractControl): ValidationErrors | null {
    const date = control.get('date')?.value;
    const expirationDate = control.get('expirationDate')?.value;

    if (date && expirationDate && date > expirationDate) {
      control.get('expirationDate')?.setErrors({ maxDate: true });
      return { maxDate: true };
    }

    control.get('expirationDate')?.setErrors(null);

    return null;
  }

  formatDate(): void {
    if (this.data.res.date) {
      const formattedDate = new Date(this.data.res.date);
      formattedDate.setDate(formattedDate.getDate() + 1); // Suma un día
      this.data.res.date = this.datePipe.transform(formattedDate, 'yyyy-MM-dd');
    }

    if (this.data.res.expirationDate) {
      const formattedExpirationDate = new Date(this.data.res.expirationDate);
      formattedExpirationDate.setDate(formattedExpirationDate.getDate() + 1); // Suma un día
      this.data.res.expirationDate = this.datePipe.transform(
        formattedExpirationDate,
        'yyyy-MM-dd',
      );
    }

    if (this.data.res.iteration?.endDate) {
      const formattedDate = new Date(this.data.res.iteration.endDate);
      formattedDate.setDate(formattedDate.getDate() + 1); // Suma un día
      this.data.res.iteration.endDate = this.datePipe.transform(
        formattedDate,
        'yyyy-MM-dd',
      );
    }
  }

  setIteration() {
    const iteration = this.data.res.iteration;

    const weeksSelected = iteration.repeatJson;
    this.isMonday = weeksSelected.Monday;
    this.isTuesday = weeksSelected.Tuesday;
    this.isWednesday = weeksSelected.Wednesday;
    this.isThursday = weeksSelected.Thursday;
    this.isFriday = weeksSelected.Friday;
    this.isSaturday = weeksSelected.Saturday;
    this.isSunday = weeksSelected.Sunday;

    const finishControl = this.fourthFormGroup.get('finish');
    if (finishControl) {
      if (iteration.endDate) {
        finishControl.setValue('finishDateValue');
      } else if (iteration.endNumber) {
        finishControl.setValue('finishAfterValue');
      } else if (iteration.endNever) {
        finishControl.setValue('finishNever');
      }
    }
  }

  changeEndOptions() {
    if (this.fourthFormGroup.value.finish === 'finishNever') {
      this.fourthFormGroup.patchValue({
        finishDate: null,
        finishAfter: null,
      });
    } else if (this.fourthFormGroup.value.finish === 'finishDateValue') {
      this.fourthFormGroup.patchValue({
        finishAfter: null,
      });
    } else if (this.fourthFormGroup.value.finish === 'finishAfterValue') {
      this.fourthFormGroup.patchValue({
        finishDate: null,
      });
    }
  }
}
