import { Component, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-custom-tool-tip',
  templateUrl: './custom-tool-tip.component.html',
  styleUrls: ['./custom-tool-tip.component.scss'],
})
export class CustomToolTipComponent {
  @Input() text: string;

  @Input() contentTemplate: TemplateRef<any>;
}
