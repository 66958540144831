import { Component, Input } from '@angular/core';

@Component({
  selector: 'a-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.css'],
})
export class InputComponent {
  @Input('label') label: string;
  @Input('hint') hint: string;
  @Input('new') new: boolean;
}
