import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';
import { IQuery } from '../interfaces';
import { FindingTypes } from '../interfaces/finding-types.interface';

@Injectable({
  providedIn: 'root',
})
export class FindingTypesService extends BaseService {
  constructor(private http: HttpClient, private router: Router) {
    super();
  }

  read(params: IQuery = {}): Observable<FindingTypes> {
    return this.http.get<FindingTypes>(`${this.url}/finding-types`, {
      params,
    });
  }
}
