<audit-modal title="{{isCreate ? 'Crear Locación' : 'Actualizar Locación'}}">
    <form class="d-flex flex-column" [formGroup]="locationForm">
        <a-input label="Nombre">
            <mat-form-field appearance="outline">
                <input formControlName="name" matInput placeholder="Nombre">
            </mat-form-field>
        </a-input>

        <a-input label="Descripción">
            <mat-form-field appearance="outline">
                <input formControlName="description" matInput placeholder="Descripción">
            </mat-form-field>
        </a-input>

        <a-input label="Asignar auditado" *ngIf="!selectedClient">
            <mat-form-field appearance="outline">
                <mat-select formControlName="clientId" placeholder="Seleccione un auditado">
                    <mat-option *ngFor="let client of clientsList" [value]="client.id">{{client.name}}</mat-option>
                </mat-select>
            </mat-form-field>
        </a-input>

        <div class="maps">
            <audits-google-maps #mapsComponent [lat]="data?.location?.latitude" [lng]="data?.location?.longitude"
                [address]="data?.location?.address" label="Dirección">
            </audits-google-maps>
        </div>
        
        <div class="button-container">
            <button class="close-button" (click)="cancel()">Cancelar</button>
            <button [ngClass]="!locationForm.valid ? 'disabled-button' : 'create-button'"
                [disabled]="!locationForm.valid" *ngIf="isCreate" (click)="submit()">Crear</button>
            <button [ngClass]="!locationForm.valid ? 'disabled-button' : 'create-button'"
                [disabled]="!locationForm.valid" *ngIf="!isCreate" (click)="submit()">Actualizar</button>
        </div>
    </form>
</audit-modal>