import { AfterViewInit, Component, Input, OnDestroy } from '@angular/core';
import Chart from 'chart.js/auto';
import { AUDITS, PAGES } from 'src/app/utils/urls';

@Component({
  selector: 'app-audits-dashboard',
  templateUrl: './audits.component.html',
  styleUrls: ['./audits.component.scss'],
})
export class AuditsDashboardComponent implements AfterViewInit, OnDestroy {
  @Input('audit') audit: any;
  @Input('isExpired') isExpired = false;
  @Input('isClient') isClient = false;
  @Input() uniqueId: string;

  percentage: number;
  daysLeft: number;
  daysLeftExpired: number;

  private chart: Chart<'doughnut'> | null = null;

  ngAfterViewInit(): void {
    this.renderDonutChart();
    this.calculateDaysLeft();
  }

  ngOnDestroy(): void {
    if (this.chart) {
      this.chart.destroy();
    }
  }

  onAuditCode() {
    return `/${PAGES}/${AUDITS}/${this.audit.id}`;
  }

  renderDonutChart(): void {
    const canvas = document.getElementById(
      `${this.uniqueId}`,
    ) as HTMLCanvasElement;
    const ctx = canvas?.getContext('2d');

    if (ctx) {
      if (this.chart) {
        this.chart.destroy();
      }
      if (this.audit.totalControls > 0) {
        this.percentage = Math.round(
          Math.min(
            Math.max(
              (this.audit.completedControls / this.audit.totalControls) * 100,
              0,
            ),
            100,
          ),
        );
      } else {
        this.percentage = 0;
      }

      const completedColor = this.getColorBasedOnPercentage(this.percentage);
      const remainingColor = 'white';
      if (this.audit.totalControls > 0) {
        this.chart = new Chart(ctx, {
          type: 'doughnut',
          data: {
            datasets: [
              {
                data: [
                  this.audit.completedControls,
                  this.audit.totalControls - this.audit.completedControls,
                ],
                label: 'Controles',
                backgroundColor: [completedColor, remainingColor],
                borderWidth: 0,
                hoverOffset: 4,
              },
            ],
          },
          options: {
            cutout: 32,
          },
        });
      } else {
        this.chart = new Chart(ctx, {
          type: 'doughnut',
          data: {
            datasets: [
              {
                data: [0],
                backgroundColor: ['white'],
                hoverOffset: 4,
                borderWidth: 0,
              },
            ],
          },
          options: {
            cutout: 32,
          },
        });
      }
    }
  }

  getColorBasedOnPercentage(percentage: number): string {
    if (percentage <= 25) {
      return 'red';
    } else if (percentage <= 75) {
      return 'orange';
    } else {
      return 'green';
    }
  }

  calculateDaysLeft() {
    const currentDate = new Date();

    if (this.audit.date) {
      const dateComponents = this.audit.date.split('/');

      if (dateComponents.length === 3) {
        const day = parseInt(dateComponents[0], 10);
        const month = parseInt(dateComponents[1], 10) - 1;
        const year = parseInt(dateComponents[2], 10);

        const auditDate = new Date(year, month, day);

        const timeDifference = auditDate.getTime() - currentDate.getTime();

        const daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24));

        this.daysLeft = daysDifference + 1;
      }
    }

    if (this.audit.expirationDate) {
      const dateComponents = this.audit.expirationDate.split('/');

      if (dateComponents.length === 3) {
        const day = parseInt(dateComponents[0], 10);
        const month = parseInt(dateComponents[1], 10) - 1;
        const year = parseInt(dateComponents[2], 10);

        const auditDate = new Date(year, month, day);

        const timeDifference = auditDate.getTime() - currentDate.getTime();

        const daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24));

        this.daysLeftExpired = daysDifference + 1;
      }
    }
  }
}
