import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, map } from 'rxjs';
import { BaseService } from './base.service';
import { Company, IQuery } from '../interfaces';
import { DocumentInterface } from '../interfaces/document.interface';
import { DocumentType } from '../interfaces/document-type.interface';
import { Employee } from '../interfaces/employee.interface';
import { Vehicle } from '../interfaces/vehicle.interface';
import { EntityType } from '../enums/enums';

@Injectable({
  providedIn: 'root',
})
export class DocumentService extends BaseService {
  constructor(private http: HttpClient) {
    super();
  }

  create(body: any, image: File | null) {
    const form = this.form({ ...body, image });
    return this.http.post(`${this.url}/documents`, form);
  }
  update(id: number, body: any, image: File | null) {
    const form = this.form({ ...body, image });
    return this.http.put(`${this.url}/documents/${id}`, form);
  }

  findAll(
    entityType: number,
    params: IQuery = {},
  ): Observable<{ data: DocumentInterface[]; total: number }> {
    // eslint-disable-next-line dot-notation
    if (entityType === EntityType.CLIENT) {
      return this.http
        .get<{
          data: { doc: DocumentInterface; entity: Employee }[];
          total: number;
        }>(`${this.url}/documents`, {
          params,
        })
        .pipe(
          map((res) => ({
            data: res.data.map((document) => ({
              ...document.doc,
              clientName: `${document.entity.firstName} ${document.entity.lastName}`,
            })),
            total: res.total,
          })),
        );
    }
    if (entityType === EntityType.VEHICLE) {
      return this.http
        .get<{
          data: { doc: DocumentInterface; entity: Vehicle }[];
          total: number;
        }>(`${this.url}/documents`, {
          params,
        })
        .pipe(
          map((res) => ({
            data: res.data.map((document) => ({
              ...document.doc,
              clientName: document.entity.name,
            })),
            total: res.total,
          })),
        );
    }

    return this.http
      .get<{
        data: { doc: DocumentInterface; entity: Company }[];
        total: number;
      }>(`${this.url}/documents`, {
        params,
      })
      .pipe(
        map((res) => ({
          data: res.data.map((document) => ({
            ...document.doc,
            clientName: document.entity.name,
          })),
          total: res.total,
        })),
      );
  }

  findOne(id: number): Observable<DocumentType> {
    return this.http.get<DocumentType>(`${this.url}/documents/${id}`);
  }

  delete(id: number) {
    return this.http.delete(`${this.url}/documents/${id}`);
  }
  private form({
    image,
    ...body
  }: DocumentInterface & { image: File }): FormData {
    const form = new FormData();
    Object.entries(body).forEach(([key, value]) =>
      form.append(key, value as any),
    );
    if (image) {
      form.append('image', image);
    }
    return form;
  }
}
