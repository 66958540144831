import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ControlPriority } from 'src/app/enums/enums';
import { AlertsService } from 'src/app/services/alerts.service';
import { ControlService } from 'src/app/services/controls.service';
import { ControlsComponent } from '../controls.component';

@Component({
  selector: 'app-controls-create',
  templateUrl: './controls-create-update.component.html',
  styleUrls: ['./controls-create-update.component.scss'],
})
export class ControlsCreateUpdateComponent implements OnInit {
  controlForm = new FormGroup({
    name: new FormControl('', Validators.required),
    description: new FormControl('', Validators.required),
    priority: new FormControl(),
    stopActivity: new FormControl(false),
    recommendedDays: new FormControl(),
    penaltyPoints: new FormControl(),
  });

  @Output('onCancel') onCancel = new EventEmitter();
  requirementsList: any = [];
  isCreate = true;
  controlPriority: ControlPriority;

  constructor(
    private controlService: ControlService,
    private alertsService: AlertsService,
    public dialogRef: MatDialogRef<ControlsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit(): void {
    if (this.data) {
      this.isCreate = false;
      this.controlForm.patchValue({
        name: this.data.control.name,
        description: this.data.control.description,
        priority: this.data.control.priority,
        stopActivity: this.data.control.stopActivity,
        recommendedDays: this.data.control.recommendedDays,
        penaltyPoints: this.data.control.penaltyPoints,
      });
    }
  }

  cancel() {
    this.dialogRef.close();
  }

  setRecommendedDays(newValue: number) {
    this.controlForm.patchValue({
      recommendedDays: newValue,
    });
  }

  setPenaltyPoints(newValue: number) {
    this.controlForm.patchValue({
      penaltyPoints: newValue,
    });
  }

  submit(): void {
    if (this.data) {
      this.update();
    } else {
      this.create();
    }
    this.dialogRef.close();
  }

  create() {
    this.controlForm.patchValue({
      priority: parseInt(this.controlForm.value.priority, 10),
    });
    this.controlService.create(this.controlForm.value).subscribe({
      next: () => {
        this.dialogRef.close();
        this.alertsService.success('Control creado con éxito');
      },
      error: (error) => {
        console.log(error);
        this.alertsService.error('Error al crear control');
      },
    });
  }

  update() {
    this.controlService.update(this.data.id, this.controlForm.value).subscribe({
      next: () => {
        this.dialogRef.close();
        this.alertsService.success('Control actualizado con éxito');
      },
      error: (error) => {
        console.log(error);
        this.alertsService.error('Error al actualizar control');
      },
    });
  }
}
