<audit-modal title="{{ isCreate ? 'Crear Control' : 'Actualizar Control' }}">
  <form class="d-flex flex-column form-container" [formGroup]="controlForm">
    <a-input label="Nombre">
      <mat-form-field appearance="outline">
        <input formControlName="name" matInput placeholder="Nombre" />
      </mat-form-field>
    </a-input>

    <a-input label="Descripción">
      <mat-form-field appearance="outline">
        <input
          formControlName="description"
          matInput
          placeholder="Descripción"
        />
      </mat-form-field>
    </a-input>

    <a-input label="Prioridad">
      <mat-form-field appearance="outline">
        <mat-select
          formControlName="priority"
          placeholder="Seleccione prioridad"
        >
          <mat-option [value]="1"> Baja </mat-option>
          <mat-option [value]="2"> Media </mat-option>
          <mat-option [value]="3"> Alta </mat-option>
        </mat-select>
      </mat-form-field>
    </a-input>

    <div class="d-flex flex-column mb-3">
      <label>Parar actividad</label>
      <mat-radio-group formControlName="stopActivity">
        <mat-radio-button color="primary" class="mr-5" [value]="true"
          >Si</mat-radio-button
        >
        <mat-radio-button color="primary" [value]="false">No</mat-radio-button>
      </mat-radio-group>
    </div>

    <a-input label="Días recomendados">
      <mat-form-field appearance="outline">
        <input
          type="number"
          formControlName="recommendedDays"
          matInput
          placeholder="Días recomendados"
        />
      </mat-form-field>
    </a-input>

    <a-input label="Puntos de penalización">
      <mat-form-field appearance="outline">
        <input
          type="number"
          formControlName="penaltyPoints"
          matInput
          placeholder="Puntos de penalización"
        />
      </mat-form-field>
    </a-input>
  </form>
  <div class="button-container">
    <button class="close-button" (click)="cancel()">Cancelar</button>
    <button
      [ngClass]="!controlForm.valid ? 'disabled-button' : 'create-button'"
      [disabled]="!controlForm.valid"
      *ngIf="isCreate"
      type="submit"
      (click)="submit()"
    >
      Crear
    </button>
    <button
      [ngClass]="!controlForm.valid ? 'disabled-button' : 'create-button'"
      [disabled]="!controlForm.valid"
      *ngIf="!isCreate"
      type="submit"
      (click)="submit()"
    >
      Actualizar
    </button>
  </div>
</audit-modal>
