import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Vehicle } from 'src/app/interfaces/vehicle.interface';
import { AlertsService } from 'src/app/services/alerts.service';
import { VehiclesService } from 'src/app/services/vehicles.service';

@Component({
  selector: 'app-vehicle-form',
  templateUrl: './vehicle-form.component.html',
  styleUrls: ['./vehicle-form.component.scss'],
})
export class VehicleFormComponent {
  vehicleForm = new FormGroup({
    name: new FormControl<string | null>(null, Validators.required),
    description: new FormControl(),
    license: new FormControl(),
  });

  constructor(
    private service: VehiclesService,
    private alertsService: AlertsService,
    private dialogRef: MatDialogRef<VehicleFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { vehicle?: Vehicle},
  ) {}

  ngOnInit() {
    if (this.data?.vehicle) this.setForm();
  }

  setForm() {
    this.vehicleForm.patchValue({
      name: this.data.vehicle?.name,
      description: this.data.vehicle?.description,
      license: this.data.vehicle?.license,
    });
  }

  submit() {
    if (this.data?.vehicle) {
      this.service.update(this.data.vehicle.id, this.vehicleForm.value).subscribe({
        next: () => {
          this.dialogRef.close();
          this.alertsService.success('Vehículo actualizado con éxito');
        },
        error: (error) => {
          console.log(error);
          this.alertsService.error('Error al actualizar vehículo');
        },
      });
    } else {
      this.service.create(this.vehicleForm.value).subscribe(({
        next: () => {
          this.dialogRef.close();
          this.alertsService.success('Vehículo creado con éxito');
        },
        error: (error) => {
          console.log(error);
          this.alertsService.error('Error al crear vehículo');
        },
      }));
    }
  }

  cancel() {
    this.dialogRef.close();
  }
}
