import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Company } from 'src/app/interfaces';
import { AuthService } from 'src/app/services';
import { CompanyService } from 'src/app/services/company.service';
import { ThemeService } from 'src/app/services/theme.service';
import { DASHBOARD, PAGES } from 'src/app/utils/urls';

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.scss'],
})
export class CompaniesComponent implements OnInit {
  columns: string[] = ['Logo', 'Cliente'];
  columnsNames: string[] = ['companyLogo', 'name'];

  companiesList: any = [];
  showCompanyForm: boolean = false;
  selectedCompany: Company | undefined = undefined;

  showPaginator: boolean;

  constructor(
    private companyService: CompanyService,
    private auth: AuthService,
    private router: Router,
    private themeService: ThemeService,
  ) {}

  ngOnInit(): void {
    this.read();
  }

  logout(): void {
    this.auth.logout();
  }

  read() {
    this.companyService.read().subscribe((res: Company[]) => {
      this.companiesList = res;
    });
  }

  update(id: any) {
    this.companyService.readOne(id).subscribe((res: Company) => {
      this.selectedCompany = res;
      this.showCompanyForm = true;
    });
  }

  create() {
    this.showCompanyForm = true;
    this.selectedCompany = undefined;
  }

  delete(id: any) {
    this.companyService.delete(id).subscribe(() => {
      this.read();
    });
  }

  closeForm(): void {
    this.showCompanyForm = false;
    this.selectedCompany = undefined;
    this.read();
  }

  setCompany(id: any) {
    this.companyService.readOne(id).subscribe((res: Company) => {
      this.auth.setSelectedCompany(res).subscribe(() => {
        this.themeService.updateCustomPrimaryColor();
        this.router.navigate([`${PAGES}/${DASHBOARD}`]);
      });
    });
  }
}
