import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { DatePipe } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CommonComponentsModule } from './modules/common/common.module';
import { MaterialModule } from './material/material.module';
import { PagesModule } from './modules/pages/pages.module';
import { AuthInterceptorService } from './interceptors/auth-interceptor.service';
import { ErrorCatchingInterceptor } from './services';
import {
  AuthenticationGuard,
  AuthorizationGuard,
  SelectedCompanyGuard,
} from './guards';
import { AdminModule } from './modules/admin/admin.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    RouterModule,
    HttpClientModule,
    PagesModule,
    AdminModule,
    MaterialModule,
    CommonComponentsModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorCatchingInterceptor,
      multi: true,
    },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    AuthenticationGuard,
    AuthorizationGuard,
    SelectedCompanyGuard,
    DatePipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
