<div *ngIf="audit" class="padding-component">
    <div>
        <div class="back-button-container">
            <a (click)="backToAudits()" class="back-button"><mat-icon>keyboard_arrow_left</mat-icon>Atrás</a>
        </div>
        <div class="d-flex justify-content-between">
            <div>
                <h1 class="title-component pb-4">
                    Auditoría {{audit.name}}
                </h1>
            </div>
            <div>
                <div class="d-flex">
                    <button (click)="generatePdf()" class="create-button" *ngIf="audit.completed">
                        <mat-icon>picture_as_pdf</mat-icon>
                        EXPORTAR
                    </button>
                </div>
            </div>
        </div>
        <div class="data-total">
            <div>
                <h3 class="information-title">Información</h3>
            </div>
            <div class="data-div">
                <div class="data-unit">
                    <div>
                        <mat-label type="date"><span class="span-title">Fecha de realización: </span><span
                                class="span-description">{{audit.date}}</span></mat-label>
                    </div>
                    <div>
                        <mat-label><span class="span-title">Fecha de vencimiento: </span><span
                                class="span-description">{{audit.expirationDate}}</span></mat-label>
                    </div>
                </div>
                <div class="data-unit">
                    <div>
                        <mat-label><span class="span-title">Auditado: </span><span
                                class="span-description">{{audit.client.name}}</span></mat-label>
                    </div>
                    <div>
                        <mat-label><span class="span-title">Objeto auditado: </span><span
                                class="span-description">{{audit.auditableObject.name}}</span></mat-label>
                    </div>
                </div>
                <div class="data-unit">
                    <div>
                        <mat-label><span class="span-title">Auditor: </span>{{audit.auditor.firstName}} <span
                                class="span-description">{{audit.auditor.lastName}}</span></mat-label>
                    </div>
                </div>
            </div>
        </div>


    </div>
    <mat-tab-group [selectedIndex]="selectedIndex">
        <mat-tab label="Resultados"><app-results [isAuditDetails]="true" [auditId]="id"></app-results></mat-tab>
        <mat-tab label="Hallazgos"><app-findings [isAuditDetails]="true" [auditId]="id"></app-findings></mat-tab>
        <mat-tab label="Acciones Correctivas"><app-corrective-actions [isAuditDetails]="true"
                [auditId]="id"></app-corrective-actions></mat-tab>
    </mat-tab-group>
</div>