import { HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

export class BaseService {
  protected url: string = environment.api;

  protected token = () => localStorage.getItem('token');

  protected username = () => localStorage.getItem('name');

  protected options = () => ({
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  });

  protected authorization = () => ({
    headers: new HttpHeaders({
      Authorization: `Bearer ${this.token()}`,
    }),
  });
}
