import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EntityType } from 'src/app/enums/enums';
import { IPaginatorEvent } from 'src/app/modules/common/table/table.component';
import { DateTransformService } from 'src/app/services/date-transform.service';
import { DocumentTypeService } from 'src/app/services/document-type.service';
import { DocumentTypeFormComponent } from './document-type-form/document-type-form.component';

@Component({
  selector: 'app-document-types-list',
  templateUrl: './document-types-list.component.html',
  styleUrls: ['./document-types-list.component.scss'],
})
export class DocumentTypesListComponent {
  columns = [
    'Nombre',
    'Entidad',
    'Plazo',
    'Tipo',
    'Creado por',
    'Modificado por',
  ];
  columnsNames = [
    'name',
    'entityType',
    'daysToNotify',
    'isExpiration',
    'createdBy',
    'updatedBy',
  ];

  docsList: any[];

  searchText: string;
  showLoading = false;
  usersList: any = [];
  total = 0;
  offset = 0;
  limit = 25;

  constructor(
    private documentTypeService: DocumentTypeService,
    public dialog: MatDialog,
    private dateTransformService: DateTransformService,
  ) {}

  ngOnInit() {
    this.getDocs();
  }

  onFilter() {
    this.offset = 0;
    this.getDocs();
  }

  handlePageChange(event: IPaginatorEvent) {
    this.offset = event.offset;
    this.limit = event.limit;
    this.getDocs();
  }

  getDocs() {
    const params = {
      offset: this.offset,
      limit: this.limit,
      ...(this.searchText && { search: this.searchText }),
    };
    this.documentTypeService.findAll(params).subscribe((res: any) => {
      this.showLoading = false;
      this.docsList = this.dateTransformService.transformDates(res.data);
      this.total = res.total;
      this.docsList.forEach((doc) => {
        doc.entityType =
          doc.entityType === EntityType.CLIENT
            ? 'Empleado'
            : doc.entityType === EntityType.VEHICLE
              ? 'Vehículo'
              : 'Empresa';
        doc.isExpiration = doc.isExpiration
          ? 'Con Vencimiento'
          : 'Sin Vencimiento';
      });
    });
  }

  openModal() {
    this.dialog
      .open(DocumentTypeFormComponent, {
        width: '30%',
        minWidth: '300px',
        autoFocus: false,
      })
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.getDocs();
        }
      });
  }

  update(event: number) {
    this.documentTypeService.findOne(event).subscribe((res) => {
      this.dialog
        .open(DocumentTypeFormComponent, {
          width: '30%',
          minWidth: '300px',
          data: { type: res },
          autoFocus: false,
        })
        .afterClosed()
        .subscribe(() => {
          this.getDocs();
        });
    });
  }

  delete(event: any) {
    this.documentTypeService.delete(event).subscribe(() => {
      this.getDocs();
    });
  }

  search(query: string) {
    this.offset = 0;
    this.searchText = query;
    this.showLoading = true;
    this.getDocs();
  }
}
