<audit-modal title="{{isCreate ? 'Crear Usuario' : 'Actualizar Usuario'}}">
    <form class="d-flex flex-column" [formGroup]="userForm">
        <a-input label="Nombre">
            <mat-form-field appearance="outline">
                <input formControlName="firstName" matInput placeholder="Nombre">
            </mat-form-field>
        </a-input>

        <a-input label="Apellido">
            <mat-form-field appearance="outline">
                <input formControlName="lastName" matInput placeholder="Apellido">
            </mat-form-field>
        </a-input>

        <a-input label="Rol">
            <mat-form-field appearance="outline">
                <mat-select formControlName="role" placeholder="Seleccione un rol">
                    <mat-option *ngFor="let rol of  roles" [value]="rol.id"> {{rol.name}}</mat-option>
                </mat-select>
            </mat-form-field>
        </a-input>

        <a-input label="Email">
            <mat-form-field appearance="outline">
                <input formControlName="email" matInput placeholder="Email">
            </mat-form-field>
        </a-input>

        <a-input label="Auditado" *ngIf="userForm.controls['role'].value === 4">
            <mat-form-field appearance="outline">
                <mat-select formControlName="clientId" placeholder="Seleccione un auditado">
                    <mat-option *ngFor="let client of clientList" [value]="client.id"> {{client.name}}</mat-option>
                </mat-select>
            </mat-form-field>
        </a-input>

        <a-input label="Contraseña" *ngIf="isCreate">
            <mat-form-field appearance="outline">
                <input type="password" formControlName="password" matInput placeholder="Contraseña">
            </mat-form-field>
        </a-input>

        <div class="button-container">
            <button class="close-button" (click)="cancel()">Cancelar</button>
            <button [ngClass]="!userForm.valid ? 'disabled-button' : 'create-button'" [disabled]="!userForm.valid"
                *ngIf="isCreate" (click)="submit()">Crear</button>
            <button [ngClass]="!userForm.valid ? 'disabled-button' : 'create-button'" [disabled]="!userForm.valid"
                *ngIf="!isCreate" (click)="submit()">Actualizar</button>
        </div>
    </form>
</audit-modal>