import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';
import { CorrectiveActions } from '../interfaces/corrective-actions.interface';
import { IQuery } from '../interfaces';
import { CorrectiveActionsResponse } from '../interfaces/corrective-actions-response.interface';

@Injectable({
  providedIn: 'root',
})
export class CorrectiveActionsService extends BaseService {
  constructor(private http: HttpClient, private router: Router) {
    super();
  }

  create(data: any & { file: File }): Observable<any> {
    const formData = this.form(data);
    return this.http.post<any>(`${this.url}/corrective-actions`, formData);
  }

  read(params: IQuery = {}): Observable<CorrectiveActionsResponse> {
    return this.http.get<CorrectiveActionsResponse>(`${this.url}/corrective-actions`, {
      params,
    });
  }

  readByAudit(auditId: number, params: IQuery = {}): Observable<CorrectiveActions[]> {
    return this.http.get<CorrectiveActions[]>(
      `${this.url}/corrective-actions/audit/${auditId}`,
      { params },
    );
  }

  readOne(id: number): Observable<CorrectiveActions> {
    return this.http.get<CorrectiveActions>(`${this.url}/corrective-actions/${id}`, this.options());
  }

  decide(id: number, data: any): Observable<any> {
    return this.http.put<any>(`${this.url}/corrective-actions/${id}/decide`, data, this.options());
  }

  delete(id: number) {
    return this.http.delete(`${this.url}/corrective-actions/${id}`, this.options());
  }

  private form({ file, ...ao }: CorrectiveActions & { file: File }): FormData {
    const form = new FormData();
    Object.entries(ao).forEach(([key, value]) => form.append(key, value as any));
    if (file) {
      form.append('file', file);
    }
    return form;
  }
}
