import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AlertsService } from 'src/app/services/alerts.service';
import { ClientsService } from 'src/app/services/clients.service';
import { ServicesService } from 'src/app/services/services.service';
import { UsersService } from 'src/app/services/users.service';
import { ClientsComponent } from '../clients.component';

@Component({
  selector: 'app-clients-create-update',
  templateUrl: './clients-create-update.component.html',
  styleUrls: ['./clients-create-update.component.scss'],
})
export class ClientsCreateUpdateComponent implements OnInit {
  clientForm = new FormGroup({
    name: new FormControl(
      '',
      Validators.compose([Validators.required, Validators.minLength(3)]),
    ),
    rut: new FormControl('', Validators.required),
    email: new FormControl('', [Validators.required, Validators.email]),
    phone: new FormControl('', Validators.required),
    address: new FormControl('', Validators.required),
    serviceIds: new FormControl(''),
    adminIds: new FormControl('', Validators.required),
  });

  @Output('onCancel') onCancel = new EventEmitter();
  isCreate = true;
  serviceList: any = [];
  adminList: any = [];
  locationsList: any = [];
  isSubmitting = false;

  constructor(
    private requirementsService: ClientsService,
    private servicesService: ServicesService,
    private usersService: UsersService,
    private alertsService: AlertsService,
    public dialogRef: MatDialogRef<ClientsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit(): void {
    this.read();
    if (this.data) {
      this.isCreate = false;
      const { client } = this.data;
      this.clientForm.setValue({
        name: client.name,
        rut: client.rut,
        email: client.email,
        phone: client.phone,
        address: client.address,
        serviceIds: client.clientServices.map(
          (clientService: any) => clientService.serviceId,
        ),
        adminIds: client.clientAdmins.map(
          (clientAdmin: any) => clientAdmin.adminId,
        ),
      });
    }
  }

  read() {
    this.readServices();
    this.readAdmins();
  }

  cancel() {
    this.dialogRef.close();
  }

  submit(): void {
    if (this.isSubmitting) {
      return;
    }
    this.isSubmitting = true;
    if (!this.isCreate) {
      this.update();
    } else {
      this.create();
    }
    this.dialogRef.close();
  }

  create() {
    if (this.clientForm.valid) {
      this.requirementsService.create(this.clientForm.value).subscribe({
        next: () => {
          this.dialogRef.close();
          this.alertsService.success('Auditado creado con éxito');
          this.isSubmitting = false;
        },
        error: (error) => {
          console.log(error);
          this.alertsService.error('Error al crear auditado');
        },
      });
    }
  }

  update() {
    this.requirementsService
      .update(this.data.id, this.clientForm.value)
      .subscribe({
        next: () => {
          this.alertsService.success('Auditado actualizado con éxito');
          this.dialogRef.close();
        },
        error: (error) => {
          console.log(error);
          this.alertsService.error('Error al actualizar auditado');
        },
      })
      .add(() => {
        this.isSubmitting = false;
      });
  }

  readServices() {
    this.servicesService.read().subscribe((res) => {
      this.serviceList = res.services;
    });
  }

  readAdmins() {
    this.usersService.readAdmins().subscribe((res) => {
      this.adminList = res;
    });
  }
}
