<audit-modal title="{{data?.employee ? 'Editar Empleado' : 'Crear Empleado'}}">
    <form [formGroup]="employeeForm">
        <a-input label="Nombre">
            <mat-form-field appearance="outline">
                <input formControlName="firstName" matInput placeholder="Nombre">
            </mat-form-field>
        </a-input>
        <a-input label="Apellido">
            <mat-form-field appearance="outline">
                <input formControlName="lastName" matInput placeholder="Apellido">
            </mat-form-field>
        </a-input>
        <a-input label="Documento">
            <mat-form-field appearance="outline">
                <input formControlName="dni" matInput placeholder="Documento">
            </mat-form-field>
        </a-input>
        <a-input label="Correo Electrónico">
            <mat-form-field appearance="outline">
                <input formControlName="email" matInput placeholder="Correo electrónico">
            </mat-form-field>
        </a-input>
        <a-input label="Teléfono">
            <mat-form-field appearance="outline">
                <input formControlName="phone" matInput placeholder="Teléfono">
            </mat-form-field>
        </a-input>
        <a-input label="Posición">
            <mat-form-field appearance="outline">
                <input formControlName="position" matInput placeholder="Posición">
            </mat-form-field>
        </a-input>
    </form>
<div class="button-container">
    <button class="close-button" (click)="cancel()">Cancelar</button>
    <button [ngClass]="!employeeForm.valid ? 'disabled-button' : 'create-button'" [disabled]="!employeeForm.valid" (click)="submit()">{{data?.employee ? 'Editar':'Crear'}}</button>
</div>
</audit-modal>