import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ResultService } from 'src/app/services/results.service';
import { FindingTypesService } from 'src/app/services/finding-types.service';
import { CommunicationService } from 'src/app/services/communication.service';
import { ResultsComponent } from '../results.component';

@Component({
  selector: 'app-results-update',
  templateUrl: './results-update.component.html',
  styleUrls: ['./results-update.component.scss'],
})
export class ResultsUpdateComponent implements OnInit {
  resultFindingForm = new FormGroup({
    description: new FormControl('', Validators.required),
    findingTypeId: new FormControl(null, Validators.required),
    limit: new FormControl(null, Validators.required),
    solution: new FormControl(),
  });

  findingTypesList: any = [];
  isApply = false;
  isNoApply = false;
  isNotApplicable = false;
  isNoApplyFromDatabase = false;

  constructor(
    public dialogRef: MatDialogRef<ResultsComponent>,
    private resultsService: ResultService,
    private communicationService: CommunicationService,
    private findingTypesServices: FindingTypesService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit(): void {
    this.read();
    if (this.data) {
      this.setStatus();
    }
  }

  read() {
    this.readFindingTypes();
  }

  readFindingTypes() {
    this.findingTypesServices.read().subscribe((res) => {
      this.findingTypesList = res;
    });
  }

  cancel() {
    this.dialogRef.close();
  }

  submit() {
    this.saveStatus();
    const finding = {
      description: this.resultFindingForm.value.description,
      findingTypeId: this.resultFindingForm.value.findingTypeId,
      limit: this.resultFindingForm.value.limit,
      solution: this.resultFindingForm.value.solution,
      solved: false,
    };
    if (finding) this.data.result.findings = [finding];
    this.resultsService
      .update(this.data.id, { ...this.data.result })
      .subscribe(() => {
        this.communicationService.updateFindings();
        this.dialogRef.close();
      });
  }

  setStatus() {
    if (this.data.result.value === 1) this.isApply = true;
    if (this.data.result.value === 2) {
      this.isNoApply = true;
      this.isNoApplyFromDatabase = true;
    }
    if (this.data.result.value === 3) this.isNotApplicable = true;
  }

  saveStatus() {
    if (this.isApply) this.data.result.value = 1;
    if (this.isNoApply) this.data.result.value = 2;
    if (this.isNotApplicable) this.data.result.value = 3;
  }

  toggleStatus(status: string) {
    switch (status) {
      case 'apply':
        this.isApply = true;
        this.isNoApply = false;
        this.isNotApplicable = false;
        break;
      case 'no-apply':
        this.isApply = false;
        this.isNoApply = true;
        this.isNotApplicable = false;
        break;
      case 'not-applicable':
        this.isApply = false;
        this.isNoApply = false;
        this.isNotApplicable = true;
        break;
    }
  }
}
