<mat-sidenav-container [ngClass]="isExpanded ? 'sidenav-container' : 'sidenav-container-collapsed'">
  <mat-sidenav #sidenav [ngClass]="isExpanded ? 'sidenav' : 'sidenav-collapsed'" fixedInViewport mode="side"
    opened="true">

    <div class="sidebar-container">
      <!-- LOGO TITLE -->
      <div class="logo">
        <img *ngIf="isExpanded" src="assets/logo.svg" alt="User Image">
        <img *ngIf="!isExpanded" src="assets/logo-collapsed.svg" alt="User Image">
      </div>
      <!-- MENU OPTIONS -->
      <div class="main-buttons">
        <div>
          <mat-selection-list *ngFor="let option of menuItems; let i = index;" [multiple]="false">
            <mat-list-item (click)="onOptionClick(option)" (click)="optionSelected(i)" [routerLink]="option.link">
              <div [id]="'icon-' + i" class="sidebar-options" [ngClass]="{'justify-content-center': !isExpanded }">
                <img *ngIf="option.icon" [src]="route(option.icon)" alt="">
                <span *ngIf="isExpanded" class="m-3">{{ option.title }}</span>
                <mat-icon
                  *ngIf="isExpanded && option.collapsed && option.items && option.items.length > 0">keyboard_arrow_down</mat-icon>
                <mat-icon
                  *ngIf="isExpanded && !option.collapsed && option.items && option.items.length > 0">keyboard_arrow_up</mat-icon>
              </div>
            </mat-list-item>

            <div *ngIf="isExpanded && !option.collapsed && option.items && option.items.length > 0">
              <mat-list-item *ngFor="let item of option.items; let j = index" class="child"
                (click)="optionSelected(i,j)" [routerLink]="item.link">
                <div [id]="'icon-' + i + j" class="sidebar-options"
                  [ngClass]="{'justify-content-center': !isExpanded }">
                  <img *ngIf="item.icon" [src]="route(item.icon)" alt="">
                  <span class="m-3">{{ item.title }}</span>
                </div>
              </mat-list-item>
            </div>
          </mat-selection-list>

          <mat-selection-list>
            <mat-list-item (click)="logout()">
              <div id="icon--1" class="sidebar-options" [ngClass]="{'justify-content-center': !isExpanded }">
                <img [src]="route('logout.svg')" alt="">
                <span *ngIf="isExpanded" class="m-3">Salir</span>
              </div>
            </mat-list-item>
          </mat-selection-list>
        </div>

        <div>
          <div id="icon--2" class="sidebar-options expand-button" [ngClass]="{'justify-content-center': !isExpanded }"
            (click)="setExpanded()">
            <img [src]="route('close.svg')" alt="">
            <span *ngIf="isExpanded" class="m-3">Colapsar</span>
          </div>
        </div>
      </div>
    </div>
  </mat-sidenav>
</mat-sidenav-container>