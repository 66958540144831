<div class="confirm-dialog">
  <div class="content">
    <div mat-dialog-title>
      {{ message }}
    </div>
  </div>
  <div mat-dialog-content>
    <span class="body-content">
      {{ body }}
    </span>
    <div class="d-flex justify-content-between buttons-div">
      <button class="close-button" (click)="onCancel()">Cancelar</button>
      <button class="create-button approve-button" (click)="onConfirm()">
        Confirmar
      </button>
    </div>
  </div>
</div>
