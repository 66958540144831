<audit-modal title="{{isCreate ? 'Crear Servicio' : 'Actualizar Servicio'}}">
  <form class="d-flex flex-column" [formGroup]="serviceForm">
    <a-input label="Nombre">
      <mat-form-field appearance="outline">
        <input formControlName="name" matInput placeholder="Nombre">
      </mat-form-field>
    </a-input>
    <a-input label="Checklists">
      <mat-form-field appearance="outline">
        <mat-select multiple formControlName="checklistIds" placeholder="Seleccione checklists">
          <mat-option *ngFor="let checklist of checklistList" [value]="checklist.id">{{checklist.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </a-input>
    <a-input label="Descripción">
      <mat-form-field appearance="outline">
        <textarea formControlName="description" matInput placeholder="Descripción"></textarea>
      </mat-form-field>
    </a-input>
    <div class="button-container">
      <button class="close-button" (click)="cancel()">Cancelar</button>
      <button [ngClass]="!serviceForm.valid ? 'disabled-button' : 'create-button'" [disabled]="!serviceForm.valid"
        *ngIf="isCreate" (click)="submit()">Crear</button>
      <button [ngClass]="!serviceForm.valid ? 'disabled-button' : 'create-button'" [disabled]="!serviceForm.valid"
        class="create-button" *ngIf="!isCreate" (click)="submit()">Actualizar</button>
    </div>
  </form>
</audit-modal>