<app-admin-toast></app-admin-toast>
<div class="padding-component">
    <app-header [title]="'Usuarios'"></app-header>
    <div class="d-flex justify-content-between">
        <div>
            <searcher [label]="'Buscar Usuarios'" (onChange)="search($event)" [showLoading]="showLoading"></searcher>
        </div>
        <div>
            <button (click)="create()" class="create-button">
                Crear Usuario
            </button>
        </div>
    </div>
    <app-table [data]="usersList" [displayedColumns]="columns" [columnsNames]="columnsNames" [total]="total" [offset]="offset" [limit]="limit" (update)="update($event)"
        (delete)="delete($event)" (onPageChange)="handlePageChange($event)"></app-table>

</div>