import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EntityType } from 'src/app/enums/enums';
import { IPaginatorEvent } from 'src/app/modules/common/table/table.component';
import { DateTransformService } from 'src/app/services/date-transform.service';
import { DocumentService } from 'src/app/services/document.service';
import { DocumentFormComponent } from '../document-form/document-form.component';

@Component({
  selector: 'app-car-documents-list',
  templateUrl: './car-documents-list.component.html',
  styleUrls: ['./car-documents-list.component.scss'],
})
export class CarDocumentsListComponent {
  columns = ['Nombre', 'Vehiculo', 'Tipo', 'Fecha de Vencimiento', 'Creado por', 'Modificado por'];
  columnsNames = ['name', 'clientName', 'documentType', 'expiration', 'createdBy', 'updatedBy'];

  docsList: any[];

  searchText: string;
  showLoading: boolean = false;
  usersList: any = [];
  total: number = 0;
  offset: number = 0;
  limit: number = 25;

  constructor(
    private documentService: DocumentService,
    public dialog: MatDialog,
    private dateTransformService: DateTransformService,
  ) { }

  ngOnInit() {
    this.getVehicleDocs();
  }

  search(query: string) {
    this.searchText = query;
    this.showLoading = true;
    this.getVehicleDocs();
  }

  handlePageChange(event: IPaginatorEvent) {
    this.offset = event.offset;
    this.limit = event.limit;
    this.getVehicleDocs();
  }

  getVehicleDocs() {
    const params = {
      offset: this.offset,
      limit: this.limit,
      entityType: EntityType.VEHICLE,
      ...(this.searchText && { search: this.searchText }),
    };
    this.documentService.findAll(2, params).subscribe((res:any) => {
      this.showLoading = false;
      this.docsList = this.dateTransformService.transformDates(res.data);
      this.total = res.total;
    });
  }

  openModal() {
    this.dialog.open(DocumentFormComponent, {
      width: '30%',
      minWidth: '300px',
      data: { isClient: false, isVehicle: true },
      autoFocus: false,
    }).afterClosed().subscribe(() => {
      this.getVehicleDocs();
    });
  }

  update(event:number) {
    this.documentService.findOne(event)
      .subscribe((res) => {
        this.dialog.open(DocumentFormComponent, {
          width: '30%',
          minWidth: '300px',
          data: { isClient: false, isVehicle: true, document: res },
          autoFocus: false,
        }).afterClosed().subscribe(() => {
          this.getVehicleDocs();
        });
      });
  }

  delete(event:any) {
    this.documentService.delete(event)
      .subscribe(() => {
        this.getVehicleDocs();
      });
  }
}
