import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DocumentType } from 'src/app/interfaces/document-type.interface';
import { AlertsService } from 'src/app/services/alerts.service';
import { DocumentTypeService } from 'src/app/services/document-type.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-document-type-form',
  templateUrl: './document-type-form.component.html',
  styleUrls: ['./document-type-form.component.scss'],
})
export class DocumentTypeFormComponent {
  docTypeForm: FormGroup = new FormGroup({
    name: new FormControl(null, Validators.required),
    description: new FormControl(),
    entityType: new FormControl(1, Validators.required),
    isExpiration: new FormControl(false, Validators.required),
    usersToNotifyIds: new FormControl(null, Validators.required),
    daysToNotify: new FormControl(0, [Validators.required, Validators.min(0)]),
  });

  title: string = this.data?.type ? 'Editar tipo de documento' : 'Crear tipo de documento';
  usersList: any = [];

  constructor(
    private service: DocumentTypeService,
    private usersService: UsersService,
    private alertsService: AlertsService,
    public dialogRef: MatDialogRef<DocumentTypeFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {type?: DocumentType},
  ) {}

  ngOnInit() {
    this.readAdmins();
    if (this.data?.type) {
      this.setForm();
    }
  }

  setForm() {
    this.docTypeForm.patchValue({
      name: this.data.type?.name,
      description: this.data.type?.description,
      entityType: this.data.type?.entityType,
      isExpiration: this.data.type?.isExpiration,
      usersToNotifyIds: this.data.type?.users,
      daysToNotify: this.data.type?.daysToNotify,
    });
  }

  submit() {
    if (this.data?.type) {
      this.service.update(this.data.type.id, this.docTypeForm.value).subscribe({
        next: () => {
          this.dialogRef.close(true);
          this.alertsService.success('Tipo de documento editado con éxito');
        },
        error: (error) => {
          console.error(error);
          this.alertsService.error('Error al editar tipo de documento');
        },
      });
    } else {
      this.service.create(this.docTypeForm.value).subscribe({
        next: () => {
          this.dialogRef.close(true);
          this.alertsService.success('Tipo de documento creado con éxito');
        },
        error: (error) => {
          console.error(error);
          this.alertsService.error('Error al crear tipo de documento');
        },
      });
    }
  }

  readAdmins() {
    this.usersService.readAdmins().subscribe((res) => {
      this.usersList = res;
    });
  }

  cancel() {
    this.dialogRef.close();
  }
}
