import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BaseService } from './base.service';
import { Observable } from 'rxjs';
import { Service } from '../interfaces/service.interface';
import { IQuery } from '../interfaces';
import { ServicesResponse } from '../interfaces/sevices-response.interface';

@Injectable({
  providedIn: 'root',
})
export class ServicesService extends BaseService {
  constructor(private http: HttpClient, private router: Router) {
    super();
  }
  create(data: any) {
    return this.http.post(`${this.url}/services`, data);
  }


  read(params: IQuery = {}): Observable<ServicesResponse> {
    return this.http.get<ServicesResponse>(`${this.url}/services`, {
      params,
    });
  }

  readByClientId(clientId: number): Observable<Service[]> {
    return this.http.get<Service[]>(
      `${this.url}/services/client/${clientId}`
    );
  }

  readOne(id: number) {
    return this.http.get(`${this.url}/services/${id}`, this.options());
  }

  update(id: number, data: any) {
    return this.http.put(`${this.url}/services/${id}`, data);
  }

  delete(id: number) {
    return this.http.delete(`${this.url}/services/${id}`);
  }
}
