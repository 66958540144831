import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AdminComponent } from './admin.component';
import { CompaniesComponent } from './companies/companies.component';
import { AdminRoutingModule } from './admin-routing.module';
import { CommonComponentsModule } from '../common/common.module';
import { CompaniesCreateUpdateComponent } from './companies/clients-create-update/companies-create-update.component';
import { MaterialModule } from 'src/app/material/material.module';

@NgModule({
  declarations: [
    CompaniesComponent,
    AdminComponent,
    CompaniesCreateUpdateComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    FormsModule,
    MaterialModule,
    AdminRoutingModule,
    CommonComponentsModule,
    MatIconModule,
  ],
})
export class AdminModule {}
