import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FindingsService } from 'src/app/services/findigs.service';
import { AUDITS, PAGES } from 'src/app/utils/urls';
import { CorrectiveActionsCreateUpdateComponent } from '../../audits/corrective-actions/corrective-actions-create-update/corrective-actions-create-update.component';

@Component({
  selector: 'app-findings-dashboard',
  templateUrl: './findings.component.html',
  styleUrls: ['./findings.component.scss']
})
export class FindingsDashboardComponent {
  @Input('finding') finding: any;
  @Input('isExpired') isExpired: boolean = false;
  @Input('isClient') isClient: boolean = false;

  constructor(
    private router: Router,
    private findingsService: FindingsService,
    private dialog: MatDialog,
  ) { }

  daysLeft: number;

  ngOnInit() {
    this.calculateDaysLeft();
  }

  seeMore() {
    this.router.navigate([`/${PAGES}/${AUDITS}/${this.finding.auditId}`], { queryParams: { tab: 'findings' } });
  }

  onAuditCode() {
    return `/${PAGES}/${AUDITS}/${this.finding.auditId}`;
  }

  calculateDaysLeft() {
    const currentDate = new Date();

    if (this.finding.date) {
      const dateComponents = this.finding.date.split('/');

      if (dateComponents.length === 3) {
        const day = parseInt(dateComponents[0], 10);
        const month = parseInt(dateComponents[1], 10) - 1;
        const year = parseInt(dateComponents[2], 10);

        const auditDate = new Date(year, month, day);

        const timeDifference = auditDate.getTime() - currentDate.getTime();

        const daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24));

        this.daysLeft = Math.abs(daysDifference + 1);
      }
    }
  }

  createCorrectiveAction(){
    this.findingsService.readOne(this.finding.id).subscribe((res: any) => {
      this.dialog.open(
        CorrectiveActionsCreateUpdateComponent,
        {
          data: { finding: res, id: this.finding.id },
          autoFocus: false,
        },
      );
    });
  }
}

