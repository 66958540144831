<div>
  <div class="d-flex justify-content-between mt-4">
    <div>
      <searcher
        [label]="'Buscar Controles'"
        (onChange)="search($event)"
        [showLoading]="showLoading"
        [placeHolder]="'Buscar por nombre'"
      ></searcher>
    </div>
    <div class="action-buttons">
      <button
        (click)="download()"
        class="close-button"
        style="margin-right: 10px"
      >
        Exportar
      </button>
      <button
        (click)="create()"
        mat-raised-button
        color="primary"
        class="create-button"
        *ngIf="canCreate"
      >
        Crear Control
      </button>
      <button
        (click)="loadFromFile()"
        mat-raised-button
        color="primary"
        class="create-button"
        *ngIf="canCreate"
      >
        Cargar desde archivo
      </button>
    </div>
  </div>
  <app-table
    [data]="controlList"
    [displayedColumns]="columns"
    [columnsNames]="columnsNames"
    (update)="update($event)"
    (onPageChange)="handlePageChange($event)"
    (delete)="delete($event)"
    (read)="showInfo($event)"
    [showInfo]="true"
    [total]="total"
    [offset]="offset"
    [limit]="limit"
  ></app-table>
</div>
