<audit-modal title="{{data?.type ? 'Editar tipo de documento' : 'Crear tipo de documento'}}">
    <form [formGroup]="docTypeForm" class="mt-4">
        <a-input label="Nombre">
            <mat-form-field appearance="outline">
                <input formControlName="name" matInput placeholder="Nombre">
            </mat-form-field>
        </a-input>
        <a-input label="Tipo de entidad">
            <mat-form-field appearance="outline">
                <mat-select formControlName="entityType">
                    <mat-option [value]="1">Empleado</mat-option>
                    <mat-option [value]="2">Vehiculo</mat-option>
                    <mat-option [value]="3">Empresa</mat-option>
                </mat-select>
            </mat-form-field>
        </a-input>
        <a-input label="Plazo Notificación">
            <mat-form-field appearance="outline">
                <input formControlName="daysToNotify" matInput type="number" min="0">
            </mat-form-field>
        </a-input>
        <div class="toggle-row">
            <mat-button-toggle-group formControlName="isExpiration">
                <mat-button-toggle class="toggle-button" [value]="false">Sin Vencimiento</mat-button-toggle>
                <mat-button-toggle class="toggle-button" [value]="true">Con Vencimiento</mat-button-toggle>
            </mat-button-toggle-group>
        </div>
        <a-input label="Usuarios a notificar">
            <mat-form-field appearance="outline">
                <mat-select multiple formControlName="usersToNotifyIds" placeholder="Seleccione usuarios a notificar">
                    <mat-option *ngFor="let user of usersList" [value]="user.id">
                        {{user.firstName}} {{user.lastName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </a-input>
        <a-input label="Descripción">
            <mat-form-field appearance="outline">
                <textarea formControlName="description" matInput placeholder="Descripción"></textarea>
            </mat-form-field>
        </a-input>
    </form>
    <div class="button-container">
        <button class="close-button" (click)="cancel()">Cancelar</button>
        <button [ngClass]="!docTypeForm.valid ? 'disabled-button' : 'create-button'" [disabled]="!docTypeForm.valid" (click)="submit()">{{data?.type ? 'Editar':'Crear'}}</button>
    </div>
</audit-modal>