import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { BaseService } from './base.service';
import { Audit } from '../interfaces/audit.interface';
import { IQuery } from '../interfaces';
import { AuditResponse } from '../interfaces/audit-response.interface';

@Injectable({
  providedIn: 'root',
})
export class AuditsService extends BaseService {
  constructor(private http: HttpClient, private router: Router) {
    super();
  }

  create(data: any) {
    return this.http.post(`${this.url}/audits`, data, this.options());
  }

  read(params: IQuery = {}): Observable<AuditResponse> {
    return this.http.get<AuditResponse>(`${this.url}/audits`, {
      params,
    });
  }

  readOne(id: number, params: IQuery = {}): Observable<Audit> {
    return this.http.get<Audit>(`${this.url}/audits/${id}`, {params});
  }

  update(id: number, data: any) {
    return this.http.put(`${this.url}/audits/${id}`, data);
  }

  delete(id: number) {
    return this.http.delete(`${this.url}/audits/${id}`);
  }

  details(id: number){
    return this.http.get<Audit>(`${this.url}/audits/audit-details/${id}`);
  }

  getPdf(id: number): Observable<any>{
    const headers = new HttpHeaders({
      'Content-Type': 'application/pdf',
    });

    return this.http.get(`${this.url}/audits/pdf/${id}`, {
      headers: headers,
      responseType: 'blob',
    });
  }
}
