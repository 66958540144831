import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonComponentsModule } from '../common/common.module';
import { GMapsModule } from '../common/google-maps/google-maps.module';
import { MaterialModule } from '../../material/material.module';
import { AuditableObjectDashboardComponent } from './auditable-object/auditable-object-dashboard.component';
import { AuditableObjectCreateUpdateComponent } from './auditable-object/auditable-object/auditable-object-create-update/auditable-object-create-update.component';
import { AuditableObjectComponent } from './auditable-object/auditable-object/auditable-object.component';
import { AuditDashboardComponent } from './audits/audit-dashboard.component';
import { AuditReportComponent } from './audits/audit/audit-report/audit-report.component';
import { AuditViewComponent } from './audits/audit/audit-report/audit-view/audit-view.component';
import { AuditsCreateUpdateComponent } from './audits/audit/audits-create-update/audits-create-update.component';
import { AuditsComponent } from './audits/audit/audits.component';
import { CorrectiveActionsCreateUpdateComponent } from './audits/corrective-actions/corrective-actions-create-update/corrective-actions-create-update.component';
import { CorrectiveActionsComponent } from './audits/corrective-actions/corrective-actions.component';
import { FindingsComponent } from './audits/findings/findings.component';
import { ResultsUpdateComponent } from './audits/results/results-update/results-update.component';
import { ResultsComponent } from './audits/results/results.component';
import { ClientsDashboardComponent } from './clients/clients-dashboard.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AuditsDashboardComponent } from './dashboard/audits/audits.component';
import { FindingsDashboardComponent } from './dashboard/findings/findings.component';
import { CorrectiveActionsDashboardComponent } from './dashboard/corrective-actions/corrective-actions.component';

import { PagesRoutingModule } from './pages-routing.module';
import { PagesComponent } from './pages.component';
import { CheckListTypeCreateUpdateComponent } from './services/check-lists-type/check-list-type-create-update/check-list-type-create-update.component';
import { CheckListsTypeComponent } from './services/check-lists-type/check-lists-type.component';
import { CheckListCreateUpdateComponent } from './services/check-lists/check-list-create-update/check-list-create-update.component';
import { CheckListComponent } from './services/check-lists/check-lists.component';
import { ServiceDashboardComponent } from './services/service-dashboard.component';
import { ControlsCreateUpdateComponent } from './services/service/controls/controls-create-update/controls-create-update.component';
import { ControlsReadComponent } from './services/service/controls/controls-read/controls-read.component';
import { ControlsComponent } from './services/service/controls/controls.component';
import { ServiceCreateUpdateComponent } from './services/service/service-create-update/service-create-update.component';
import { ServicesComponent } from './services/service/services.component';
import { UsersCreateUpdateComponent } from './users/users-create-update/users-create-update.component';
import { UsersComponent } from './users/users.component';
import { ClientsComponent } from './clients/client/clients.component';
import { ClientsCreateUpdateComponent } from './clients/client/clients-create-update/clients-create-update.component';
import { LocationsComponent } from './clients/locations/locations.component';
import { LocationsCreateUpdateComponent } from './clients/locations/locations-create-update/locations-create-update.component';
import { ClientLocationsComponent } from './clients/client-locations/client-locations.component';
import { SearcherComponent } from '../common/searcher/searcher.component';
import { DocumentsComponent } from './documents/documents.component';
import { DocumentTypeFormComponent } from './documents/document-types-list/document-type-form/document-type-form.component';
import { DocumentTypesListComponent } from './documents/document-types-list/document-types-list.component';
import { CarDocumentsListComponent } from './documents/car-documents-list/car-documents-list.component';
import { ClientDocumentsListComponent } from './documents/client-documents-list/client-documents-list.component';
import { DocumentFormComponent } from './documents/document-form/document-form.component';
import { VehiclesComponent } from './resources/vehicles/vehicles.component';
import { VehicleFormComponent } from './resources/vehicles/vehicle-form/vehicle-form.component';
import { EmployeeFormComponent } from './resources/employee-form/employee-form.component';
import { EmployeeListComponent } from './resources/employee-list/employee-list.component';
import { AttendanceListComponent } from './resources/attendance-list/attendance-list.component';
import { ResourcesComponent } from './resources/resources.component';
import { CorrectiveActionsApprovalComponent } from './audits/corrective-actions/corrective-actions-approval/corrective-actions-approval.component';
import { CompanyDocumentsListComponent } from './documents/company-documents-list/company-documents-list.component';
import { ControlsFileUploadComponent } from './services/service/controls/controls-file-upload/controls-file-upload.component';
import { FileDragNDropDirective } from '../common/directives/file-dragndrop.directive';

@NgModule({
  declarations: [
    PagesComponent,
    ControlsCreateUpdateComponent,
    ControlsComponent,
    ControlsReadComponent,
    ServicesComponent,
    ServiceCreateUpdateComponent,
    ServiceDashboardComponent,
    CheckListCreateUpdateComponent,
    CheckListComponent,
    CheckListsTypeComponent,
    CheckListTypeCreateUpdateComponent,
    UsersCreateUpdateComponent,
    UsersComponent,
    LocationsComponent,
    LocationsCreateUpdateComponent,
    AuditableObjectComponent,
    AuditableObjectCreateUpdateComponent,
    AuditableObjectDashboardComponent,
    AuditsComponent,
    AuditsCreateUpdateComponent,
    SearcherComponent,
    ResultsComponent,
    ResultsUpdateComponent,
    AuditDashboardComponent,
    FindingsComponent,
    CorrectiveActionsComponent,
    CorrectiveActionsCreateUpdateComponent,
    AuditReportComponent,
    AuditViewComponent,
    ClientsDashboardComponent,
    DashboardComponent,
    AuditsDashboardComponent,
    FindingsDashboardComponent,
    CorrectiveActionsDashboardComponent,
    ClientsComponent,
    ClientsCreateUpdateComponent,
    ClientLocationsComponent,
    DocumentsComponent,
    DocumentTypeFormComponent,
    DocumentTypesListComponent,
    CarDocumentsListComponent,
    ClientDocumentsListComponent,
    DocumentFormComponent,
    VehiclesComponent,
    VehicleFormComponent,
    EmployeeFormComponent,
    EmployeeListComponent,
    AttendanceListComponent,
    ResourcesComponent,
    CorrectiveActionsApprovalComponent,
    CompanyDocumentsListComponent,
    ControlsFileUploadComponent,
    FileDragNDropDirective,
  ],
  imports: [
    CommonModule,
    PagesRoutingModule,
    RouterModule,
    MaterialModule,
    CommonComponentsModule,
    GMapsModule,
  ],
  exports: [],
})
export class PagesModule {}
