import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuditsCreateUpdateComponent } from '../audits/audit/audits-create-update/audits-create-update.component';
import { MatDialog } from '@angular/material/dialog';
import { AuditDashboard } from 'src/app/interfaces/audit-dashboard.interface';
import { FindingDashboard } from 'src/app/interfaces/finding-dashboard.interface';
import { CorrectiveActionDashboard } from 'src/app/interfaces/corrective-action-dashboard.interface';
import { DateTransformService } from 'src/app/services/date-transform.service';
import { DashboardService } from 'src/app/services/dashboard.service';
import { Dashboard } from 'src/app/interfaces/dashboard.interface';
import { AUDITS, PAGES } from 'src/app/utils/urls';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  //RESUME SECTION//
  totalAudits: number;
  totalFindings: number;
  totalCorrectiveAction: number;
  completedAuditsCount: number;
  beforeExpiredAuditsCount: number;
  resolvedFindingsCount: number;
  pendingCorrectiveActionsCount: number;
  evaluatedCorectiveActionsCount: number;

  //CARDS SECTION//
  expiredFindingsCount: number;
  expiredAuditsCount: number;
  toOvercomeAuditsCount: number;
  inProcessAuditsCount: number;
  futureAuditsCount: number;
  toDoAuditsCount: number;
  toOvercomeFindingsCount: number;
  rejectedCorrectiveActionsCount: number;

  //LISTS//
  expiredAudits: AuditDashboard[] = [];
  toOvercomeAudits: AuditDashboard[] = [];
  inProcessAudits: AuditDashboard[] = [];
  futureAudits: AuditDashboard[] = [];
  toDoAudits: AuditDashboard[] = [];

  expiredFindings: FindingDashboard[] = [];
  toOvercomeFindings: FindingDashboard[] = [];

  pendingCorrectiveActions: CorrectiveActionDashboard[] = [];
  rejectedCorrectiveActions: CorrectiveActionDashboard[] = [];

  isClient = false;
  isSuperAdmin = false;
  isAuditor = false;
  isAdmin = false;

  startDate: Date;
  endDate: Date;
  startDateFormatted: string;
  endDateFormatted: string;

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private service: DashboardService,
    private dateTransformService: DateTransformService,
  ) {}

  ngOnInit(): void {
    this.read();
    this.setDates();
    const storedRoleValue = localStorage.getItem('role');
    this.mapStoredValueToEnum(Number(storedRoleValue));
  }

  read() {
    this.service.get().subscribe((res: Dashboard) => {
      const { resume, audits, findings, correctiveActions } = res;

      //Resume section
      this.completedAuditsCount = resume.auditsCompleted;
      this.beforeExpiredAuditsCount = resume.auditsExpired;
      this.totalAudits = resume.totalAudits;
      this.resolvedFindingsCount = resume.findingsResolved;
      this.totalFindings = resume.totalFindings;
      this.pendingCorrectiveActionsCount = resume.correctiveActionsToEvaluate;
      this.totalCorrectiveAction = resume.totalCorrectiveActions;
      this.evaluatedCorectiveActionsCount = resume.evaluatedCorrectiveActions;

      //Audits cards section
      this.expiredAudits = audits.expiredAudits;
      this.toOvercomeAudits = audits.toOvercomeAudits;
      this.inProcessAudits = audits.inProcessAudits;
      this.futureAudits = audits.futureAudits;
      this.toDoAudits = audits.toDoAudits;
      this.expiredAuditsCount = this.expiredAudits.length;
      this.toOvercomeAuditsCount = this.toOvercomeAudits.length;
      this.inProcessAuditsCount = this.inProcessAudits.length;
      this.futureAuditsCount = this.futureAudits.length;
      this.toDoAuditsCount = this.toDoAudits.length;

      //Findings cards section
      this.expiredFindings = findings.expiredFindings;
      this.toOvercomeFindings = findings.toOvercomeFindings;
      this.expiredFindingsCount = this.expiredFindings.length;
      this.toOvercomeFindingsCount = this.toOvercomeFindings.length;

      //Corrective actions section
      this.pendingCorrectiveActions =
        correctiveActions.pendingCorrectiveActions;
      this.rejectedCorrectiveActions =
        correctiveActions.rejectedCorrectiveActions;
      this.rejectedCorrectiveActionsCount =
        this.rejectedCorrectiveActions.length;

      this.formatDates();
    });
  }

  formatDates() {
    this.dateTransformService.transformDates(this.expiredAudits);
    this.dateTransformService.transformDates(this.inProcessAudits);
    this.dateTransformService.transformDates(this.futureAudits);
    this.dateTransformService.transformDates(this.toDoAudits);
    this.dateTransformService.transformDates(this.toOvercomeAudits);
    this.dateTransformService.transformDates(this.expiredFindings);
    this.dateTransformService.transformDates(this.toOvercomeFindings);
  }

  seeMoreAudits() {
    return `/${PAGES}/${AUDITS}/`;
  }

  seeMoreFindings() {
    this.router.navigate([`/${PAGES}/${AUDITS}`], {
      queryParams: { tab: 'findings' },
    });
  }

  seeMoreCorrectiveActions() {
    this.router.navigate([`/${PAGES}/${AUDITS}`], {
      queryParams: { tab: 'corrective-actions' },
    });
  }

  createAudit() {
    this.dialog.open(AuditsCreateUpdateComponent, {
      autoFocus: false,
      disableClose: true,
      maxHeight: '90%',
      panelClass: 'modal-styles',
    });
  }

  setDates() {
    const sevenDays = new Date();
    sevenDays.setDate(sevenDays.getDate() + 7);
    this.endDate = sevenDays;

    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
    this.startDate = sevenDaysAgo;

    this.endDateFormatted = this.formatDate(this.endDate);
    this.startDateFormatted = this.formatDate(this.startDate);
  }

  formatDate(date: Date): string {
    const daysOfWeek = [
      'Domingo',
      'Lunes',
      'Martes',
      'Miércoles',
      'Jueves',
      'Viernes',
      'Sábado',
    ];
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear() % 100;

    const dayOfWeek = daysOfWeek[date.getDay()];

    return `${dayOfWeek} ${this.padZero(day)}/${this.padZero(month)}/${this.padZero(year)}`;
  }

  padZero(num: number): string {
    return num < 10 ? `0${num}` : `${num}`;
  }

  getCompletionWidth(count: number, total: number): string {
    if (total > 0) {
      const percentage = Math.round(
        Math.min(Math.max((count / total) * 100, 0), 100),
      );
      return `${percentage}%`;
    } else {
      return '0%';
    }
  }

  mapStoredValueToEnum(value: number): boolean {
    switch (value) {
      case 1:
        return (this.isSuperAdmin = true);
      case 2:
        return (this.isAdmin = true);
      case 3:
        return (this.isAuditor = true);
      case 4:
        return (this.isClient = true);
      default:
        throw new Error();
    }
  }

  showExpiredAudits() {
    return `/${PAGES}/${AUDITS}/`;
  }
}
