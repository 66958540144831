/* eslint-disable no-undef */
/// <reference types="@types/google.maps" />

import {
  Component,
  ElementRef,
  Input,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

const DEFAULT_ICON_URL =
  'https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/geocode-71.png';
const DEFAULT_ICON = {
  url: DEFAULT_ICON_URL,
  size: new google.maps.Size(71, 71),
  origin: new google.maps.Point(0, 0),
  anchor: new google.maps.Point(17, 34),
  scaledSize: new google.maps.Size(25, 25),
};

@Component({
  selector: 'audits-google-maps',
  templateUrl: './google-maps.component.html',
  styleUrls: ['./google-maps.component.scss'],
})
export class GoogleMapsComponent {
  @Input('lat') lat: number;
  @Input('lng') lng: number;
  @Input('address') address: string;
  @Input('label') label = 'Buscar ubicación';
  @Input('labelOn') labelOn = true;

  currentLat: number;
  currentLng: number;
  geolocationWorking = false;
  center = {
    lat: 24,
    lng: 12,
  };
  marker: google.maps.Marker = new google.maps.Marker();
  map: google.maps.Map;
  locationSearch = '';

  @ViewChild('googleMapsSearcher') googleMapsSearcher: ElementRef;
  @ViewChild('map') mapElement: ElementRef;

  ngOnChanges(changes: SimpleChanges): void {
    if (this.map && (changes['lat'] || changes['lng'] || changes['address'])) {
      this.updateMap();
    } else {
      this.ngAfterViewInit();
    }
  }

  private updateMap(): void {
    const latLng = new google.maps.LatLng(this.lat, this.lng);
    this.map.panTo(latLng);
    if (this.marker) {
      this.marker.setPosition(latLng);
    } else {
      this.setMarker();
    }
  }

  async ngAfterViewInit() {
    if (this.address) {
      this.locationSearch = this.address;
      this.googleMapsSearcher.nativeElement.value = this.address;
    }
    if (this.mapElement) {
      this.map = new google.maps.Map(this.mapElement.nativeElement, {
        center: { lat: -33.8688, lng: 151.2195 },
        zoom: 14,
      });
      this.map.addListener('click', (event: any) => {
        this.marker.setMap(null);
        this.marker = new google.maps.Marker({
          position: event.latLng,
          map: this.map,
          icon: DEFAULT_ICON,
        });
      });
      if (this.labelOn) this.createSearchInput();
      this.getCurrentLocation();
    }
  }

  private getCurrentLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position: GeolocationPosition) => {
          this.geolocationWorking = true;
          this.currentLat = position.coords.latitude;
          this.currentLng = position.coords.longitude;
          this.setMarker();
        },
        () => {
          this.geolocationWorking = false;
          this.setMarker();
        },
      );
    } else {
      this.geolocationWorking = false;
      this.setMarker();
    }
  }

  private setMarker() {
    if (this.lat && this.lng) {
      this.center = {
        lat: Number(this.lat),
        lng: Number(this.lng),
      };
    } else if (this.geolocationWorking) {
      this.center = {
        lat: this.currentLat,
        lng: this.currentLng,
      };
    }
    this.map.setCenter(this.center);
    this.marker = new google.maps.Marker({
      position: this.center,
      map: this.map,
      icon: DEFAULT_ICON,
    });
  }

  private createSearchInput() {
    const searchBox = new google.maps.places.SearchBox(
      this.googleMapsSearcher.nativeElement,
    );
    this.map.addListener('bounds_changed', () => {
      searchBox.setBounds(this.map.getBounds() as google.maps.LatLngBounds);
    });

    // Listen for the event fired when the user selects a
    // prediction and retrieve more details for that place.
    searchBox.addListener('places_changed', () => {
      const places = searchBox.getPlaces() as google.maps.places.PlaceResult[];

      if (!places || places?.length === 0) {
        return;
      }

      // Clear out old marker.
      this.marker.setMap(null);

      places?.forEach((place) => {
        if (!place.geometry || !place.geometry.location) {
          return;
        }
        this.locationSearch = place.formatted_address || '';
        this.marker = new google.maps.Marker({
          map: this.map,
          icon: DEFAULT_ICON,
          title: place.name,
          position: place.geometry.location,
        });
        this.map.panTo(place.geometry.location);
      });
    });
  }

  public getFormValues(): {
    lng: number | undefined;
    lat: number | undefined;
    address: string;
  } {
    return {
      lng: Number(this.marker.getPosition()?.lng()),
      lat: Number(this.marker.getPosition()?.lat()),
      address: this.locationSearch,
    };
  }
}
