import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ControlPriority } from 'src/app/enums/enums';
import { ControlsComponent } from '../controls.component';

@Component({
  selector: 'app-controls-read',
  templateUrl: './controls-read.component.html',
  styleUrls: ['./controls-read.component.scss'],
})
export class ControlsReadComponent implements OnInit {
  control: any;
  controlForm = new FormGroup({
    name: new FormControl(''),
    description: new FormControl(''),
    priority: new FormControl(),
    requirement: new FormControl(''),
    stopActivity: new FormControl(''),
    recommendedDays: new FormControl(),
    penaltyPoints: new FormControl(),
    createdAt: new FormControl(''),
    updatedAt: new FormControl(''),
    createdBy: new FormControl(''),
    updatedBy: new FormControl(''),
  });

  constructor(
    public dialogRef: MatDialogRef<ControlsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    const controlObj = this.data.control;
    this.control = controlObj;

    this.controlForm.patchValue({
      name: this.data.control.name,
      description: controlObj.description,
      requirement: controlObj.requirement?.name,
      priority: this.getPriority(),
      stopActivity: controlObj.stopActivity ? 'Si' : 'No',
      recommendedDays: controlObj.recommendedDays,
      penaltyPoints: controlObj.penaltyPoints,
      createdAt: controlObj.createdAt?.slice(0, 10),
      updatedAt: controlObj.updatedAt?.slice(0, 10),
      createdBy: controlObj.createdBy
        ? `${controlObj.createdBy.firstName} ${controlObj.createdBy.lastName}`
        : '',
      updatedBy: controlObj.updatedBy
        ? `${controlObj.updatedBy.firstName} ${controlObj.updatedBy.lastName}`
        : '',
    });
  }

  getPriority = (): string => {
    switch (this.data.control.priority) {
      case ControlPriority.BAJA:
        return 'Baja';
      case ControlPriority.MEDIA:
        return 'Media';
      case ControlPriority.ALTA:
        return 'Alta';
      default:
        return '';
    }
  };

  cancel() {
    this.dialogRef.close();
  }
}
