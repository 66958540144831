import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  HttpEvent,
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AlertsService } from './alerts.service';

export enum HttpResponseCode {
  unauthorized = 401,
}

@Injectable()
export class ErrorCatchingInterceptor implements HttpInterceptor {
  constructor(private router: Router, private alerts: AlertsService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any> | any> {
    return next.handle(request).pipe(
      catchError(({ status, error }) => {
        const { message } = error;

        if (status === HttpResponseCode.unauthorized) {
          this.alerts.error('No tiene permiso para acceder a esta página');
          this.router.navigate(['auth/login']);
        }

        return throwError(() => ({ status, message }));
      }),
    );
  }
}
