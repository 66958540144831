import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Employee } from 'src/app/interfaces/employee.interface';
import { AlertsService } from 'src/app/services/alerts.service';
import { EmployeesService } from 'src/app/services/employees.service';

@Component({
  selector: 'app-employee-form',
  templateUrl: './employee-form.component.html',
  styleUrls: ['./employee-form.component.scss'],
})
export class EmployeeFormComponent {
  employeeForm = new FormGroup({
    firstName: new FormControl<string | null>(null, Validators.required),
    lastName: new FormControl<string | null>(null, Validators.required),
    dni: new FormControl<string | null>(null, Validators.required),
    email: new FormControl<string | null>(null, Validators.email),
    phone: new FormControl<string | null>(null),
    position: new FormControl<string | null>(null),
  });

  constructor(
    private employeeService: EmployeesService,
    public dialogRef: MatDialogRef<EmployeeFormComponent>,
    private alertsService: AlertsService,
    @Inject(MAT_DIALOG_DATA) public data: { employee?: Employee},
  ) { }

  ngOnInit() {
    if (this.data?.employee) this.setForm();
  }

  setForm() {
    this.employeeForm.patchValue({
      firstName: this.data.employee?.firstName,
      lastName: this.data.employee?.lastName,
      dni: this.data.employee?.dni,
      email: this.data.employee?.email,
      phone: this.data.employee?.phone,
      position: this.data.employee?.position,
    });
  }

  submit() {
    if (this.data?.employee) {
      this.employeeService.update(this.data.employee.id, this.employeeForm.value)
        .subscribe({
          next: () => {
            this.dialogRef.close();
            this.alertsService.success('Empleado actualizado con éxito');
          },
          error: (error) => {
            console.log(error);
            this.alertsService.error('Error al actualizar empleado');
          },
        });
    } else {
      this.employeeService.create(this.employeeForm.value).subscribe(({
        next: () => {
          this.dialogRef.close(true);
          this.alertsService.success('Empleado creado con éxito');
        },
        error: (error) => {
          console.log(error);
          this.alertsService.error('Error al crear empleado');
        },
      }));
    }
  }

  cancel() {
    this.dialogRef.close();
  }
}
