import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ControlsCreateUpdateComponent } from './controls-create-update/controls-create-update.component';
import { ControlsReadComponent } from './controls-read/controls-read.component';
import { Tag } from 'src/app/modules/common/tags/tags.component';
import { ControlService } from 'src/app/services/controls.service';
import { AlertsService } from 'src/app/services/alerts.service';
import { IPaginatorEvent } from 'src/app/modules/common/table/table.component';
import { ConfirmDialogComponent } from 'src/app/modules/common/confirm-dialog/confirm-dialog.component';
import { AuthService } from 'src/app/services';
import { Roles } from 'src/app/enums/enums';
import { ControlsFileUploadComponent } from './controls-file-upload/controls-file-upload.component';

@Component({
  selector: 'app-controls',
  templateUrl: './controls.component.html',
  styleUrls: ['./controls.component.scss'],
})
export class ControlsComponent implements OnInit {
  searchText = '';
  allControlsList: any[] = [];
  @Input() controlList: any = [];
  controlTypeList: Tag[] = [];
  columns: string[] = [
    'Nombre',
    'Prioridad',
    'Parar actividad',
    'Días recomendados',
    'Puntos de penalización',
  ];
  columnsNames: string[] = [
    'name',
    'priority',
    'stopActivity',
    'recommendedDays',
    'penaltyPoints',
  ];
  total = 0;
  offset = 0;
  limit = 25;
  showLoading = false;
  canCreate = false;

  constructor(
    private controlService: ControlService,
    private dialog: MatDialog,
    private alertsService: AlertsService,
    private auth: AuthService,
  ) {}

  ngOnInit(): void {
    const userRole = this.auth.get().role;
    if (userRole == Roles.SUPER_ADMIN || userRole == Roles.ADMIN) {
      this.canCreate = true;
    }
    this.read();
  }

  handlePageChange(event: IPaginatorEvent) {
    this.offset = event.offset;
    this.limit = event.limit;
    this.read();
  }

  create() {
    const dialogRef = this.dialog.open(ControlsCreateUpdateComponent, {
      autoFocus: false,
      minWidth: '30%',
      maxHeight: '90%',
      panelClass: 'modal-styles',
    });
    dialogRef.afterClosed().subscribe(() => {
      this.read();
    });
  }

  loadFromFile() {
    const dialogRef = this.dialog.open(ControlsFileUploadComponent, {
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe(() => {
      this.read();
    });
  }

  read() {
    const params = {
      offset: this.offset,
      limit: this.limit,
      param: this.searchText,
    };
    this.controlService.read(params).subscribe((res: any) => {
      this.showLoading = false;
      this.total = res.total;
      this.controlList = res.controls;
      this.allControlsList = this.controlList;
    });
  }

  update(id: any) {
    this.controlService.readOne(id).subscribe((res) => {
      const dialogRef = this.dialog.open(ControlsCreateUpdateComponent, {
        data: { control: res, id },
        autoFocus: false,
        minWidth: '30%',
        maxHeight: '90%',
        panelClass: 'modal-styles',
      });
      dialogRef.afterClosed().subscribe((result) => {
        this.read();
      });
    });
  }

  showInfo(id: any) {
    this.controlService.readOne(id).subscribe((res) => {
      const dialogRef = this.dialog.open(ControlsReadComponent, {
        data: { control: res, id },
        autoFocus: false,
      });
      dialogRef.afterClosed().subscribe((result) => {
        this.read();
      });
    });
  }

  delete(id: any) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        message: '¿Confirma la eliminación de este control?',
      },
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res && res.confirmed) {
        this.controlService.delete(id).subscribe(
          () => {
            this.read();
          },
          (error) => {
            if (
              error.status === 400 &&
              error.message === 'checklist asociated'
            ) {
              this.alertsService.error(
                'Este control está asociado a una checklist y no se puede eliminar.',
              );
            } else {
              this.alertsService.error('No se pudo eliminar este control.');
              console.error(error);
            }
          },
        );
      } else {
        this.dialog.closeAll();
      }
    });
  }

  async download() {
    const confirmed = await this.alertsService.confirm(
      '¿Descargar todos los controles?',
    );
    if (confirmed) {
      this.controlService.download().subscribe((data: Blob) => {
        this.downloadFile(data);
      });
    }
  }

  private downloadFile(data: Blob) {
    const blob = new Blob([data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'controles.xlsx';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }

  search(query: string) {
    this.searchText = query;
    this.showLoading = true;
    this.read();
  }
}
