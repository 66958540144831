import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';
import { IQuery } from '../interfaces';
import { DocumentType } from '../interfaces/document-type.interface';

@Injectable({
  providedIn: 'root',
})
export class DocumentTypeService extends BaseService {
  constructor(private http: HttpClient) {
    super();
  }

  create(body: any) {
    return this.http.post(`${this.url}/document-types`, body);
  }
  update(id:number, body: any) {
    return this.http.put(`${this.url}/document-types/${id}`, body);
  }

  findAll(params: IQuery = {}): Observable<{data: DocumentType[], total:number}> {
    return this.http.get<{data: DocumentType[], total:number}>(`${this.url}/document-types`, {
      params,
    });
  }

  findOne(id: number): Observable<DocumentType> {
    return this.http.get<DocumentType>(`${this.url}/document-types/${id}`);
  }

  delete(id:number) {
    return this.http.delete(`${this.url}/document-types/${id}`);
  }
}
