import { Component } from '@angular/core';

@Component({
  selector: 'app-clients-dashboard',
  templateUrl: './clients-dashboard.component.html',
})
export class ClientsDashboardComponent {
  genericList: any[] = [];
  params: { limit: number } = { limit: 25 };
}
