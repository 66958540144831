import { Component, Input } from '@angular/core';
import { Location } from '@angular/common';
import { AuthService } from 'src/app/services';
import { MatDialog } from '@angular/material/dialog';
import { HelpModalComponent } from '../help-modal/help-modal.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  @Input('title') title: string;
  @Input('subtitle') subtitle?: string;
  @Input('backButton') backButton?: boolean = false;
  username: string;

  constructor(private auth: AuthService, private location: Location, private dialog: MatDialog) {
    this.username = this.auth.getName() || '';
  }

  goBack(): void {
    return this.location.back();
  }

  openHelp() {
    const dialogRef = this.dialog.open(HelpModalComponent, {
      data: { },
      autoFocus: false,
    });
  }
}
