import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { delay } from 'rxjs/operators';
import { CheckListCreateUpdateComponent } from './check-list-create-update/check-list-create-update.component';
import { CheckListTypeCreateUpdateComponent } from '../check-lists-type/check-list-type-create-update/check-list-type-create-update.component';
import { Tag } from 'src/app/modules/common/tags/tags.component';
import { CheckListsService } from 'src/app/services/check-lists.service';
import { CheckListsTypeService } from 'src/app/services/check-lists-type.service';
import { DateTransformService } from 'src/app/services/date-transform.service';
import { AlertsService } from 'src/app/services/alerts.service';
import { IPaginatorEvent } from 'src/app/modules/common/table/table.component';
import { ConfirmDialogComponent } from 'src/app/modules/common/confirm-dialog/confirm-dialog.component';
import { Roles } from 'src/app/enums/enums';
import { AuthService } from 'src/app/services';

@Component({
  selector: 'app-check-lists',
  templateUrl: './check-lists.component.html',
  styleUrls: ['./check-lists.component.scss'],
})
export class CheckListComponent implements OnInit {
  searchText = '';
  allCheckListList: any[] = [];
  @Input() checkListList: any = [];
  checkListTypeList: Tag[] = [];
  columns: string[] = [
    'Nombre',
    'Descripción',
    'Tipo de Checklist',
    'Creado por',
    'Modificado por',
  ];
  columnsNames: string[] = [
    'name',
    'description',
    'checklistType',
    'createdBy',
    'updatedBy',
  ];
  total = 0;
  offset = 0;
  limit = 25;
  createBoolean = false;
  showLoading = false;
  canCreate = false;

  constructor(
    private checkListService: CheckListsService,
    private checkListTypeService: CheckListsTypeService,
    private dialog: MatDialog,
    private dateTransformService: DateTransformService,
    private alertsService: AlertsService,
    private auth: AuthService,
  ) {}

  ngOnInit(): void {
    this.checkListTypeService.read().subscribe((res: any) => {
      this.checkListTypeList = this.dateTransformService.transformDates(res);
    });
    const userRole = this.auth.get().role;
    if (userRole == Roles.SUPER_ADMIN || userRole == Roles.ADMIN) {
      this.canCreate = true;
    }
    this.read();
  }

  handlePageChange(event: IPaginatorEvent) {
    this.offset = event.offset;
    this.limit = event.limit;
    this.read();
  }

  read() {
    const params = {
      offset: this.offset,
      limit: this.limit,
      param: this.searchText,
    };
    this.checkListService.read(params).subscribe((res: any) => {
      this.showLoading = false;
      this.total = res.total;
      this.checkListList = this.dateTransformService.transformDates(
        res.checklists,
      );
      this.allCheckListList = this.checkListList;
    });
  }
  create() {
    const dialogRef = this.dialog.open(CheckListCreateUpdateComponent, {
      autoFocus: false,
      width: '80%',
      maxHeight: '90%',
      panelClass: 'modal-styles',
    });
    dialogRef.afterClosed().subscribe(() => {
      this.read();
    });
  }

  createTag(name: string) {
    const checklistType: Tag = { name };
    this.checkListTypeService.create(checklistType).subscribe((res: any) => {
      this.ngOnInit();
    });
  }

  update(id: number) {
    this.checkListService.readOne(id).subscribe((res) => {
      const dialogRef = this.dialog.open(CheckListCreateUpdateComponent, {
        data: { checklist: res, id },
        autoFocus: false,
        width: '80%',
        maxHeight: '90%',
        panelClass: 'modal-styles',
      });
      dialogRef.afterClosed().subscribe(() => {
        this.read();
      });
    });
  }

  delete(id: number) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        message: '¿Confirma la eliminación de esta checklist?',
      },
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res && res.confirmed) {
        this.checkListService
          .delete(id)
          .pipe(delay(800))
          .subscribe(
            () => {
              this.read();
            },
            (error) => {
              if (
                error.status === 400 &&
                error.message === 'service asociated'
              ) {
                this.alertsService.error(
                  'Esta checklist está asociada a un servicio y no se puede eliminar.',
                );
              } else {
                this.alertsService.error('No se pudo eliminar esta checklist.');
                console.error(error);
              }
            },
          );
      } else {
        this.dialog.closeAll();
      }
    });
  }

  deleteTag(id: number) {
    this.checkListTypeService.delete(id).subscribe(() => {
      this.ngOnInit();
    });
  }

  updateTag(id: number) {
    this.checkListTypeService.readOne(id).subscribe((res) => {
      const dialogRef = this.dialog.open(CheckListTypeCreateUpdateComponent, {
        data: { checkListType: res, id },
        autoFocus: false,
      });
      dialogRef.afterClosed().subscribe((result) => {
        this.read();
      });
    });
  }

  search(query: string) {
    this.searchText = query;
    this.showLoading = true;
    this.read();
  }
}
