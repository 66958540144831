import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GoogleMapsModule } from '@angular/google-maps';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { MaterialModule } from 'src/app/material/material.module';
import { FormsModule } from '@angular/forms';
import { GoogleMapsComponent } from './google-maps-component/google-maps.component';

@NgModule({
  declarations: [
    GoogleMapsComponent,
  ],
  imports: [
    MaterialModule,
    CommonModule,
    GoogleMapsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    FormsModule,
  ],
  exports: [GoogleMapsComponent],
})
export class GMapsModule {}
