export const AUTH: string = 'auth';
export const PAGES: string = 'pages';
export const LOGIN: string = 'login';
export const DASHBOARD: string = 'dashboard';
export const AUDITS: string = 'audits';
export const SERVICES: string = 'services';
export const AUDITABLE_OBJECT: string = 'auditable-object';
export const CLIENTS: string = 'auditor';
export const USERS: string = 'users';
export const COMPANIES: string = 'companies';
export const ADMIN: string = 'admin';
export const DOCUMENTS: string = 'documents';
export const RESOURCES: string = 'resources';
