<div class="container" *ngIf="isSuperAdmin">
  <span class="text"
    >Cliente: {{ companyName }}
    <img
      src="../../../../assets/sidebar-icons/change-company.svg"
      class="text-icon"
      (click)="changeCompany()"
      alt=""
    />
  </span>
</div>
