import { CdkDropList, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  Component,
  EventEmitter,
  Inject,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Checklist } from 'src/app/interfaces/checklist.interface';
import { Control } from 'src/app/interfaces/control.interface';
import { AlertsService } from 'src/app/services/alerts.service';
import { CheckListsTypeService } from 'src/app/services/check-lists-type.service';
import { CheckListsService } from 'src/app/services/check-lists.service';
import { CheckListComponent } from '../check-lists.component';

@Component({
  selector: 'app-check-list-create-update',
  templateUrl: './check-list-create-update.component.html',
  styleUrls: ['./check-list-create-update.component.scss'],
})
export class CheckListCreateUpdateComponent implements OnInit {
  checkListForm = new FormGroup({
    name: new FormControl('', Validators.required),
    description: new FormControl(''),
    checklistTypeId: new FormControl(-1, Validators.required),
  });

  checkListTypesList: any = [];
  deletedControls: number[] = [];

  checklist: Checklist;

  selectedMap: Record<number, Control> = {};
  dragableList: Control[] = [];

  @ViewChild(CdkDropList) dropList: CdkDropList;

  @Output('onCancel') onCancel = new EventEmitter();

  constructor(
    private checkListTypeService: CheckListsTypeService,
    private checkListService: CheckListsService,
    public dialogRef: MatDialogRef<CheckListComponent>,
    private alertsService: AlertsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit() {
    this.read();
  }

  read() {
    this.checkListTypeService.read().subscribe((res: any) => {
      this.checkListTypesList = res;
    });
    if (this.data && this.data.checklist) {
      this.checkListService
        .readOne(this.data.checklist.id)
        .subscribe((checklist: any) => {
          this.checklist = checklist;
          this.checkListForm.setValue({
            name: this.checklist.name,
            description: this.checklist.description,
            checklistTypeId: this.checklist.checklistTypeId,
          });
          this.dragableList = this.checklist.controls;
          this.dragableList.forEach((control) => {
            this.selectedMap[control.id] = control;
          });
        });
    }
  }

  submit(): void {
    if (this.data) {
      this.update();
    } else {
      this.create();
    }
  }

  create() {
    this.checkListService
      .create({
        ...this.checkListForm.value,
        controls: this.dropList
          .getSortedItems()
          .map(({ data }, index) => ({ id: data.id, order: index })),
      })
      .subscribe({
        next: () => {
          this.dialogRef.close();
          this.alertsService.success('Checklist creada con éxito');
        },
        error: ({ message }) => {
          console.log(message);
          this.alertsService.error('Error al crear checklist');
        },
      });
  }

  update() {
    this.checkListService
      .update(this.data.id, {
        ...this.checkListForm.value,
        controls: this.dropList
          .getSortedItems()
          .map(({ data }, index) => ({ id: data.id, order: index })),
      })
      .subscribe({
        next: () => {
          this.dialogRef.close();
          this.alertsService.success('Checklist actualizada con éxito');
        },
        error: ({ message }) => {
          console.log(message);
          this.alertsService.error('Error al actualizar checklist');
        },
      });
  }

  cancel() {
    this.dialogRef.close();
  }

  drop(event: any) {
    moveItemInArray(this.dragableList, event.previousIndex, event.currentIndex);
  }

  removeControl(control: Control) {
    const index = this.dragableList.findIndex((x) => x.id === control.id);
    this.dragableList.splice(index, 1);
    const dragable = this.dropList
      .getSortedItems()
      .find(({ data }) => data.id === control.id);
    if (dragable) {
      this.dropList.removeItem(dragable);
    }
    delete this.selectedMap[control.id];
  }

  selectControl(control: Control) {
    if (this.selectedMap[control.id]) {
      delete this.selectedMap[control.id];
      this.removeControl(control);
    } else {
      this.selectedMap[control.id] = control;
      this.dragableList.push(control);
    }
  }
}
