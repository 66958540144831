<app-admin-toast></app-admin-toast>
<div class="padding-component">
  <app-header [title]="'Panel de control'"></app-header>

  <div class="first-container">
    <div>
      <span class="resume-title">Resumen quincenal</span>
      <div class="date-container">
        <mat-icon class="date-icon">calendar_month</mat-icon
        ><span class="date-title"
          >{{ startDateFormatted }} - {{ endDateFormatted }}</span
        >
      </div>
    </div>
    <div>
      <button (click)="createAudit()" class="create-button">
        Crear auditoria<mat-icon fontSet="material-icons-outlined"
          >add_box</mat-icon
        >
      </button>
    </div>
  </div>

  <div class="resume-cards">
    <mat-card class="main-card">
      <span class="resume-card-title">Auditorias completadas</span>
      <span class="resume-card-subtitle" *ngIf="completedAuditsCount == 0"
        >No hay auditorias completadas</span
      >
      <span class="resume-card-count" *ngIf="completedAuditsCount > 0">{{
        completedAuditsCount
      }}</span>
      <div class="graphic-header" *ngIf="completedAuditsCount > 0">
        <span class="resume-card-subtitle">{{
          getCompletionWidth(completedAuditsCount, totalAudits)
        }}</span>
        <span class="resume-card-subtitle"
          >{{ completedAuditsCount }} completadas /
          {{ totalAudits }} totales</span
        >
      </div>
      <div
        class="completion-bar"
        *ngIf="completedAuditsCount > 0"
        [ngClass]="{ 'mb-4': beforeExpiredAuditsCount > 0 && isSuperAdmin }"
      >
        <div
          class="completion-bar-inner"
          [style.width]="getCompletionWidth(completedAuditsCount, totalAudits)"
        ></div>
      </div>
      <span
        [ngClass]="completedAuditsCount > 0 ? 'expired' : 'expired-margin'"
        [routerLink]="showExpiredAudits()"
        *ngIf="isSuperAdmin && beforeExpiredAuditsCount > 0"
        ><mat-icon>event_busy</mat-icon
        >{{ beforeExpiredAuditsCount }} auditorias vencidas anteriormente</span
      >
    </mat-card>

    <mat-card class="main-card">
      <span class="resume-card-title">Hallazgos resueltos</span>
      <span class="resume-card-subtitle" *ngIf="resolvedFindingsCount == 0"
        >No hay hallazgos resueltos</span
      >
      <span class="resume-card-count" *ngIf="resolvedFindingsCount > 0">{{
        resolvedFindingsCount
      }}</span>
      <div class="graphic-header" *ngIf="resolvedFindingsCount > 0">
        <span class="resume-card-subtitle">{{
          getCompletionWidth(resolvedFindingsCount, totalFindings)
        }}</span>
        <span class="resume-card-subtitle"
          >{{ resolvedFindingsCount }} resueltos /
          {{ totalFindings }} totales</span
        >
      </div>
      <div class="completion-bar" *ngIf="resolvedFindingsCount > 0">
        <div
          class="completion-bar-inner"
          [style.width]="
            getCompletionWidth(resolvedFindingsCount, totalFindings)
          "
        ></div>
      </div>
      <!-- <span class="expired" (click)="expiredFindings()"><mat-icon>event_busy</mat-icon>3 hallazgos vencidos anteriormente</span> -->
    </mat-card>

    <mat-card class="main-card">
      <span class="resume-card-title">Acciones correctivas a evaluar</span>
      <span
        class="resume-card-subtitle"
        *ngIf="pendingCorrectiveActionsCount == 0"
        >No hay acciones para evaluar</span
      >
      <span
        class="resume-card-count"
        *ngIf="pendingCorrectiveActionsCount > 0"
        >{{ pendingCorrectiveActionsCount }}</span
      >
      <div
        class="graphic-header"
        [ngClass]="
          pendingCorrectiveActionsCount > 0
            ? 'graphic-header'
            : 'graphic-header-second'
        "
      >
        <span class="resume-card-subtitle">{{
          getCompletionWidth(
            evaluatedCorectiveActionsCount,
            totalCorrectiveAction
          )
        }}</span>
        <span class="resume-card-subtitle"
          >{{ evaluatedCorectiveActionsCount }} evaluadas /
          {{ totalCorrectiveAction }} totales</span
        >
      </div>
      <div class="completion-bar">
        <div
          class="completion-bar-inner"
          [style.width]="
            getCompletionWidth(
              evaluatedCorectiveActionsCount,
              totalCorrectiveAction
            )
          "
        ></div>
      </div>
    </mat-card>
  </div>

  <div class="card-section">
    <mat-card class="main-card">
      <div class="cards-header">
        <span class="card-title">Auditorías</span>
        <a class="see-more" [routerLink]="seeMoreAudits()">Ver más</a>
      </div>
      <span
        class="expired"
        [routerLink]="showExpiredAudits()"
        *ngIf="!isSuperAdmin"
        ><mat-icon>event_busy</mat-icon
        >{{ beforeExpiredAuditsCount }} auditorias vencidas anteriormente</span
      >
      <div
        *ngIf="!isClient"
        [ngClass]="{ 'mt-4': beforeExpiredAuditsCount > 0 && !isSuperAdmin }"
      >
        <span class="card-subtitle mb-3"
          >Vencidas ({{ expiredAuditsCount }})</span
        >
        <div class="list-cards mb-3" *ngIf="expiredAuditsCount > 0">
          <div *ngFor="let audit of expiredAudits">
            <app-audits-dashboard
              [audit]="audit"
              [isExpired]="true"
              [isClient]="isClient"
              [uniqueId]="'donutChart_' + audit.id"
            ></app-audits-dashboard>
          </div>
        </div>
        <div class="list-cards mb-3" *ngIf="expiredAuditsCount == 0">
          <mat-card class="list-card">
            <span class="resume-card-subtitle"
              >No hay auditorias vencidas en los últimos 7 días</span
            >
          </mat-card>
        </div>
      </div>

      <div *ngIf="!isClient">
        <span class="card-subtitle"
          >Por vencer ({{ toOvercomeAuditsCount }})</span
        >
        <div class="list-cards" *ngIf="toOvercomeAuditsCount > 0">
          <div *ngFor="let audit of toOvercomeAudits">
            <app-audits-dashboard
              [audit]="audit"
              [isClient]="isClient"
              [uniqueId]="'donutChart_' + audit.id"
            ></app-audits-dashboard>
          </div>
        </div>
        <div class="list-cards mb-3" *ngIf="toOvercomeAuditsCount == 0">
          <mat-card class="list-card">
            <span class="resume-card-subtitle"
              >No hay auditorias por vencer en los últimos 7 días</span
            >
          </mat-card>
        </div>
      </div>

      <div *ngIf="!isClient">
        <span class="card-subtitle">Por realizar ({{ toDoAuditsCount }})</span>
        <div class="list-cards" *ngIf="toDoAuditsCount > 0">
          <div *ngFor="let audit of toDoAudits">
            <app-audits-dashboard
              [audit]="audit"
              [isClient]="isClient"
              [uniqueId]="'donutChart_' + audit.id"
            ></app-audits-dashboard>
          </div>
        </div>
        <div class="list-cards" *ngIf="toDoAuditsCount == 0">
          <mat-card class="list-card">
            <span class="resume-card-subtitle"
              >No hay auditorias por realizar en los últimos 7 días</span
            >
          </mat-card>
        </div>
      </div>

      <div *ngIf="isClient">
        <span class="card-subtitle"
          >En proceso ({{ inProcessAuditsCount }})</span
        >
        <div class="list-cards" *ngIf="inProcessAuditsCount > 0">
          <div *ngFor="let audit of inProcessAudits">
            <app-audits-dashboard
              [audit]="audit"
              [isClient]="isClient"
              [uniqueId]="'donutChart_' + audit.id"
            ></app-audits-dashboard>
          </div>
        </div>
        <div class="list-cards" *ngIf="inProcessAuditsCount == 0">
          <mat-card class="list-card">
            <span class="resume-card-subtitle"
              >No hay auditorias en proceso en los últimos 7 días</span
            >
          </mat-card>
        </div>
      </div>

      <div *ngIf="isClient">
        <span class="card-subtitle">Futuras ({{ futureAuditsCount }})</span>
        <div class="list-cards" *ngIf="futureAuditsCount > 0">
          <div *ngFor="let audit of futureAudits">
            <app-audits-dashboard
              [audit]="audit"
              [isClient]="isClient"
              [uniqueId]="'donutChart_' + audit.id"
            ></app-audits-dashboard>
          </div>
        </div>
        <div class="list-cards" *ngIf="futureAuditsCount == 0">
          <mat-card class="list-card">
            <span class="resume-card-subtitle"
              >No hay auditorias futuras en los últimos 7 días</span
            >
          </mat-card>
        </div>
      </div>
    </mat-card>
  </div>

  <div class="card-section">
    <mat-card class="main-card">
      <div class="cards-header">
        <span class="card-title">Hallazgos</span>
        <a class="see-more" (click)="seeMoreFindings()">Ver más</a>
      </div>
      <div>
        <span class="card-subtitle">Vencidos ({{ expiredFindingsCount }})</span>
        <div class="list-cards mb-3" *ngIf="expiredFindingsCount > 0">
          <div *ngFor="let finding of expiredFindings">
            <app-findings-dashboard
              [finding]="finding"
              [isExpired]="true"
              [isClient]="isClient"
            ></app-findings-dashboard>
          </div>
        </div>
        <div class="list-cards mb-3" *ngIf="expiredFindingsCount == 0">
          <mat-card class="list-card">
            <span class="resume-card-subtitle"
              >No hay hallazgos vencidos en los últimos 7 días</span
            >
          </mat-card>
        </div>
      </div>

      <div>
        <span class="card-subtitle">
          {{ isClient ? 'Por resolver' : 'Por vencer' }} ({{
            toOvercomeFindingsCount
          }})
        </span>
        <div class="list-cards mb-3" *ngIf="toOvercomeFindingsCount > 0">
          <div *ngFor="let finding of toOvercomeFindings">
            <app-findings-dashboard
              [finding]="finding"
              [isClient]="isClient"
            ></app-findings-dashboard>
          </div>
        </div>
        <div class="list-cards mb-3" *ngIf="toOvercomeFindingsCount == 0">
          <mat-card class="list-card">
            <span class="resume-card-subtitle"
              >No hay hallazgos por resolver en los últimos 7 días</span
            >
          </mat-card>
        </div>
      </div>
    </mat-card>
  </div>

  <div class="card-section">
    <mat-card class="main-card">
      <div class="cards-header">
        <span class="card-title">Acciones correctivas</span>
        <a class="see-more" (click)="seeMoreCorrectiveActions()">Ver más</a>
      </div>
      <div>
        <span class="card-subtitle">
          {{ isClient ? 'Pendientes de evaluación' : 'Por evaluar' }} ({{
            pendingCorrectiveActionsCount
          }})
        </span>
        <div class="list-cards" *ngIf="pendingCorrectiveActionsCount > 0">
          <div *ngFor="let correctiveAction of pendingCorrectiveActions">
            <app-corrective-actions-dashboard
              [correctiveAction]="correctiveAction"
              [isClient]="isClient"
            ></app-corrective-actions-dashboard>
          </div>
        </div>
        <div class="list-cards" *ngIf="pendingCorrectiveActionsCount == 0">
          <mat-card class="list-card">
            <span class="resume-card-subtitle"
              >No hay acciones correctivas pendientes en los últimos 7
              días</span
            >
          </mat-card>
        </div>
      </div>

      <div *ngIf="isClient">
        <span class="card-subtitle"
          >Rechazadas ({{ rejectedCorrectiveActionsCount }})</span
        >
        <div class="list-cards" *ngIf="rejectedCorrectiveActionsCount > 0">
          <div *ngFor="let correctiveAction of rejectedCorrectiveActions">
            <app-corrective-actions-dashboard
              [correctiveAction]="correctiveAction"
              [isClient]="isClient"
              [rejected]="true"
            ></app-corrective-actions-dashboard>
          </div>
        </div>
        <div class="list-cards" *ngIf="rejectedCorrectiveActionsCount == 0">
          <mat-card class="list-card">
            <span class="resume-card-subtitle"
              >No hay acciones correctivas rechazadas en los últimos 7
              días</span
            >
          </mat-card>
        </div>
      </div>
    </mat-card>
  </div>
</div>
