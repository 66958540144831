<div class="d-flex justify-content-between mt-4">
    <div>
        <searcher [label]="'Buscar Vehiculo'" (onChange)="search($event)" [showLoading]="showLoading"></searcher>
    </div>
    <div>
        <button (click)="openModal()" class="create-button">
            Crear Vehiculo
        </button>
    </div>
</div>
<app-table [data]="vehicles" [displayedColumns]="columns" [columnsNames]="columnsNames" [total]="total" [offset]="offset" [limit]="limit" (update)="update($event)"
    (delete)="delete($event)" (onPageChange)="handlePageChange($event)"></app-table>
