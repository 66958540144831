<div class="header-container">
  <h1 class="mb-4">
    {{ title }}
  </h1>
  <div class="user-profile">
    <button *ngIf="backButton" (click)="goBack()" class="close-button mr-5">
      Volver
    </button>
    <!-- <div class="help-icon">
      <mat-icon (click)="openHelp()">help_outline</mat-icon>
    </div> -->
    <div class="notification-icon">
      <mat-icon>notifications_none</mat-icon>
    </div>
    <div class="avatar-icon">
      {{ username }}
    </div>
  </div>
</div>
