import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BaseService } from './base.service';
import { IQuery } from '../interfaces';
import { Observable } from 'rxjs';
import { ChecklistsResponse } from '../interfaces/checklists-response.interface';

@Injectable({
  providedIn: 'root',
})
export class CheckListsService extends BaseService {
  constructor(private http: HttpClient, private router: Router) {
    super();
  }
  create(data: any) {
    return this.http.post(`${this.url}/checklists`, data);
  }

  read(params: IQuery = {}): Observable<ChecklistsResponse> {
    return this.http.get<ChecklistsResponse>(`${this.url}/checklists`, {
      params,
    });
  }

  readOne(id: number) {
    return this.http.get(`${this.url}/checklists/${id}`);
  }

  readOneByName(name: string) {
    return this.http.get(`${this.url}/checklists/name/${name}`);
  }

  update(id: number, data: any) {
    return this.http.put(`${this.url}/checklists/${id}`, data);
  }

  delete(id: number) {
    return this.http.delete(`${this.url}/checklists/${id}`);
  }
}
