import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from '../services';
import { User } from '../interfaces';
import { AUTH } from '../utils/urls';

@Injectable()
export default class SelectedCompanyGuard {
  constructor(private router: Router, private service: AuthService) {}

  canActivateChild(route: ActivatedRouteSnapshot) {
    const user: User = this.service.get();
    const { selectedCompany } = user;
    if (selectedCompany) {
      if (!selectedCompany.id || !selectedCompany.primaryColor) {
        this.router.navigate([AUTH]);
        return false;
      }
      return true;
    }
    this.router.navigate([AUTH]);
    return false;
  }
}
