<audit-modal
  title="{{
    isCreate ? 'Crear Acción Correctiva' : 'Actualizar Acción Correctiva'
  }}"
>
  <form [formGroup]="correctiveActionForm" class="main-form">
    <div>
      <div class="static-div">
        <p class="bold bg margin-right">Auditoría:</p>
        <p>{{ auditName }}</p>
      </div>
      <div class="static-div">
        <p class="bold bg margin-right">Hallazgo:</p>
        <p>{{ finding }}</p>
      </div>
    </div>
    <a-input label="Descripción:">
      <mat-form-field appearance="outline">
        <textarea
          matInput
          formControlName="description"
          placeholder="Ingrese descripción de acción correctiva"
        >
        </textarea>
      </mat-form-field>
    </a-input>
    <a-input label="Archivo">
      <input type="file" (change)="onSelectFile($event)" #file hidden />
      <div class="actions" class="input-image">
        <button
          class="common-button"
          mat-raised-button
          type="button"
          (click)="file.click()"
        >
          <mat-icon>attach_file</mat-icon>Seleccione archivo
        </button>
        <span class="file-name" *ngIf="selectedFileName">{{
          selectedFileName
        }}</span>
      </div>
    </a-input>
    <div class="button-container">
      <button class="close-button" (click)="cancel()">Cancelar</button>
      <button
        class="create-button"
        *ngIf="isCreate"
        (click)="submit()"
        type="submit"
      >
        Crear
      </button>
      <button
        class="create-button"
        *ngIf="!isCreate"
        (click)="submit()"
        type="submit"
      >
        Actualizar
      </button>
    </div>
  </form>
</audit-modal>
