<mat-form-field appearance="outline">
  <div class="searcher-input">
    <input matInput [formControl]="searcher" placeholder="Buscar control por nombre" autocomplete="off">
    <mat-icon class="clean-icon" (click)="cleanSearch()" matTooltip="Limpiar búsqueda" [hidden]="searcher.value === ''">close</mat-icon>
  </div>
  <mat-hint *ngIf="loading">Cargando...</mat-hint>
  <mat-hint *ngIf="!loading && !searcher.valid">Escriba 3 caracteres como mínimo</mat-hint>
</mat-form-field>

<div class="list">
  <div *ngFor="let control of controls" (click)="toggleControl(control)" [ngClass]="{'option-selected': selected[control.id], 'option': !selected[control.id]}">
    {{ control.name }}
    <mat-icon class="icon" *ngIf="selected[control.id]" style="display: table;">done</mat-icon>
  </div>
</div>