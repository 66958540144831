import {
  Component, EventEmitter, Inject, OnInit, Output,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuditsService } from 'src/app/services/audits.service';
import { CorrectiveActionsService } from 'src/app/services/corrective-actions.service';
import { FindingsService } from 'src/app/services/findigs.service';
import { AlertsService } from 'src/app/services/alerts.service';
import { FindingsComponent } from '../../findings/findings.component';

@Component({
  selector: 'app-corrective-actions-create-update',
  templateUrl: './corrective-actions-create-update.component.html',
  styleUrls: ['./corrective-actions-create-update.component.scss'],
})
export class CorrectiveActionsCreateUpdateComponent implements OnInit {
  correctiveActionForm = new FormGroup({
    description: new FormControl(''),
  });

  @Output('onCancel') onCancel = new EventEmitter();

  finding: string;
  auditName: string;
  isCreate: boolean = true;
  isSubmitting: boolean = false;
  isAuditDetails: boolean;
  audit: any;
  auditId: number;
  findingId: number;
  auditsList: any = [];
  findingsList: any = [];
  findingDescription: string;
  selectedFileName: string | null = null;
  file: File | null;

  constructor(
    public dialogRef: MatDialogRef<FindingsComponent>,
    private correctiveActionsService: CorrectiveActionsService,
    private auditsService: AuditsService,
    private findingsService: FindingsService,
    private alertsService: AlertsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
    if (this.data?.finding) {
      this.auditId = this.data.finding.auditControl.auditChecklist.auditId;
      this.findingId = this.data.finding.id;
      this.readAudit(this.auditId);
      this.readFindings();
      this.finding = this.data.finding.description;
      this.auditName = this.data.finding.auditControl.auditChecklist.audit.name;
      this.correctiveActionForm.setValue({
        description: '',
      });
    } else if (this.data) {
      this.isAuditDetails = this.data.isAuditDetails;
      this.auditId = this.data.auditId;
      this.isCreate = false;
      this.finding = this.data.res.finding.description;
      this.auditName = this.data.res.name;
      this.correctiveActionForm.setValue({
        description: this.data.res.description,
      });
      this.readFindings();
    }
  }

  readAudit(id: number) {
    this.auditsService.readOne(id).subscribe((res) => {
      this.audit = res;
    });
  }

  readFindings() {
    this.findingsService.readByAudit(this.auditId).subscribe((res) => {
      this.findingsList = res;
    });
  }

  onAuditChange() {
    this.readFindings();
  }

  cancel() {
    this.dialogRef.close();
  }

  submit() {
    if (this.isSubmitting) {
      return;
    }
    this.isSubmitting = true;
    if (this.isCreate) {
      this.create();
    }
  }

  create() {
    this.correctiveActionsService
      .create({
        ...this.correctiveActionForm.value,
        auditId: this.auditId,
        findingId: this.findingId,
        file: this.file,
      })
      .subscribe({
        next: () => {
          this.dialogRef.close();
          this.alertsService.success('Acción correctiva creada con éxito');
          this.isSubmitting = false;
        },
        error: (error) => {
          console.log(error);
          this.alertsService.error('Error al crear acción correctiva');
          this.isSubmitting = false;
        },
      });
  }

  onSelectFile(event: any): void {
    const input = event.target as HTMLInputElement;
    const files = input.files;

    if (files && files.length > 0) {
      this.file = files[0];
      this.selectedFileName = this.file.name;
    } else {
      this.file = null;
      this.selectedFileName = null;
    }
  }
}
