<audit-modal
  title="{{
    data?.document
      ? 'Editar documentación'
      : data.isClient
        ? 'Crear documentación de empleado'
        : data.isVehicle
          ? 'Crear documentación de vehículo'
          : 'Crear documentación de empresa'
  }}"
>
  <!-- <div mat-dialog-content class="dialog-container"> -->
  <form [formGroup]="documentForm" class="main-form">
    <a-input label="Nombre">
      <mat-form-field appearance="outline">
        <input matInput formControlName="name" placeholder="Nombre" />
      </mat-form-field>
    </a-input>
    <a-input label="Documentación">
      <mat-form-field appearance="outline">
        <mat-select
          formControlName="documentTypeId"
          placeholder="Seleccione documentación"
        >
          <mat-option
            *ngFor="let documentType of docTypes"
            [value]="documentType.id"
          >
            {{ documentType.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </a-input>
    <a-input
      *ngIf="data.isClient || data.isVehicle"
      [label]="data.isClient ? 'Empleado' : 'Vehículo'"
    >
      <mat-form-field appearance="outline">
        <mat-select
          formControlName="entityId"
          placeholder="Seleccione empleado"
        >
          <mat-option *ngFor="let entity of entities" [value]="entity.id">
            {{ data.isClient ? entity.fullName : entity.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </a-input>
    <a-input label="Fecha de alta">
      <mat-form-field appearance="outline">
        <input
          matInput
          [matDatepicker]="datePickerIssued"
          formControlName="issued"
          placeholder="Fecha de alta"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="datePickerIssued"
        ></mat-datepicker-toggle>
        <mat-datepicker #datePickerIssued></mat-datepicker>
      </mat-form-field>
    </a-input>
    <a-input label="Fecha de expiración">
      <mat-form-field appearance="outline">
        <input
          matInput
          [matDatepicker]="datePicker"
          formControlName="expiration"
          placeholder="Fecha de expiración"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="datePicker"
        ></mat-datepicker-toggle>
        <mat-datepicker #datePicker></mat-datepicker>
      </mat-form-field>
    </a-input>

    <input
      type="file"
      [accept]="validFileFormat"
      (change)="onSelectFile($event.target)"
      #file
      hidden
    />
    <div class="actions">
      <button
        class="common-button"
        mat-raised-button
        type="button"
        (click)="file.click()"
      >
        <mat-icon>attach_file</mat-icon>Seleccionar imagen
      </button>
      <button
        *ngIf="image || (data.document && data.document?.imageUrl && preview)"
        mat-mini-fab
        color="basic"
        (click)="onRemoveFile()"
      >
        <mat-icon>clear</mat-icon>
      </button>
    </div>
    <div class="image-div" *ngIf="preview">
      <img class="image-preview" [src]="preview" />
    </div>
    <div class="image-div" *ngIf="!preview && documentForm.value.imageUrl">
      <img class="image-preview" [src]="documentForm.value.imageUrl" />
    </div>
  </form>
  <!-- </div> -->
  <div class="button-container">
    <button class="close-button" (click)="cancel()">Cancelar</button>
    <button
      [ngClass]="!documentForm.valid ? 'disabled-button' : 'create-button'"
      [disabled]="!documentForm.valid"
      (click)="submit()"
    >
      {{ data?.document ? 'Editar' : 'Crear' }}
    </button>
  </div>
</audit-modal>
