<audit-modal
  title="{{
    isCreate
      ? 'Crear Auditoría'
      : !isCopy
        ? 'Actualizar Auditoría'
        : 'Copia de Auditoría'
  }}"
>
  <mat-horizontal-stepper
    [linear]="false"
    #stepper="matHorizontalStepper"
    class="audit-container"
  >
    <mat-step [stepControl]="firstFormGroup">
      <form [formGroup]="firstFormGroup">
        <a-input label="Nombre">
          <mat-form-field appearance="outline">
            <input formControlName="name" matInput placeholder="Nombre" />
          </mat-form-field>
        </a-input>
        <div class="date-container">
          <div>
            <a-input label="Fecha">
              <mat-form-field class="inside-input" appearance="outline">
                <input
                  matInput
                  [matDatepicker]="datePicker"
                  formControlName="date"
                  placeholder="Fecha"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="datePicker"
                ></mat-datepicker-toggle>
                <mat-datepicker #datePicker></mat-datepicker>
              </mat-form-field>
            </a-input>
          </div>
          <div>
            <a-input label="Fecha vencimiento">
              <mat-form-field class="inside-input" appearance="outline">
                <input
                  matInput
                  [matDatepicker]="expirationdatePicker"
                  formControlName="expirationDate"
                  placeholder="Fecha vencimiento"
                />
                <mat-error
                  *ngIf="firstFormGroup.hasError('maxDate', 'expirationDate')"
                >
                  La fecha de expiración debe ser mayor que la fecha.
                </mat-error>
                <mat-datepicker-toggle
                  matSuffix
                  [for]="expirationdatePicker"
                ></mat-datepicker-toggle>
                <mat-datepicker #expirationdatePicker></mat-datepicker>
              </mat-form-field>
            </a-input>
          </div>
        </div>
        <div class="button-container">
          <button class="close-button" (click)="close()">Cancelar</button>
          <button matStepperNext class="create-button">Siguiente</button>
        </div>
      </form>
    </mat-step>

    <mat-step [stepControl]="secondFormGroup">
      <form [formGroup]="secondFormGroup">
        <a-input label="Auditado">
          <mat-form-field appearance="outline">
            <mat-select
              formControlName="clientId"
              (selectionChange)="onClientChange()"
              placeholder="Selecciona auditado"
            >
              <mat-option
                *ngFor="let option of auditeesList"
                [value]="option.id"
              >
                {{ option.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </a-input>

        <a-input label="Objeto Auditado">
          <mat-form-field appearance="outline">
            <mat-select
              formControlName="auditableObjectId"
              placeholder="Selecciona objeto auditable"
            >
              <mat-option
                *ngFor="let option of auditableObjectList"
                [value]="option.id"
              >
                {{ option.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </a-input>

        <a-input label="Servicios">
          <mat-form-field appearance="outline">
            <mat-select
              multiple
              formControlName="servicesIds"
              placeholder="Selecciona servicios"
            >
              <mat-option
                *ngFor="let option of serviceList"
                [value]="option.id"
                [disabled]="isCompleted"
              >
                {{ option.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </a-input>

        <a-input label="Auditor">
          <mat-form-field appearance="outline">
            <mat-select
              formControlName="auditorId"
              placeholder="Selecciona auditor"
              (selectionChange)="onAuditorChange($event)"
            >
              <mat-option
                *ngFor="let option of auditorsList"
                [value]="option.id"
              >
                {{ option.firstName }}
                {{ option.lastName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </a-input>

        <a-input label="Auditores adicionales">
          <mat-form-field appearance="outline">
            <mat-select
              multiple
              formControlName="extraAuditorsIds"
              placeholder="Seleccionar auditores adicionales"
            >
              <mat-option
                *ngFor="let option of extraAuditorsList"
                [value]="option.id"
              >
                {{ option.firstName }}
                {{ option.lastName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </a-input>
        <div class="button-container">
          <button matStepperPrevious class="close-button">Atrás</button>
          <button matStepperNext class="create-button">Siguiente</button>
        </div>
      </form>
    </mat-step>

    <mat-step [stepControl]="thirdFormGroup">
      <form [formGroup]="thirdFormGroup" class="third-form">
        <div>
          <label>Enviar informe</label>
          <mat-radio-group
            formControlName="report"
            aria-label="Select an option"
            class="radio-group"
          >
            <mat-radio-button
              color="primary"
              [value]="true"
              style="margin-right: 50px"
              >Si</mat-radio-button
            >
            <mat-radio-button color="primary" [value]="false"
              >No</mat-radio-button
            >
          </mat-radio-group>
        </div>

        <a-input label="Administradores a notificar">
          <mat-form-field appearance="outline">
            <mat-select
              multiple
              formControlName="usersToNotificateIds"
              placeholder="Selecciona administradores a notificar"
            >
              <mat-option *ngFor="let option of adminsList" [value]="option.id">
                {{ option.firstName }} {{ option.lastName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </a-input>

        <a-input label="Locaciones">
          <mat-form-field appearance="outline">
            <mat-select
              #location
              (ngModelChange)="setLocation($event)"
              formControlName="locationId"
              placeholder="Selecciona locaciones"
            >
              <mat-option
                *ngFor="let option of locationsList"
                [value]="option.id"
              >
                {{ option.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </a-input>

        <div class="maps">
          <audits-google-maps
            #mapsComponent
            [lat]="locationInitialize.latitude"
            [lng]="locationInitialize.longitude"
            [address]="locationInitialize.address"
            [labelOn]="false"
          >
          </audits-google-maps>
        </div>
      </form>
      <div class="button-container">
        <button matStepperPrevious class="close-button">Atrás</button>
        <button matStepperNext class="create-button">Siguiente</button>
      </div>
    </mat-step>

    <mat-step [stepControl]="fourthFormGroup">
      <form [formGroup]="fourthFormGroup" class="third-form">
        <a-input label="Observaciones">
          <mat-form-field appearance="outline">
            <textarea
              formControlName="observations"
              matInput
              placeholder="Observaciones"
            ></textarea>
          </mat-form-field>
        </a-input>

        <div>
          <label>Iterar</label>
          <mat-radio-group
            formControlName="iterate"
            aria-label="Select an option"
            class="radio-group"
            (change)="onIterateSelected($event.value)"
          >
            <mat-radio-button
              color="primary"
              [value]="true"
              style="margin-right: 50px"
              >Si</mat-radio-button
            >
            <mat-radio-button color="primary" [value]="false"
              >No</mat-radio-button
            >
          </mat-radio-group>
        </div>
        <div *ngIf="auditItereation">
          <div>
            <label>Repetir cada</label>
            <mat-form-field
              appearance="outline"
              style="width: 70px; margin-left: 20px"
            >
              <input
                type="number"
                matInput
                formControlName="repeatCount"
                placeholder="1*"
              />
            </mat-form-field>
            <mat-form-field
              appearance="outline"
              style="width: 140px; margin-left: 20px"
            >
              <mat-select
                #repeatTypeRef
                (selectionChange)="repeatTypeChange($event.value)"
                formControlName="repeatType"
                placeholder="Frecuencia"
              >
                <mat-option
                  *ngFor="let option of repeatTypes"
                  [value]="option.id"
                >
                  {{ option.type }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div
            *ngIf="weeklyIteration"
            style="display: flex; align-items: baseline; margin-bottom: 20px"
          >
            <label>Repetir días</label>
            <div class="day-buttons-container">
              <button
                (click)="selectDay('L')"
                [ngClass]="isMonday ? 'day-button-selected' : 'day-button'"
              >
                L
              </button>
              <button
                (click)="selectDay('Ma')"
                [ngClass]="isTuesday ? 'day-button-selected' : 'day-button'"
              >
                M
              </button>
              <button
                (click)="selectDay('Mi')"
                [ngClass]="isWednesday ? 'day-button-selected' : 'day-button'"
              >
                M
              </button>
              <button
                (click)="selectDay('J')"
                [ngClass]="isThursday ? 'day-button-selected' : 'day-button'"
              >
                J
              </button>
              <button
                (click)="selectDay('V')"
                [ngClass]="isFriday ? 'day-button-selected' : 'day-button'"
              >
                V
              </button>
              <button
                (click)="selectDay('S')"
                [ngClass]="isSaturday ? 'day-button-selected' : 'day-button'"
              >
                S
              </button>
              <button
                (click)="selectDay('D')"
                [ngClass]="isSunday ? 'day-button-selected' : 'day-button'"
              >
                D
              </button>
            </div>
          </div>
          <div style="display: flex">
            <label>Finaliza</label>
            <mat-radio-group
              formControlName="finish"
              (change)="changeEndOptions()"
              aria-label="Select an option"
              class="radio-group-finish"
            >
              <mat-radio-button color="primary" value="finishNever"
                >Nunca</mat-radio-button
              >
              <div style="display: flex; align-items: baseline; height: 40px">
                <mat-radio-button color="primary" value="finishDateValue"
                  >Fecha
                </mat-radio-button>
                <mat-form-field class="finish-date" appearance="outline">
                  <input
                    matInput
                    [matDatepicker]="finishDatePicker"
                    formControlName="finishDate"
                    placeholder="Fecha"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="finishDatePicker"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #finishDatePicker></mat-datepicker>
                </mat-form-field>
              </div>
              <div style="display: flex; align-items: baseline">
                <mat-radio-button color="primary" value="finishAfterValue"
                  >Luego de</mat-radio-button
                >
                <mat-form-field appearance="outline" class="finish-after">
                  <input
                    type="number"
                    matInput
                    formControlName="finishAfter"
                    placeholder="1 evento"
                  />
                </mat-form-field>
              </div>
            </mat-radio-group>
          </div>
        </div>
      </form>
      <div class="button-container">
        <button class="close-button" matStepperPrevious>Atrás</button>
        <button
          [ngClass]="
            !(
              firstFormGroup.valid &&
              secondFormGroup.valid &&
              thirdFormGroup.valid &&
              fourthFormGroup.valid
            )
              ? 'disabled-button'
              : 'create-button'
          "
          [disabled]="
            !(
              firstFormGroup.valid &&
              secondFormGroup.valid &&
              thirdFormGroup.valid &&
              fourthFormGroup.valid
            ) || isSubmitting
          "
          class="create-button"
          (click)="submit()"
        >
          {{ isCreate ? 'Crear' : !isCopy ? 'Actualizar' : 'Duplicar' }}
        </button>
      </div>
    </mat-step>
  </mat-horizontal-stepper>
</audit-modal>
