<audit-modal title="Cargar controles desde un archivo">
  <div class="drop-container">
    <div
      class="dropzone"
      fileDragDrop
      (filesChangeEmiter)="onFileDropped($event)"
    >
      <div class="container">
        <span *ngIf="!selectedFile" class="show-file">
          <div>
            <mat-icon> cloud_upload </mat-icon>
          </div>
          <p>Arrastrar y soltar archivo aquí</p>
          <div>
            <input
              type="file"
              name="file"
              id="file"
              (change)="onFileSelected($event)"
              multiple
              hidden
            />
            <label for="file" class="type-p"> o explorar archivos </label>
          </div>
        </span>
        <span *ngIf="selectedFile" class="show-file">
          <div>
            <mat-icon> cloud_done </mat-icon>
          </div>
          <p>{{ selectedFile.name }}</p>
        </span>
      </div>
    </div>
  </div>

  <div class="download-file">
    <span>Descargar archivo tipo </span>
    <a class="csv-example" href="/assets/examples/csvExample.csv"> aquí</a>
  </div>

  <div class="button-container">
    <button class="close-button" (click)="cancel()">Cancelar</button>
    <button
      [ngClass]="!selectedFile ? 'disabled-button' : 'create-button'"
      [disabled]="!selectedFile"
      type="submit"
      (click)="onSave()"
    >
      Crear controles
    </button>
  </div>
</audit-modal>
