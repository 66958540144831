import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Roles } from 'src/app/enums/enums';
import { IPaginatorEvent } from 'src/app/modules/common/table/table.component';
import { AuthService } from 'src/app/services/auth.service';
import { DateTransformService } from 'src/app/services/date-transform.service';
import { ResultService } from 'src/app/services/results.service';
import { ResultsUpdateComponent } from './results-update/results-update.component';

@Component({
  selector: 'app-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.scss'],
})
export class ResultsComponent implements OnInit {
  columns: string[] = ['Auditoría', 'Control', 'Modificado por', 'Resultado'];
  columnsNames: string[] = ['audit', 'control', 'updatedBy', 'value'];
  searchText: string = '';
  allResultsList: any[] = [];
  @Input() resultsList: any = [];
  @Input() isAuditDetails: boolean;
  @Input() auditId: number;
  showLoading: boolean = false;
  total: number = 0;
  offset: number = 0;
  limit: number = 25;
  showActionCol: boolean = true;

  constructor(
    private resultsService: ResultService,
    private dateTransformService: DateTransformService,
    private dialog: MatDialog,
    private authService: AuthService,
  ) {}

  ngOnInit(): void {
    if (this.isAuditDetails) {
      this.columns.shift();
      this.columnsNames.shift();
    }
    this.read();
    this.showActionButton();
  }

  handlePageChange(event: IPaginatorEvent) {
    this.offset = event.offset;
    this.limit = event.limit;
    this.read();
  }

  read() {
    const params = {
      offset: this.offset,
      limit: this.limit,
      param: this.searchText,
    };
    if (!this.auditId) {
      this.resultsService.read(params).subscribe((res: any) => {
        this.showLoading = false;
        this.total = res.total;
        this.resultsList = res.results;
        this.allResultsList = this.resultsList;
      });
    } else {
      this.resultsService.readByAudit(this.auditId, params).subscribe((res: any) => {
        this.showLoading = false;
        this.total = res.total;
        this.resultsList = this.dateTransformService.transformDates(res.results);
        this.allResultsList = this.resultsList;
      });
    }
  }

  update(id: number) {
    this.resultsService.readOne(id).subscribe((res: any) => {
      const dialogRef = this.dialog.open(ResultsUpdateComponent, {
        data: { result: res, id },
        autoFocus: false,
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        this.read();
      });
    });
  }

  delete(id: number) {
    this.resultsService.delete(id).subscribe(() => {
      this.read();
    });
  }

  search(query: string) {
    this.searchText = query;
    this.showLoading = true;
    this.read();
  }

  showActionButton() {
    const user = this.authService.get();
    if (user.role === Roles.CLIENT) {
      this.showActionCol = false;
    }
  }
}
