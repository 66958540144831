<div class="content">
    <div class="snackbar-content">
        <div class="snackbar-message">
            <mat-icon [ngClass]="view" class="icon"> {{ icon }} </mat-icon>
            <p class="message" [ngClass]="view">{{ message }}</p>
        </div>
        <div class="additional-content" *ngIf="content">
            <p style="white-space: pre-wrap;">{{ content }}</p>
        </div>
    </div>
</div>