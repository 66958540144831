<button class="box-tag" (click)="displayOptions()">
    Etiquetas
</button>

<div class="tags" *ngIf="options">
    <div class="d-flex flex-column">
        <div *ngFor="let tag of tags" class="tags-list">
            <mat-label> {{tag.name}}</mat-label>
            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu"
                class="ml-2">
                <svg [id]="'pencil-' " width="16" height="17" viewBox="0 0 16 17" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M14.27 6.0802L10.5493 2.39801L11.775 1.17061C12.1106 0.834541 12.5229 0.666504 13.012 0.666504C13.5005 0.666504 13.9126 0.834541 14.2482 1.17061L15.4738 2.39801C15.8094 2.73408 15.9845 3.13971 15.9991 3.61489C16.0137 4.08948 15.8532 4.49481 15.5176 4.83089L14.27 6.0802ZM0.875463 16.6665C0.627415 16.6665 0.419639 16.5823 0.252133 16.414C0.0840446 16.2463 0 16.0382 0 15.7898V13.3131C0 13.1962 0.0218866 13.0831 0.0656598 12.9738C0.109433 12.8639 0.175093 12.7651 0.262639 12.6775L9.27991 3.64733L13.0006 7.37335L3.98336 16.4035C3.89581 16.4912 3.79747 16.5569 3.68833 16.6008C3.5786 16.6446 3.46537 16.6665 3.34865 16.6665H0.875463Z"
                        fill="#D4D4D4" />
                </svg> </button>
            <mat-menu #menu="matMenu">
                <button class="pl-3" (click)="updateTag(tag)" mat-menu-item>
                    <span>Modificar</span>
                </button>
                <button class="pl-3" (click)="deleteTag(tag)" mat-menu-item>
                    <span>Eliminar</span>
                </button>
            </mat-menu>
        </div>
    </div>

    <div class="w-100 border-tag"></div>

    <input [(ngModel)]="newTagValue" #newTag placeholder="Etiqueta nueva" class="mt-3">

    <div class="d-flex justify-content-between mt-3">
        <div (click)="cancelTag()" class="cancel-new-tag">
            Cancelar
        </div>
        <div (click)="createTag()" class="create-new-tag">
            Crear
        </div>
    </div>
</div>