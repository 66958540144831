import {
  Component, EventEmitter, Input, Output,
} from '@angular/core';

@Component({
  selector: 'audit-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent {
  @Input('title') title: string;
  @Input('subtitle') subtitle?: string;
  @Input('closeButton') closeButton: boolean = true;

  @Output('onClose') onClose = new EventEmitter();

  onCloseClick() {
    this.onClose.emit();
  }
}
