import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IPaginatorEvent } from 'src/app/modules/common/table/table.component';
import { AuditableObject } from 'src/app/interfaces/auditable-object.interface';
import { AlertsService } from 'src/app/services/alerts.service';
import { AuditableObjectService } from 'src/app/services/auditable-object.service';
import { DateTransformService } from 'src/app/services/date-transform.service';
import { AuditableObjectCreateUpdateComponent } from './auditable-object-create-update/auditable-object-create-update.component';
import { AuthService } from 'src/app/services';
import { Roles } from 'src/app/enums/enums';

@Component({
  selector: 'app-auditable-object',
  templateUrl: './auditable-object.component.html',
  styleUrls: ['./auditable-object.component.scss'],
})
export class AuditableObjectComponent implements OnInit {
  columns: string[] = [
    'Nombre',
    'Descripción',
    'Auditado',
    'Creado por',
    'Modificado por',
  ];
  columnsNames: string[] = [
    'name',
    'description',
    'client',
    'createdBy',
    'updatedBy',
  ];
  searchText = '';
  allAuditableObjects: AuditableObject[] = [];
  total = 0;
  offset = 0;
  limit = 25;

  showLoading = false;
  canCreate = false;

  @Input() auditableObjectList: AuditableObject[] = [];

  constructor(
    private auditableObjectService: AuditableObjectService,
    private dialog: MatDialog,
    private dateTransformService: DateTransformService,
    private alertsService: AlertsService,
    private auth: AuthService,
  ) {}

  ngOnInit(): void {
    const userRole = this.auth.get().role;
    if (userRole == Roles.SUPER_ADMIN || userRole == Roles.ADMIN) {
      this.canCreate = true;
    }
    this.read();
  }

  handlePageChange(event: IPaginatorEvent) {
    this.offset = event.offset;
    this.limit = event.limit;
    this.read();
  }

  read() {
    const params = {
      offset: this.offset,
      limit: this.limit,
      param: this.searchText,
    };
    this.auditableObjectService.read(params).subscribe((res: any) => {
      this.showLoading = false;
      this.total = res.total;
      this.auditableObjectList = this.dateTransformService.transformDates(
        res.auditableObjects,
      );
      this.allAuditableObjects = this.auditableObjectList;
    });
  }

  update(id: number) {
    this.auditableObjectService.readOne(id).subscribe((res: any) => {
      const dialogRef = this.dialog.open(AuditableObjectCreateUpdateComponent, {
        data: { auditableObject: res, id },
        autoFocus: false,
        maxHeight: '90%',
        panelClass: 'modal-styles',
        minWidth: '30%',
      });
      dialogRef.afterClosed().subscribe(() => {
        this.read();
      });
    });
  }

  create() {
    const dialogRef = this.dialog.open(AuditableObjectCreateUpdateComponent, {
      autoFocus: false,
      maxHeight: '90%',
      panelClass: 'modal-styles',
      minWidth: '30%',
    });
    dialogRef.afterClosed().subscribe(() => {
      this.read();
    });
  }

  async delete(id: number) {
    const confirmed = await this.alertsService.confirm(
      '¿Confirma la eliminación de este objeto auditable?',
    );
    if (confirmed) {
      this.auditableObjectService.delete(id).subscribe({
        next: () => {
          this.alertsService.success('Objeto auditable eliminado con éxito');
          this.read();
        },
        error: (error) => {
          console.log(error);
          this.alertsService.error('Error al eliminar el objeto auditable');
        },
      });
    }
  }

  search(query: string) {
    this.searchText = query;
    this.showLoading = true;
    this.read();
  }
}
