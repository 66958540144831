import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CheckListsTypeService } from 'src/app/services/check-lists-type.service';
import { CheckListsService } from 'src/app/services/check-lists.service';
import { CheckListsTypeComponent } from '../check-lists-type.component';

@Component({
  selector: 'app-check-list-type-create-update',
  templateUrl: './check-list-type-create-update.component.html',
  styleUrls: ['./check-list-type-create-update.component.scss'],
})
export class CheckListTypeCreateUpdateComponent implements OnInit {
  checkListsTypeForm = new FormGroup({
    name: new FormControl(''),
    description: new FormControl(''),
  });
  @Output('onCancel') onCancel = new EventEmitter();
  checkListList: any = [];
  isCreate: boolean = true;

  constructor(
    private checkListTypeService: CheckListsTypeService,
    private checkListService: CheckListsService,
    public dialogRef: MatDialogRef<CheckListsTypeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.checkListService.read().subscribe((res: any) => {
      this.checkListList = res;
    });
    if (this.data) {
      this.isCreate = false;
      this.checkListsTypeForm.setValue({
        name: this.data.checkListType.name,
        description: this.data.checkListType.description,
      });
    }
  }

  cancel() {
    this.dialogRef.close();
  }

  submit(): void {
    if (this.data) {
      this.update();
    } else {
      this.create();
    }
    this.dialogRef.close();
  }

  create() {
    this.checkListTypeService.create(this.checkListsTypeForm.value).subscribe();
  }

  update() {
    this.checkListTypeService
      .update(this.data.id, this.checkListsTypeForm.value)
      .subscribe();
  }
}
