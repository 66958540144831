import { Component, Inject, OnInit } from '@angular/core';
import {
  FormControl, FormGroup, UntypedFormControl, Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuditableObjectService } from 'src/app/services/auditable-object.service';
import { Client } from 'src/app/interfaces/client.interface';
import { ClientsService } from 'src/app/services/clients.service';
import { AlertsService } from 'src/app/services/alerts.service';
import { AuditableObjectComponent } from '../auditable-object.component';

const VALID_FILE_FORMAT = ['.png', '.jpg', '.jpeg', '.gif', '.svg'];

@Component({
  selector: 'app-auditable-object-create-update',
  templateUrl: './auditable-object-create-update.component.html',
  styleUrls: ['./auditable-object-create-update.component.scss'],
})
export class AuditableObjectCreateUpdateComponent implements OnInit {
  auditableObjectForm = new FormGroup({
    name: new FormControl('', Validators.required),
    description: new FormControl(''),
    clientId: new FormControl('', Validators.required),
  });

  isCreate: boolean = true;
  auditedList: Client[] = [];
  image: File | null;
  preview: any;
  imageChanged: boolean = false;
  validFileFormat: string = VALID_FILE_FORMAT.join(',');

  constructor(
    private auditableObjetService: AuditableObjectService,
    private clientService: ClientsService,
    private alertsService: AlertsService,
    public dialogRef: MatDialogRef<AuditableObjectComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit(): void {
    this.read();
    if (this.data) {
      this.isCreate = false;
      this.auditableObjectForm.setValue({
        name: this.data.auditableObject.name,
        description: this.data.auditableObject.description,
        clientId: this.data.auditableObject.clientId,
      });
      this.preview = this.data.auditableObject.imageUrl;
    }
  }

  cancel() {
    this.dialogRef.close();
  }

  read() {
    this.clientService.read().subscribe((res: any) => {
      this.auditedList = res.clients;
    });
  }

  submit(): void {
    if (this.data) {
      this.update();
    } else {
      this.create();
    }
    this.dialogRef.close();
  }

  create() {
    this.auditableObjetService
      .create({
        ...this.auditableObjectForm.value,
        image: this.image,
      })
      .subscribe(
        {
          next: () => {
            this.dialogRef.close();
            this.alertsService.success('Objeto auditable creado con éxito');
          },
          error: (error) => {
            console.log(error);
            this.alertsService.error('Error al crear objeto auditable');
          },
        },
      );
  }

  update() {
    this.auditableObjetService
      .update(this.data.id, {
        ...this.auditableObjectForm.value,
        image: this.image,
      })
      .subscribe({
        next: () => {
          this.dialogRef.close();
          this.alertsService.success('Objeto auditable editado con éxito');
        },
        error: (error) => {
          console.log(error);
          this.alertsService.error('Error al editar objeto auditable');
        },
      });
  }
}
