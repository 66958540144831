<div>
    <div  class="d-flex justify-content-between mt-4">
        <div class="filters">
            <searcher [label]="'Buscar Acciones Correctivas'" (onChange)="search($event)" [showLoading]="showLoading"></searcher>
            <a-input label="Estado" class="statusFilter">
                <mat-form-field appearance="outline" #statusSelect>
                    <mat-select placeholder="Filtrar por Estado" (selectionChange)="onStatusChange()">
                        <mat-option>-</mat-option>
                        <mat-option value="approved">Aprobadas</mat-option>
                        <mat-option value="rejected">Rechazadas</mat-option>
                        <mat-option value="pending">Para evaluar</mat-option>
                    </mat-select>
                </mat-form-field>

            </a-input>
        </div>
    </div>
<app-table  [data]="correctiveActionsList"  [displayedColumns]="columns" [columnsNames]="columnsNames" [total]="total" [offset]="offset" [limit]="limit" 
    (update)="update($event)" (delete)="delete($event)" (approval)="approval($event)" (onPageChange)="handlePageChange($event)" [showActionColumn]="showActionCol" [isApproval]="true"></app-table>

</div>
