<div>
    <div class="d-flex justify-content-between mt-4">
        <div class="filters">
            <searcher [label]="'Buscar Auditorías'" (onChange)="search($event)" [showLoading]="showLoading"></searcher>
            <a-input label="Estado" class="statusFilter">
                <mat-form-field appearance="outline" #statusSelect>
                    <mat-select placeholder="Filtrar por estado"
                        (selectionChange)="onStatusChange()">
                        <mat-option>-</mat-option>
                        <mat-option value="expired">Vencidas</mat-option>
                        <mat-option value="completed">Completadas</mat-option>
                        <mat-option value="inProcess">En proceso</mat-option>
                        <mat-option value="pending">Pendientes</mat-option>
                        <mat-option value="deleted">Eliminadas</mat-option>
                    </mat-select>
                </mat-form-field>
            </a-input>
        </div>
        <div>
            <button (click)="create()" class="create-button" customToolTip={{textAudit}}>
                Crear auditoría
            </button>
        </div>
    </div>
    <app-table [data]="auditsList" [displayedColumns]="columns" [columnsNames]="columnsNames" [total]="total" [offset]="offset" [limit]="limit" (sort)="sort($event)"
    (update)="update($event)" (delete)="delete($event)" (duplicate)="duplicate($event)" (onPageChange)="handlePageChange($event)" [showActionColumn]="showActionCol"
    [isAudit]="true" [clickableHeader]="true" [order]="this.desc" ></app-table>

</div>