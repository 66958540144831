<div>
    <div class="d-flex mt-4">

        <searcher [label]="'Buscar Hallazgos'" (onChange)="search($event)" [showLoading]="showLoading"></searcher>
        <div class="solved-container">
            <span class="title-check">Listar resueltos</span>
            <mat-checkbox [ngModel]="listSolved" (change)="listSelected($event)" color="primary"></mat-checkbox>
        </div>

    </div>
    <app-table [data]="findingsList" [isCorrectiveActions]="true" [displayedColumns]="columns"
        [columnsNames]="columnsNames" [total]="total" [offset]="offset" [limit]="limit" (update)="update($event)"
        (delete)="delete($event)" (correctiveActions)="correctiveActions($event)"
        (onPageChange)="handlePageChange($event)" [showActionColumn]="showActionCol"></app-table>

</div>