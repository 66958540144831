import {
  Component, EventEmitter, Input, Output,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { debounce } from 'lodash';
import { startWith } from 'rxjs';
import { Control } from 'src/app/interfaces';
import { ControlsResponse } from 'src/app/interfaces/controls-response.interface';
import { ControlService } from 'src/app/services/controls.service';

const DELAY = 500;

@Component({
  selector: 'controls-finder',
  templateUrl: './controls-finder.component.html',
  styleUrls: ['./controls-finder.component.scss'],
})
export class ControlsFinderComponent {
  searcher = new FormControl('', Validators.minLength(3));
  loading = false;
  controls: Control[] = [];
  selected: Record<number, Control> = {};
  @Input('selected') inital: Record<number, Control> = {};
  @Output('onSelect') onSelect = new EventEmitter<Control>();
  @Output('onDeselect') onDeselect = new EventEmitter<Control>();

  constructor(
    private service: ControlService,
  ) { }

  ngOnInit(): void {
    this.read();
    this.search = debounce(this.search, DELAY);
    this.subscribeToSearcher();
    this.selected = this.inital;
  }

  read(): void {
    this.service.read().subscribe((res: any) => {
      this.controls = res.controls;
    })
  }

  ngOnChanges(): void {
    this.selected = this.inital;
  }

  private search(param: string) {
    this.loading = true;
    this.service.read({
      param,
    }).subscribe((controls: ControlsResponse) => {
      this.controls = controls.controls;
      this.loading = false;
    }, ({ message }: Error) => {
      console.error(message);
      this.loading = false;
    });
  }

  private subscribeToSearcher() {
    this.searcher.valueChanges.pipe(
      startWith(''),
    ).subscribe(() => {
      this.validateAndSearch();
    });
  }

  private validateAndSearch() {
    const input = this.searcher;
    if (typeof input.value !== 'string') {
      return;
    }
    if (input.value == '') this.read();
    if (input.valid && input.value) {
      this.search(input.value);
    }
  }

  setDisplayWith(entity: Control) {
    return entity?.name;
  }

  toggleControl(control: Control) {
    if (this.selected[control.id]) {
      this.deselect(control);
      delete this.selected[control.id];
    } else {
      this.select(control);
      this.selected[control.id] = control;
    }
  }

  cleanSearch() {
    this.searcher.setValue('');
    this.read();
  }

  select(control: Control) {
    this.onSelect.emit(control);
  }

  deselect(control: Control) {
    this.onDeselect.emit(control);
  }
}
