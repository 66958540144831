import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AuditControl } from 'src/app/interfaces/audit-control.interface';
import { Audit } from 'src/app/interfaces/audit.interface';
import { Service } from 'src/app/interfaces/service.interface';
import { AuditControlService } from 'src/app/services/audit-control.service';
import { AuditsService } from 'src/app/services/audits.service';
import { CorrectiveActionsService } from 'src/app/services/corrective-actions.service';
import { FindingsService } from 'src/app/services/findigs.service';
import { ResultService } from 'src/app/services/results.service';
import { DateTransformService } from 'src/app/services/date-transform.service';
import { AuditViewComponent } from './audit-view/audit-view.component';
import { Location } from '@angular/common';

@Component({
  selector: 'app-audit-report',
  templateUrl: './audit-report.component.html',
  styleUrls: ['./audit-report.component.scss'],
})
export class AuditReportComponent implements OnInit {
  id: number;
  audit: Audit;
  genericList: any = [];
  auditControls: AuditControl[];
  services: Service[];
  selectedIndex = 0;

  constructor(
    private route: ActivatedRoute,
    private auditService: AuditsService,
    private auditControlService: AuditControlService,
    private resultService: ResultService,
    private findingsService: FindingsService,
    private correctiveActionsService: CorrectiveActionsService,
    private dialog: MatDialog,
    private router: Router,
    private dateTransformService: DateTransformService,
    private location: Location,
  ) {}

  ngOnInit() {
    this.route.params.subscribe(({ id }) => {
      this.id = id;
      const params = {
        details: true,
      };
      this.auditService.readOne(id, params).subscribe((response: Audit) => {
        this.audit = this.dateTransformService.transformOneAuditDate(response);
      });
    });

    this.route.queryParams.subscribe((params) => {
      if (params['tab'] === 'findings') {
        this.selectedIndex = 1;
      } else if (params['tab'] === 'corrective-actions') {
        this.selectedIndex = 2;
      } else {
        this.selectedIndex = 0;
      }
    });
  }

  generatePdf() {
    this.auditService.getPdf(this.id).subscribe(
      (response: Blob) => {
        const blob = new Blob([response], { type: 'application/pdf' });
        const downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(blob);
        downloadLink.setAttribute(
          'download',
          `auditoria_${this.audit.code}.pdf`,
        );
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      },
      (error) => {
        console.error('Error al descargar el PDF:', error);
      },
    );
  }

  readResults() {
    this.resultService.readByAudit(this.id).subscribe((res: any) => {
      this.genericList = res;
    });
  }

  readFindings() {
    this.findingsService.readByAudit(this.id).subscribe((res: any) => {
      this.genericList = res;
    });
  }

  openView() {
    this.dialog.open(AuditViewComponent);
    this.auditService.readOne(this.id).subscribe((res: any) => {
      this.dialog.open(AuditViewComponent, {
        data: res,
      });
    });
  }

  backToAudits() {
    this.location.back();
  }

  readCorrectiveActions() {
    this.correctiveActionsService
      .readByAudit(this.id)
      .subscribe((res: any[]) => {
        this.genericList = res;
      });
  }
}
