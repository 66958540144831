import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { User } from 'src/app/interfaces';
import { AuthService, SidebarService } from 'src/app/services';

export interface ISidebarChildOption {
  title: string;
  action: string | (() => void);
}

export interface ISidebarOption {
  title: string;
  icon?: string;
  collapsed?: boolean;
  action?: string | (() => void);
  items?: ISidebarChildOption[];
  withSeparator?: boolean;
}

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent {
  @Input('toggleSidebar') toggleSidebar: () => void;
  menuItems?: any[];
  isExpanded = true;
  selected: number | undefined;
  groupStatus: Record<number, boolean> = {};
  selectedParent: number;
  selectedChild: number | null = null;
  user: User;
  isSuperAdmin: boolean;

  constructor(
    private auth: AuthService,
    private sidebarService: SidebarService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.menuItems = this.sidebarService.getSidebar(this.auth.get());
    this.user = this.auth.get();
    if (this.user.role === 1) this.isSuperAdmin = true;
  }

  route(itemIcon: string) {
    const route = `assets/sidebar-icons/${itemIcon}`;
    return route;
  }

  logout(): void {
    this.auth.logout();
  }

  setExpanded() {
    this.isExpanded = !this.isExpanded;
    this.toggleSidebar();
  }

  isSelected(index: number): boolean {
    return this.selected === index;
  }

  optionSelected(parentIndex: number, childIndex?: number) {
    const prevParentEl = document.querySelector(`#icon-${this.selected}`);
    prevParentEl?.classList.remove('option-selected');

    const prevChildEl = document.querySelector(
      `#icon-${this.selected}${this.selectedChild}`,
    );
    prevChildEl?.classList.remove('option-selected');

    this.selected = parentIndex;
    this.selectedChild = childIndex !== undefined ? childIndex : null;

    if (childIndex === undefined) {
      const el = document.querySelector(`#icon-${this.selected}`);
      el?.classList.add('option-selected');
    } else {
      const el = document.querySelector(
        `#icon-${this.selected}${this.selectedChild}`,
      );
      el?.classList.add('option-selected');
    }
  }

  onOptionClick(option: ISidebarOption) {
    if (option.items) option.collapsed = !option.collapsed;
  }

  changeCompany() {
    this.router.navigate(['/admin/companies']);
  }
}
