import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, map } from 'rxjs';
import { BaseService } from './base.service';
import { IQuery } from '../interfaces';
import { Employee } from '../interfaces/employee.interface';

@Injectable({
  providedIn: 'root',
})
export class EmployeesService extends BaseService {
  constructor(
    private http: HttpClient,
  ) {
    super();
  }

  create(body: any) {
    return this.http.post(`${this.url}/employees`, body);
  }
  update(id:number, body: any) {
    return this.http.put(`${this.url}/employees/${id}`, body);
  }

  read(params: IQuery = {}): Observable<{data: Employee[], total:number}> {
    return this.http.get<{data: Employee[], total:number}>(`${this.url}/employees`, {
      params,
    }).pipe(map((res) => ({
      data: res.data.map((employee) => {
        employee.fullName = `${employee.firstName} ${employee.lastName}`;
        return employee;
      }),
      total: res.total,
    })));
  }

  findOne(id: number): Observable<Employee> {
    return this.http.get<Employee>(`${this.url}/employees/${id}`);
  }

  delete(id:number) {
    return this.http.delete(`${this.url}/employees/${id}`);
  }
}
