export enum ControlPriority {
  BAJA = 1,
  MEDIA = 2,
  ALTA = 3,
}

export enum Roles {
  SUPER_ADMIN = 1,
  ADMIN = 2,
  AUDITOR = 3,
  CLIENT = 4,
}

export enum AuditResults {
  COMPLY = 1,
  NOTCOMPLY = 2,
  NA = 3,
}

export enum AuditResultNames {
  COMPLY = 'CUMPLE',
  NOTCOMPLY = 'NO CUMPLE',
  NA = 'NO APLICA',
}

export interface AuditResult {
  id: number;
  name: string;
}

export const AuditResultObjects: AuditResult[] = [
  {
    id: AuditResults.COMPLY,
    name: AuditResultNames.COMPLY,
  },
  {
    id: AuditResults.NOTCOMPLY,
    name: AuditResultNames.NOTCOMPLY,
  },
  {
    id: AuditResults.NA,
    name: AuditResultNames.NA,
  },
];

export enum EntityType {
  CLIENT = 1,
  VEHICLE = 2,
  COMPANY = 3,
}

export enum CorrectiveActionApproval {
  APPROVED = 'Aprobado',
  REJECTED = 'Rechazado',
  PENDING = 'Para evaluar',
}

export enum AuditStatus {
  COMPLETED = 'Completada',
  INPROCESS = 'En proceso',
  PENDING = 'Pendiente',
}

export enum FindingSolved {
  APPROVED = 'Resuelto',
  PENDING = 'Por resolver',
}

export enum FinishOptions {
  NEVER = 'finishNever',
  DATE = 'finishDateValue',
  AFTER = 'finishAfterValue',
}
