import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Company, CreateCompany, UpdateCompany } from '../interfaces';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class CompanyService extends BaseService {
  constructor(private http: HttpClient) {
    super();
  }

  create(data: CreateCompany) {
    return this.http.post(`${this.url}/companies`, data);
  }

  read(): Observable<Company[]> {
    return this.http.get<Company[]>(`${this.url}/companies`);
  }

  readOne(id: number): Observable<Company> {
    return this.http.get<Company>(`${this.url}/companies/${id}`);
  }

  update(id: number, data: UpdateCompany): Observable<UpdateCompany> {
    return this.http.put<UpdateCompany>(`${this.url}/companies/${id}`, data);
  }

  delete(id: number) {
    return this.http.delete(`${this.url}/companies/${id}`);
  }
}
