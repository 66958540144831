import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { BaseService } from './base.service';
import { Audit } from '../interfaces/audit.interface';
import { Result } from '../interfaces/results.interface';
import { IQuery } from '../interfaces';
import { ResultsResponse } from '../interfaces/results-response.interface';

@Injectable({
  providedIn: 'root',
})
export class ResultService extends BaseService {
  constructor(private http: HttpClient, private router: Router) {
    super();
  }

  read(params: IQuery = {}): Observable<ResultsResponse> {
    return this.http.get<ResultsResponse>(`${this.url}/results`, {
      params,
    });
  }

  readByAudit(auditId: number, params: IQuery = {}): Observable<Result[]> {
    return this.http.get(`${this.url}/results/audit/${auditId}`, {
      params,
    }) as Observable<
      Result[]
    >;
  }

  readOne(id: number): Observable<Result> {
    return this.http.get(
      `${this.url}/results/${id}`,
      this.options(),
    ) as Observable<Result>;
  }

  update(id: number, data: any) {
    return this.http.put(`${this.url}/results/${id}`, data, this.options());
  }

  delete(id: number) {
    return this.http.delete(`${this.url}/results/${id}`, this.options());
  }
}
