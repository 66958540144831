import { Injectable } from '@angular/core';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DateTransformService {

  transformDates(data: any[]): any[] {
    if(data){
      return data.map((item: any) => {
        if(item.createdAt){
          const createdDate = new Date(item.createdAt);

          const createdDay = createdDate.getDate().toString().padStart(2, '0');
          const createdMonth = (createdDate.getMonth() + 1).toString().padStart(2, '0');
          const createdYear = createdDate.getFullYear();
    
          item.createdAt = `${createdDay}/${createdMonth}/${createdYear}`;
        }

        if(item.updatedAt){
          const updatedDate = new Date(item.updatedAt);

          const updatedDay = updatedDate.getDate().toString().padStart(2, '0');
          const updatedMonth = (updatedDate.getMonth() + 1).toString().padStart(2, '0');
          const updatedYear = updatedDate.getFullYear();

          item.updatedAt = `${updatedDay}/${updatedMonth}/${updatedYear}`;
        }
  
        if(item.date){
          const date = new Date(item.date);
  
          const Day = date.getDate().toString().padStart(2, '0');
          const Month = (date.getMonth() + 1).toString().padStart(2, '0');
          const Year = date.getFullYear();
  
          item.date = `${Day}/${Month}/${Year}`;
        }

        if(item.expirationDate){
          const date = new Date(item.expirationDate);
  
          const Day = date.getDate().toString().padStart(2, '0');
          const Month = (date.getMonth() + 1).toString().padStart(2, '0');
          const Year = date.getFullYear();
  
          item.expirationDate = `${Day}/${Month}/${Year}`;
        }

        if(item.expiration){
          const expiration = new Date(item.expiration);
  
          const Day = expiration.getDate().toString().padStart(2, '0');
          const Month = (expiration.getMonth() + 1).toString().padStart(2, '0');
          const Year = expiration.getFullYear();
  
          item.expiration = `${Day}/${Month}/${Year}`;
        }
        return item;
      });
    }
    return [];
  }

  transformOneAuditDate(data: any): any {
    const createdDate = new Date(data.createdAt);
    const updatedDate = new Date(data.updatedAt);
    const auditDate = new Date(data.date);
    const auditExpirationDate = new Date(data.expirationDate);

    const createdDay = createdDate.getDate().toString().padStart(2, '0');
    const createdMonth = (createdDate.getMonth() + 1).toString().padStart(2, '0');
    const createdYear = createdDate.getFullYear();

    const updatedDay = updatedDate.getDate().toString().padStart(2, '0');
    const updatedMonth = (updatedDate.getMonth() + 1).toString().padStart(2, '0');
    const updatedYear = updatedDate.getFullYear();

    const auditDay = auditDate.getDate().toString().padStart(2, '0');
    const auditMonth = (auditDate.getMonth() + 1).toString().padStart(2, '0');
    const auditYear = auditDate.getFullYear();

    const auditExpirationDay = auditExpirationDate.getDate().toString().padStart(2, '0');
    const auditExpirationMonth = (auditExpirationDate.getMonth() + 1).toString().padStart(2, '0');
    const auditExpirationYear = auditExpirationDate.getFullYear();

    data.createdAt = `${createdDay}/${createdMonth}/${createdYear}`;
    data.updatedAt = `${updatedDay}/${updatedMonth}/${updatedYear}`;
    data.date = `${auditDay}/${auditMonth}/${auditYear}`;
    data.expirationDate = `${auditExpirationDay}/${auditExpirationMonth}/${auditExpirationYear}`;
    return data;
  }
}
