import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { AuditableObjectDashboardComponent } from './auditable-object/auditable-object-dashboard.component';
import { AuditDashboardComponent } from './audits/audit-dashboard.component';
import { AuditReportComponent } from './audits/audit/audit-report/audit-report.component';
import { ClientsDashboardComponent } from './clients/clients-dashboard.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PagesComponent } from './pages.component';
import { ServiceDashboardComponent } from './services/service-dashboard.component';
import { UsersComponent } from './users/users.component';
import { ClientLocationsComponent } from './clients/client-locations/client-locations.component';
import {
  AUDITABLE_OBJECT,
  AUDITS,
  CLIENTS,
  DASHBOARD,
  DOCUMENTS,
  RESOURCES,
  SERVICES,
  USERS,
} from '../../utils/urls';
import { DocumentsComponent } from './documents/documents.component';
import { ResourcesComponent } from './resources/resources.component';

const routes: Route[] = [
  {
    path: '',
    component: PagesComponent,
    children: [
      {
        path: DASHBOARD,
        component: DashboardComponent,
      },
      {
        path: AUDITS,
        component: AuditDashboardComponent,
      },
      {
        path: `${AUDITS}/:id`,
        component: AuditReportComponent,
      },
      {
        path: SERVICES,
        component: ServiceDashboardComponent,
      },
      {
        path: DOCUMENTS,
        component: DocumentsComponent,
      },
      // ** TBD **
      // {
      //   path: 'contratistas',
      //   component: ServiceDashboardComponent,
      // },
      {
        path: AUDITABLE_OBJECT,
        component: AuditableObjectDashboardComponent,
      },
      {
        path: CLIENTS,
        component: ClientsDashboardComponent,
      },
      {
        path: `${CLIENTS}/:id`,
        component: ClientLocationsComponent,
      },
      // ** TBD **
      // {
      //   path: 'sistema',
      //   component: ServiceDashboardComponent,
      // },
      {
        path: USERS,
        component: UsersComponent,
      },
      {
        path: DASHBOARD,
        component: DashboardComponent,
      },
      {
        path: RESOURCES,
        component: ResourcesComponent,
      },
      {
        path: '**',
        redirectTo: DASHBOARD,
      },
    ],
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PagesRoutingModule {}
