import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';
import { IQuery } from '../interfaces';
import { Vehicle } from '../interfaces/vehicle.interface';

@Injectable({
  providedIn: 'root',
})
export class VehiclesService extends BaseService {
  constructor(
    private http: HttpClient,
  ) {
    super();
  }

  create(body: any) {
    return this.http.post(`${this.url}/vehicles`, body);
  }

  update(id:number, body: any) {
    return this.http.put(`${this.url}/vehicles/${id}`, body);
  }

  read(params: IQuery = {}): Observable<{total:number, vehicles: Vehicle[], }> {
    return this.http.get<{vehicles: Vehicle[], total:number}>(`${this.url}/vehicles`, {
      params,
    });
  }

  findOne(id: number): Observable<Vehicle> {
    return this.http.get<Vehicle>(`${this.url}/vehicles/${id}`);
  }

  delete(id:number) {
    return this.http.delete(`${this.url}/vehicles/${id}`);
  }
}
