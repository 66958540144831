<div>
    <div class="d-flex justify-content-between mt-4">
        <div>
            <searcher [label]="'Buscar Resultados'" (onChange)="search($event)" [showLoading]="showLoading"></searcher>
        </div>
        <div>
    </div>
</div>
<app-table  [data]="resultsList" [displayedColumns]="columns" [columnsNames]="columnsNames" (update)="update($event)" (delete)="delete($event)" (onPageChange)="handlePageChange($event)"
    [showActionColumn]="showActionCol" [total]="total" [offset]="offset" [limit]="limit" [onlyEdit]="true"></app-table>
</div>
