import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ControlService } from 'src/app/services/controls.service';
import { MatDialogRef } from '@angular/material/dialog';
import { ControlsComponent } from '../controls.component';
import { AlertsService } from '../../../../../../services/alerts.service';
import { CdkDragDrop } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-controls-file-upload',
  templateUrl: './controls-file-upload.component.html',
  styleUrls: ['./controls-file-upload.component.scss'],
})
export class ControlsFileUploadComponent {
  selectedFile: any = null;
  files: any[] = [];

  constructor(
    private controlService: ControlService,
    public dialogRef: MatDialogRef<ControlsComponent>,
    private alertsService: AlertsService,
  ) {}

  onFileDropped(pFileList: File[]) {
    this.files = Object.keys(pFileList).map((key: any) => pFileList[key]);
    this.selectedFile = this.files[0];
  }

  onFileSelected(file: any) {
    this.files = Object.keys(file.target.files).map(
      (key: any) => file.target.files[key],
    );
    this.selectedFile = this.files[0];
  }
  onSave() {
    return this.controlService.createFromCvsFile(this.selectedFile).subscribe({
      next: () => {
        this.dialogRef.close();
        this.alertsService.success('Controles creado con éxito');
      },
      error: (error) => {
        console.log(error);
        this.alertsService.error('Error al cargar controles');
      },
    });
  }

  cancel() {
    this.dialogRef.close();
  }
}
