<mat-card class="list-card">
  <div>
    <div class="name-container">
      <span class="audit-code" [routerLink]="onAuditCode()">{{
        audit.auditCode
      }}</span>
      <span class="audit-name">{{ audit.name }}</span>
    </div>
    <div class="data-container">
      <div class="chart-container">
        <canvas [id]="uniqueId"></canvas>
        <div class="chart-center">{{ percentage }}%</div>
      </div>
      <div style="padding-top: 10px;">
        <span class="audit-date">
          <mat-icon fontSet="material-icons-outlined">event_available</mat-icon>
          {{ audit.date }} ({{
            daysLeft < 0 ? 'hace ' + -daysLeft : 'en ' + daysLeft
          }}
          días)
        </span>
        <span
          [ngClass]="{
            'audit-date-expired': isExpired,
            'audit-date-second': !isExpired
          }"
        >
          <mat-icon fontSet="material-icons-outlined">event_busy</mat-icon>
          {{ audit.expirationDate }} ({{
            daysLeftExpired < 0
              ? 'hace ' + -daysLeftExpired
              : 'en ' + daysLeftExpired
          }}
          días)
        </span>
        <span class="audit-audited" *ngIf="!isClient"
          ><mat-icon>work_outline</mat-icon>{{ audit.audited }}</span
        >
        <!-- <span class="audit-auditor"><mat-icon fontSet="material-icons-outlined">group</mat-icon>{{ audit.auditor
                }}</span> -->
      </div>
    </div>
  </div>
</mat-card>
