import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Employee } from 'src/app/interfaces/employee.interface';
import { IPaginatorEvent } from 'src/app/modules/common/table/table.component';
import { EmployeesService } from 'src/app/services/employees.service';
import { EmployeeFormComponent } from '../employee-form/employee-form.component';

@Component({
  selector: 'app-employee-list',
  templateUrl: './employee-list.component.html',
  styleUrls: ['./employee-list.component.scss'],
})
export class EmployeeListComponent {
  columns = [
    'Nombre',
    'Documento',
    'Email',
    'Telefono',
    'Posicion',
    'Creado por',
    'Modificado por',
  ];
  columnsNames = [
    'fullName',
    'dni',
    'email',
    'phone',
    'position',
    'createdBy',
    'updatedBy',
  ];

  employees: Employee[];

  searchText: string;
  showLoading = false;
  usersList: any = [];
  total = 0;
  offset = 0;
  limit = 25;

  constructor(
    private employeesService: EmployeesService,
    public dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.getEmployees();
  }

  search(query: string) {
    this.offset = 0;
    this.searchText = query;
    this.showLoading = true;
    this.getEmployees();
  }

  handlePageChange(event: IPaginatorEvent) {
    this.offset = event.offset;
    this.limit = event.limit;
    this.getEmployees();
  }

  getEmployees() {
    const params = {
      offset: this.offset,
      limit: this.limit,
      ...(this.searchText && { search: this.searchText }),
    };
    this.employeesService.read(params).subscribe((res: any) => {
      this.showLoading = false;
      this.employees = res.data;
      this.total = res.total;
    });
  }

  openModal() {
    this.dialog
      .open(EmployeeFormComponent, {
        width: '30%',
        minWidth: '300px',
        autoFocus: false,
      })
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.getEmployees();
        }
      });
  }

  update(event: number) {
    this.employeesService.findOne(event).subscribe((res) => {
      this.dialog
        .open(EmployeeFormComponent, {
          width: '30%',
          minWidth: '300px',
          data: { employee: res },
          autoFocus: false,
        })
        .afterClosed()
        .subscribe(() => {
          this.getEmployees();
        });
    });
  }

  delete(event: any) {
    this.employeesService.delete(event).subscribe(() => {
      this.getEmployees();
    });
  }
}
