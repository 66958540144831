import { Component } from '@angular/core';

@Component({
  selector: 'app-services-dashboard',
  templateUrl: './service-dashboard.component.html',
})
export class ServiceDashboardComponent {
  // This list is used for all child components, change with matTabSelected
  genericList: any = [];
  actualTab: string;
  params: { limit: number } = { limit: 25 };
}
