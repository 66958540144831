import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of as ofObservable } from 'rxjs';
import { BaseService } from './base.service';
import { Finding } from '../interfaces/finding.interface';
import { IQuery } from '../interfaces';
import { FindingsResponse } from '../interfaces/findings-response.interface';

@Injectable({
  providedIn: 'root',
})
export class FindingsService extends BaseService {
  constructor(
    private http: HttpClient,
    private router: Router,
  ) {
    super();
  }

  read(params: IQuery = {}): Observable<FindingsResponse> {
    return this.http.get<FindingsResponse>(`${this.url}/findings`, {
      params,
    });
  }

  readByAudit(auditId: number, params: IQuery = {}): Observable<Finding[]> {
    return this.http.get(`${this.url}/findings/audit/${auditId}`, {
      params,
    }) as Observable<Finding[]>;
  }

  readOne(id: number): Observable<Finding> {
    return this.http.get<Finding>(`${this.url}/findings/${id}`, this.options());
  }

  update(id: number, data: any) {
    return this.http.put(`${this.url}/findings/${id}`, data);
  }

  delete(id: number) {
    return this.http.delete(`${this.url}/findings/${id}`);
  }
}
