import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { delay } from 'rxjs/operators';
import { ConfirmDialogComponent } from 'src/app/modules/common/confirm-dialog/confirm-dialog.component';
import { IPaginatorEvent } from 'src/app/modules/common/table/table.component';
import { LocationService } from 'src/app/services/locations.service';
import { LocationsCreateUpdateComponent } from './locations-create-update/locations-create-update.component';
import { AuthService } from 'src/app/services';
import { Roles } from 'src/app/enums/enums';

@Component({
  selector: 'app-locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.scss'],
})
export class LocationsComponent implements OnInit {
  columns: string[] = ['Nombre', 'Descripción', 'Dirección'];
  columnsNames: string[] = ['name', 'description', 'address'];

  searchText = '';
  allLocationsList: any[] = [];

  @Input() locationsList: any = [];
  @Input() client?: any = null;

  total = 0;
  offset = 0;
  limit = 25;

  showLoading = false;
  canCreate = false;

  constructor(
    private locationsService: LocationService,
    private dialog: MatDialog,
    private auth: AuthService,
  ) {}

  ngOnInit(): void {
    const userRole = this.auth.get().role;
    if (userRole == Roles.SUPER_ADMIN || userRole == Roles.ADMIN) {
      this.canCreate = true;
    }
    this.read();
  }

  handlePageChange(event: IPaginatorEvent) {
    this.offset = event.offset;
    this.limit = event.limit;
    this.read();
  }

  read() {
    const params = {
      offset: this.offset,
      limit: this.limit,
      param: this.searchText,
    };
    if (this.client) {
      this.locationsService
        .readByClientId(this.client.id)
        .subscribe((res: any) => {
          this.showLoading = false;
          this.locationsList = res;
          this.allLocationsList = this.locationsList;
        });
    } else {
      this.locationsService.read(params).subscribe((res: any) => {
        this.showLoading = false;
        this.total = res.total;
        this.locationsList = res.locations;
        this.allLocationsList = this.locationsList;
      });
    }
  }

  update(id: any) {
    this.locationsService.readOne(id).subscribe((res: any) => {
      const dialogRef = this.dialog.open(LocationsCreateUpdateComponent, {
        data: { location: res, id, client: this.client },
        autoFocus: false,
        maxHeight: '90%',
        panelClass: 'modal-styles',
        minWidth: '30%',
      });
      dialogRef.afterClosed().subscribe(() => {
        this.read();
      });
    });
  }

  create() {
    const dialogRef = this.dialog.open(LocationsCreateUpdateComponent, {
      data: { client: this.client },
      autoFocus: false,
      maxHeight: '90%',
      panelClass: 'modal-styles',
      minWidth: '30%',
    });
    dialogRef.afterClosed().subscribe(() => {
      this.read();
    });
  }

  delete(id: any) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        message: '¿Confirma la eliminación de esta locación?',
      },
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res && res.confirmed) {
        if (this.client) {
          this.locationsService
            .removeLocationFromClient(this.client.id, id)
            .pipe(delay(1000))
            .subscribe(() => {
              this.read();
            });
        } else {
          this.locationsService
            .delete(id)
            .pipe(delay(1000))
            .subscribe(() => {
              this.read();
            });
        }
      } else {
        this.dialog.closeAll();
      }
    });
  }

  search(query: string) {
    this.searchText = query;
    this.showLoading = true;
    this.read();
  }
}
