import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IPaginatorEvent } from 'src/app/modules/common/table/table.component';
import { AlertsService } from 'src/app/services/alerts.service';
import { DateTransformService } from 'src/app/services/date-transform.service';
import { ServicesService } from 'src/app/services/services.service';
import { ServiceCreateUpdateComponent } from './service-create-update/service-create-update.component';
import { Roles } from 'src/app/enums/enums';
import { AuthService } from 'src/app/services';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss'],
})
export class ServicesComponent implements OnInit {
  searchText = '';
  allServicesList: any[] = [];
  @Input() servicesList: any = [];
  columns: string[] = ['Nombre', 'Descripción', 'Creado por', 'Modificado por'];
  columnsNames: string[] = ['name', 'description', 'createdBy', 'updatedBy'];
  total = 0;
  offset = 0;
  limit = 25;
  createBoolean = false;
  showLoading = false;
  canCreate = false;

  constructor(
    private servicesService: ServicesService,
    private dialog: MatDialog,
    private dateTransformService: DateTransformService,
    private alertsService: AlertsService,
    private auth: AuthService,
  ) {}

  ngOnInit(): void {
    this.read();
    const userRole = this.auth.get().role;
    if (userRole == Roles.SUPER_ADMIN || userRole == Roles.ADMIN) {
      this.canCreate = true;
    }
  }

  create() {
    const dialogRef = this.dialog.open(ServiceCreateUpdateComponent, {
      autoFocus: false,
      minWidth: '30%',
      maxHeight: '90%',
      panelClass: 'modal-styles',
    });
    dialogRef.afterClosed().subscribe(() => {
      this.read();
    });
  }

  handlePageChange(event: IPaginatorEvent) {
    this.offset = event.offset;
    this.limit = event.limit;
    this.read();
  }

  read() {
    const params = {
      offset: this.offset,
      limit: this.limit,
      param: this.searchText,
    };
    this.servicesService.read(params).subscribe((res: any) => {
      this.showLoading = false;
      this.total = res.total;
      this.servicesList = this.dateTransformService.transformDates(
        res.services,
      );
      this.allServicesList = this.servicesList;
    });
  }

  update(id: number) {
    this.servicesService.readOne(id).subscribe((res) => {
      const dialogRef = this.dialog.open(ServiceCreateUpdateComponent, {
        data: { service: res, id },
        autoFocus: false,
        minWidth: '30%',
        maxHeight: '90%',
        panelClass: 'modal-styles',
      });
      dialogRef.afterClosed().subscribe(() => {
        this.read();
      });
    });
  }

  async delete(id: number) {
    const confirmed = await this.alertsService.confirm(
      '¿Confirma la eliminación de este servicio?',
    );
    if (confirmed) {
      this.servicesService.delete(id).subscribe({
        next: () => {
          this.alertsService.success('Servicio eliminado exitosamente');
          this.read();
        },
        error: (error) => {
          if (error.status === 400 && error.message === 'audit asociated') {
            this.alertsService.error(
              'Este servicio está asociado a una auditoría y no se puede eliminar.',
            );
          } else {
            this.alertsService.error('No se pudo eliminar este servicio');
            console.log(error);
          }
        },
      });
    }
  }

  search(query: string) {
    this.searchText = query;
    this.showLoading = true;
    this.read();
  }
}
