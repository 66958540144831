import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';
import { IQuery } from '../interfaces';
import { UsersResponse } from '../interfaces/users-response.interface';

@Injectable({
  providedIn: 'root',
})
export class UsersService extends BaseService {
  constructor(private http: HttpClient, private router: Router) {
    super();
  }
  create(data: any) {
    return this.http.post(`${this.url}/users`, data);
  }

  read(params: IQuery = {}): Observable<UsersResponse> {
    return this.http.get<UsersResponse>(`${this.url}/users`, {
      params,
    });
  }

  readAuditors() {
    return this.http.get(`${this.url}/users/auditors`);
  }
  readAdmins() {
    return this.http.get(`${this.url}/users/admins`);
  }
  readClients() {
    return this.http.get(`${this.url}/users/clients`);
  }

  readOne(id: number) {
    return this.http.get(`${this.url}/users/${id}`);
  }

  update(id: number, data: any) {
    return this.http.put(`${this.url}/users/${id}`, data);
  }

  delete(id: number) {
    return this.http.delete(`${this.url}/users/${id}`);
  }
}
