import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CorrectiveActionsService } from 'src/app/services/corrective-actions.service';
import { AlertsService } from 'src/app/services/alerts.service';
import { CorrectiveActionsComponent } from '../corrective-actions.component';
import { CommunicationService } from 'src/app/services/communication.service';

@Component({
  selector: 'app-corrective-actions-approval',
  templateUrl: './corrective-actions-approval.component.html',
  styleUrls: ['./corrective-actions-approval.component.scss'],
})
export class CorrectiveActionsApprovalComponent {
  correctiveActionForm = new FormGroup({
    imageUrl: new FormControl(''),
    observations: new FormControl(''),
  });

  audit: any;
  finding: any;
  isApproval: boolean;

  auditName: string;
  findingDescription: string;
  description: string;

  constructor(
    public dialogRef: MatDialogRef<CorrectiveActionsComponent>,
    private alertsService: AlertsService,
    private correctiveActionService: CorrectiveActionsService,
    private communicationService: CommunicationService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit(): void {
    if (this.data) {
      this.auditName = this.data.correctiveAction.finding.auditControl.auditChecklist.audit.name;
      this.findingDescription = this.data.correctiveAction.finding.description;
      this.description = this.data.correctiveAction.description;
      this.correctiveActionForm.setValue({
        imageUrl: this.data.correctiveAction.imageUrl,
        observations: this.data.correctiveAction.observations || '',
      });
    }
  }

  approve(approved: boolean) {
    const { observations } = this.correctiveActionForm.value;
    const msg = approved ? 'Acción correctiva aprobada' : 'Acción correctiva rechazada';
    this.correctiveActionService.decide(
      this.data.correctiveAction.id,
      { observations, approved },
    ).subscribe(({
      next: () => {
        this.communicationService.updateFindings();
        this.dialogRef.close();
        this.alertsService.success(msg);
      },
      error: (error) => {
        console.log(error);
        this.alertsService.error('Error al decidir sobre acción correctiva');
      },
    }));
  }

  cancel() {
    this.dialogRef.close();
  }
}
