import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SidebarComponent } from './sidebar/sidebar.component';
import { SnackbarComponent } from './snackbar/snackbar.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { TableComponent } from './table/table.component';
import { ModalComponent } from './modal/modal.component';
import { InputComponent } from './input/input.component';
import { HeaderComponent } from './header/header.component';
import { TreeChipsComponent } from './tree-chips/tree-chips.component';
import { GMapsModule } from './google-maps/google-maps.module';
import { ControlsModule } from './controls/controls.module';
import { TagsComponent } from './tags/tags.component';
import { InputNumberComponent } from './input-number/input-number.component';
import { HelpModalComponent } from './help-modal/help-modal.component';
import { CustomToolTipComponent } from './custom-tool-tip/custom-tool-tip.component';
import { ToolTipRendererDirective } from './directives/tool-tip-renderer.directive';
import { MaterialModule } from 'src/app/material/material.module';
import { AdminToastComponent } from './admin-toast/admin-toast.component';

@NgModule({
  declarations: [
    SidebarComponent,
    SnackbarComponent,
    ConfirmDialogComponent,
    TableComponent,
    ModalComponent,
    InputComponent,
    HeaderComponent,
    TreeChipsComponent,
    TagsComponent,
    InputNumberComponent,
    HelpModalComponent,
    CustomToolTipComponent,
    ToolTipRendererDirective,
    AdminToastComponent,
  ],
  imports: [
    GMapsModule,
    MaterialModule,
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    OverlayModule,
    ControlsModule,
  ],
  exports: [
    MaterialModule,
    ControlsModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    OverlayModule,
    SidebarComponent,
    TableComponent,
    HeaderComponent,
    ModalComponent,
    TreeChipsComponent,
    InputComponent,
    TagsComponent,
    InputNumberComponent,
    ToolTipRendererDirective,
    AdminToastComponent,
  ],
})
export class CommonComponentsModule {}
