<div style="min-height: 30rem">
  <div class="d-flex justify-content-between mt-4">
    <div>
      <searcher
        [label]="'Buscar Checklists'"
        (onChange)="search($event)"
        [showLoading]="showLoading"
        [placeHolder]="'Buscar por nombre, descripción o tipo'"
      ></searcher>
      <app-tags
        (update)="updateTag($event)"
        (delete)="deleteTag($event)"
        (newTag)="createTag($event)"
        [tags]="checkListTypeList"
      ></app-tags>
    </div>
    <div>
      <button (click)="create()" class="create-button" *ngIf="canCreate">
        Crear Checklist
      </button>
    </div>
  </div>
  <app-table
    [data]="checkListList"
    [displayedColumns]="columns"
    [columnsNames]="columnsNames"
    [total]="total"
    [offset]="offset"
    [limit]="limit"
    (update)="update($event)"
    (delete)="delete($event)"
    (onPageChange)="handlePageChange($event)"
  ></app-table>
</div>
