import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import {
  AuditResultObjects,
  AuditResultNames,
  ControlPriority,
  Roles,
  CorrectiveActionApproval,
  FindingSolved,
  AuditStatus,
} from 'src/app/enums/enums';
import { AUDITS, CLIENTS, PAGES } from 'src/app/utils/urls';
import { environment } from 'src/environments/environment';

export interface IPaginatorEvent {
  offset: number;
  limit: number;
}

/**
 * @title Basic use of `<table mat-table>`
 */
@Component({
  selector: 'app-table',
  styleUrls: ['table.component.scss'],
  templateUrl: 'table.component.html',
})
export class TableComponent {
  // TABLE
  @Input() displayedColumns: string[] = [];
  @Input() columnsNames: string[] = [];
  @Input() data: any = [];
  @Input() showInfo?: boolean = false;
  @Input('isCorrectiveActions') isCorrectiveActions: boolean;
  @Input('isApproval') isApproval: boolean;
  @Input('showActionColumn') showActionColumn: boolean = true;
  @Input('onlyEdit') onlyEdit: boolean = false;
  @Input('isAudit') isAudit: boolean = false;
  @Input('showPaginator') showPaginator: boolean = true;
  @Input('clickableHeader') clickableHeader: boolean;
  @Input('order') order: boolean = false;

  // TABLE ACTIONS
  @Output('update') updateId = new EventEmitter<number>();
  @Output('delete') deleteId = new EventEmitter<number>();
  @Output('read') readId = new EventEmitter<number>();
  @Output('correctiveActions') correctiveActionsId = new EventEmitter<number>();
  @Output('approval') approvalId = new EventEmitter<number>();
  @Output('duplicate') auditId = new EventEmitter<number>();
  @Output('sort') columName = new EventEmitter<string>();
  // PAGINATOR
  @Input('pages') pages = [10, 25, 50, 75, 100];
  @Input('total') total: number;
  @Input('offset') offset: number;
  @Input('limit') limit: number;
  @Output('onPageChange') onPageChange = new EventEmitter<IPaginatorEvent>();
  pageSize: number = 25;

  // enums
  controlPriority: ControlPriority;
  protected url: string = environment.url;

  colName: string = '';

  constructor(private router: Router) {}

  stylePencil(i: number) {
    const el = document.querySelector(`#pencil-${i}`);
    el?.setAttribute('class', 'hoverPencil');
  }

  unstylePencil(i: number) {
    const el = document.querySelector(`#pencil-${i}`);
    el?.classList.remove('hoverPencil');
  }

  update(id: any) {
    this.updateId.emit(id);
  }

  delete(id: any) {
    this.deleteId.emit(id);
  }

  read(id: any) {
    this.readId.emit(id);
  }

  correctiveActions(id: any) {
    this.correctiveActionsId.emit(id);
  }
  approve(id: any) {
    this.approvalId.emit(id);
  }

  duplicate(id: any) {
    this.auditId.emit(id);
  }

  sort(name: string) {
    if (this.clickableHeader) {
      this.columName.emit(name);
      this.colName = name;
    }
  }

  enumControlPriority(id: number): string {
    try {
      const value = `${ControlPriority[id]}`;
      return value.charAt(0) + value.slice(1).toLocaleLowerCase();
    } catch {
      return '';
    }
  }

  enumAuditResult(id: number): string {
    const returnObj = AuditResultObjects.find((res) => res.id === id);
    if (returnObj) return returnObj?.name;
    return '';
  }

  enumUserRol(id: number): string {
    try {
      return Roles[id];
    } catch {
      return '';
    }
  }

  isIsoDate(str: any) {
    if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return false;
    const d = new Date(str);
    return d instanceof Date && d.toISOString() === str; // valid date
  }

  // pageChange(event: PageEvent) {
  //   const offset = event.pageIndex;
  //   const limit = event.pageSize;
  //   this.onPageChange.emit({ offset, limit });
  // }

  setPaginator({ pageIndex, pageSize }: PageEvent) {
    this.offset = pageIndex * pageSize;
    this.limit = pageSize;
    this.onPageChange.emit({
      offset: this.offset,
      limit: this.limit,
    });
  }

  showColumn(column: string) {
    return (
      column !== 'value' &&
      column !== 'audit' &&
      column !== 'code' &&
      column !== 'auditCode' &&
      column !== 'locations' &&
      column !== 'companyLogo' &&
      column !== 'approved' &&
      column !== 'solved' &&
      (column !== 'name' || !this.showInfo)
    );
  }

  resultClass(result: string) {
    if (
      result === AuditResultNames.NOTCOMPLY ||
      result === CorrectiveActionApproval.REJECTED ||
      result === FindingSolved.PENDING
    ) {
      return 'not-apply-tag';
    }
    if (
      result === AuditResultNames.COMPLY ||
      result === CorrectiveActionApproval.APPROVED ||
      result === FindingSolved.APPROVED
    ) {
      return 'fulfill-tag';
    }
    if (
      result === AuditResultNames.NA ||
      result === CorrectiveActionApproval.PENDING
    ) {
      return 'not-applicable-tag';
    }
    return '';
  }

  TypeOf(column: string, data: any) {
    let val = data;
    if (column.includes('.')) {
      const properties = column.split('.');
      properties.forEach((prop) => {
        val = val[prop];
        column = prop;
      });
    } else {
      val = data[column];
    }
    if (column === 'stopActivity') {
      return val ? 'Si' : 'No';
    }
    if (column === 'approved') {
      if (val === null) return CorrectiveActionApproval.PENDING;
      return val
        ? CorrectiveActionApproval.APPROVED
        : CorrectiveActionApproval.REJECTED;
    }
    if (column === 'solved') {
      return val ? FindingSolved.APPROVED : FindingSolved.PENDING;
    }
    if (column === 'completed') {
      if (val === null) return AuditStatus.PENDING;
      return val ? AuditStatus.COMPLETED : AuditStatus.INPROCESS;
    }
    if (val) {
      if (column === 'priority') {
        return this.enumControlPriority(val);
      }
      if (column === 'role') {
        return this.enumUserRol(val);
      }
      if (column === 'control') {
        return val.name;
      }
      if (column === 'fileUrl') {
        if (val != null) {
          return '';
        }
        return '-';
      }
      if (column === 'updatedBy') {
        const date = data['updatedAt'];
        const ret = val.firstName + ' ' + val.lastName + ` (${date})`;
        return ret;
      }
      if (column === 'createdBy') {
        const date = data['createdAt'];
        const ret = val.firstName + ' ' + val.lastName + ` (${date})`;
        return ret;
      }
      if (Array.isArray(val) && val.length >= 1) {
        if (column === 'controls') {
          const controls: any = [];
          val.forEach((element) => {
            controls.push(element.control.name);
          });
          return controls;
        }
        if (column === 'checklists') {
          const checklists: any = [];
          val.forEach((element) => {
            checklists.push(element.checklist.name);
          });
          return checklists;
        }
      }
      if (typeof val === 'object') {
        if (
          column === 'createdBy' ||
          column === 'updatedBy' ||
          column === 'responsible' ||
          column === 'auditor'
        ) {
          return `${val.firstName} ${val.lastName}`;
        }
        if (column === 'client') {
          return val.name;
        }

        if (column === 'audit') {
          return { code: val.code, id: val.id, name: val.name };
        }
        return val.name;
      }
      if (typeof val === 'string') {
        if (this.isIsoDate(val)) {
          return val?.slice(0, 10);
        }
        return val;
      }
      if (typeof val === 'number') {
        if (column === 'value') {
          return this.enumAuditResult(val);
        }
        return val;
      }
    } else if (val != null && typeof val === 'number') {
      return val;
    }
    return '-';
  }

  navigateToClientLocations(clientId: number) {
    return this.router.navigate([`${PAGES}/${CLIENTS}/${clientId}`]);
  }

  getAuditsUrl(id: number) {
    return `/${PAGES}/${AUDITS}/${id}`;
  }

  showFile(fileUrl: string) {
    window.open(fileUrl, '_blank');
  }
}
