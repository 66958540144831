import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';
import { AuditControl } from '../interfaces/audit-control.interface';
import { Service } from '../interfaces/service.interface';

@Injectable({
  providedIn: 'root',
})
export class AuditControlService extends BaseService {
  constructor(private http: HttpClient, private router: Router) {
    super();
  }

  create(data: any) {
    return this.http.post(`${this.url}/audit-control`, data);
  }

  read() {
    return this.http.get(`${this.url}/audit-control`);
  }

  readOne(id: number) {
    return this.http.get(`${this.url}/audit-control/${id}`);
  }

  readControls(auditId: number): Observable<AuditControl[]> {
    return this.http.get<AuditControl[]>(
      `${this.url}/audits/audit-controls/${auditId}`,
    );
  }

  readServices(auditId: number): Observable<Service[]> {
    return this.http.get<Service[]>(
      `${this.url}/audits/audit-services/${auditId}`,
    );
  }
  update(id: number, data: any) {
    return this.http.put(`${this.url}/audit-control/${id}`, data);
  }

  delete(id: number) {
    return this.http.delete(`${this.url}/audit-control/${id}`);
  }
}
