import { Component } from '@angular/core';
import { AuditableObject } from 'src/app/interfaces/auditable-object.interface';

@Component({
  selector: 'app-auditable-object-dashboard',
  templateUrl: './auditable-object-dashboard.component.html',
})
export class AuditableObjectDashboardComponent {
  genericList: AuditableObject[] = [];
  params: { limit: number } = { limit: 25 };
}
