import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class CheckListsTypeService extends BaseService {
  constructor(private http: HttpClient, private router: Router) {
    super();
  }
  create(data: any) {
    return this.http.post(`${this.url}/checklists-type`, data);
  }

  read() {
    return this.http.get(`${this.url}/checklists-type`);
  }

  readForTree() {
    return this.http.get(`${this.url}/checklists-type/tree`);
  }

  readOne(id: number) {
    return this.http.get(`${this.url}/checklists-type/${id}`);
  }

  readOneByName(name: string) {
    return this.http.get(`${this.url}/checklists-type/name/${name}`);
  }

  update(id: number, data: any) {
    return this.http.put(`${this.url}/checklists-type/${id}`, data);
  }

  delete(id: number) {
    return this.http.delete(`${this.url}/checklists-type/${id}`);
  }
}
