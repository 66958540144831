import {
  Component,
  Input,
  OnInit,
  NgModule,
  OnDestroy,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FindingsService } from 'src/app/services/findigs.service';
import { ResultsUpdateComponent } from 'src/app/modules/pages/audits/results/results-update/results-update.component';
import { IPaginatorEvent } from 'src/app/modules/common/table/table.component';
import { CommunicationService } from 'src/app/services/communication.service';
import { Subscription } from 'rxjs';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { AuthService } from 'src/app/services';
import { Roles } from 'src/app/enums/enums';
import { CorrectiveActionsCreateUpdateComponent } from '../corrective-actions/corrective-actions-create-update/corrective-actions-create-update.component';

@Component({
  selector: 'app-findings',
  templateUrl: './findings.component.html',
  styleUrls: ['./findings.component.scss'],
})
export class FindingsComponent implements OnInit, OnDestroy {
  columnsGeneral: string[] = [
    'Auditoría',
    'Control',
    'Tipo',
    'Objeto Auditable',
    'Auditor',
    'Descripción',
    'Días',
    'Solución',
    'Estado',
  ];
  columnsNamesGeneral: string[] = [
    'auditControl.auditChecklist.audit.name',
    'auditControl.control',
    'findingType',
    'auditControl.auditChecklist.audit.auditableObject',
    'auditControl.auditChecklist.audit.auditor',
    'description',
    'limit',
    'solution',
    'solved',
  ];
  columnsDetail: string[] = [
    'Control',
    'Tipo',
    'Descripción',
    'Días',
    'Solución',
    'Estado',
  ];
  columnsNamesDetail: string[] = [
    'auditControl.control',
    'findingType',
    'description',
    'limit',
    'solution',
    'solved',
  ];

  searchText: string = '';
  allFindingsList: any[] = [];
  findingsList: any = [];
  @Input() isAuditDetails: boolean;
  @Input() auditId: number;
  showLoading: boolean = false;
  total: number = 0;
  offset: number = 0;
  limit: number = 25;
  columns: string[] = [];
  columnsNames: string[] = [];
  resultsSubscription: Subscription;
  listSolved: boolean = false;
  showActionCol: boolean = true;

  constructor(
    private dialog: MatDialog,
    private findingsService: FindingsService,
    private communicationService: CommunicationService,
    private authService: AuthService,
  ) {}

  ngOnInit(): void {
    this.columns = this.isAuditDetails
      ? this.columnsDetail
      : this.columnsGeneral;
    this.columnsNames = this.isAuditDetails
      ? this.columnsNamesDetail
      : this.columnsNamesGeneral;
    this.read();
    this.resultsSubscription = this.communicationService
      .getFindingsObservable()
      .subscribe((next) => {
        if (next) {
          this.read();
        }
      });
    this.showActionButton();
  }

  ngOnDestroy() {
    this.resultsSubscription.unsubscribe();
  }

  handlePageChange(event: IPaginatorEvent) {
    this.offset = event.offset;
    this.limit = event.limit;
    this.read();
  }

  read() {
    const params = {
      offset: this.offset,
      limit: this.limit,
      param: this.searchText,
      listSolved: this.listSolved,
    };
    if (!this.auditId) {
      this.findingsService.read(params).subscribe((res: any) => {
        this.showLoading = false;
        this.total = res.total;
        this.findingsList = res.findings;
        this.allFindingsList = this.findingsList;
      });
    } else {
      this.findingsService
        .readByAudit(this.auditId, params)
        .subscribe((res: any) => {
          this.showLoading = false;
          this.total = res.total;
          this.findingsList = res.findings;
          this.allFindingsList = this.findingsList;
        });
    }
  }

  update(id: any) {
    this.findingsService.readOne(id).subscribe((res: any) => {
      const dialogRef = this.dialog.open(ResultsUpdateComponent, {
        data: { location: res, id },
        autoFocus: false,
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        this.read();
      });
    });
  }

  delete(id: any) {
    this.findingsService.delete(id).subscribe(() => {
      this.read();
    });
  }

  correctiveActions(id: number) {
    this.findingsService.readOne(id).subscribe((res: any) => {
      const dialogRef = this.dialog.open(
        CorrectiveActionsCreateUpdateComponent,
        {
          data: { finding: res, id },
          autoFocus: false,
          maxHeight: '90%',
          panelClass: 'modal-styles',
          minWidth: '30%',
        },
      );
      dialogRef.afterClosed().subscribe((result: any) => {
        this.communicationService.updateCorrectiveActions();
      });
    });
  }

  listSelected(ev: MatCheckboxChange) {
    if (ev.checked) {
      this.listSolved = true;
    } else if (!ev.checked) {
      this.listSolved = false;
    }
    this.read();
  }

  search(query: string) {
    this.searchText = query;
    this.showLoading = true;
    this.read();
  }

  showActionButton() {
    const user = this.authService.get();
    if (user.role === Roles.AUDITOR) {
      this.showActionCol = false;
    }
  }
}
