import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Control } from '../interfaces/control.interface';
import { IQuery, Location } from '../interfaces';
import { BaseService } from './base.service';
import { LocationsResponse } from '../interfaces/locations-response.interface';

@Injectable({
  providedIn: 'root',
})
export class LocationService extends BaseService {
  constructor(private http: HttpClient) {
    super();
  }

  create(data: any) {
    return this.http.post(`${this.url}/locations`, data);
  }

  read(params: IQuery = {}): Observable<LocationsResponse> {
    return this.http.get<LocationsResponse>(`${this.url}/locations`, {
      params,
    });
  }

  readByClientId(clientId: number, params: IQuery = {}): Observable<Location[]> {
    return this.http.get<Location[]>(
      `${this.url}/locations/client/${clientId}`,
      {
        params,
      }
    );
  }

  readOne(id: number) {
    return this.http.get(`${this.url}/locations/${id}`);
  }

  update(id: number, data: any) {
    return this.http.put(`${this.url}/locations/${id}`, data);
  }

  delete(id: number) {
    return this.http.delete(`${this.url}/locations/${id}`);
  }

  removeLocationFromClient(clientId: number, locationId: number) {
    return this.http.delete(
      `${this.url}/locations/${locationId}/client/${clientId}`
    );
  }
}
