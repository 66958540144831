<audit-modal
  title="{{
    isCreate ? 'Crear Tipo de Checklist' : 'Actualizar Tipo de Checklist'
  }}"
  (onClose)="cancel()"
>
  <form
    class="d-flex flex-column"
    [formGroup]="checkListsTypeForm"
    (ngSubmit)="submit()"
  >
    <mat-form-field appearance="outline">
      <mat-label>Nombre</mat-label>
      <input formControlName="name" matInput />
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Descripción</mat-label>
      <input formControlName="description" matInput />
    </mat-form-field>
    <button
      class="create-button"
      *ngIf="isCreate"
      type="submit"
      (click)="submit()"
    >
      Crear
    </button>
    <button
      class="create-button"
      *ngIf="!isCreate"
      type="submit"
      (click)="submit()"
    >
      Actualizar
    </button>
  </form>
</audit-modal>
