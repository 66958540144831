<div>
  <div class="d-flex justify-content-between mt-4">
    <div>
      <searcher
        [label]="'Buscar Servicios'"
        (onChange)="search($event)"
        [showLoading]="showLoading"
        [placeHolder]="'Buscar por nombre o descripción'"
      ></searcher>
    </div>
    <div>
      <button (click)="create()" class="create-button" *ngIf="canCreate">
        Crear Servicio
      </button>
    </div>
  </div>

  <app-table
    [data]="servicesList"
    [displayedColumns]="columns"
    [columnsNames]="columnsNames"
    (update)="update($event)"
    (delete)="delete($event)"
    (onPageChange)="handlePageChange($event)"
    [total]="total"
    [offset]="offset"
    [limit]="limit"
  ></app-table>
</div>
