<audit-modal
  title="{{ !checklist ? 'Crear Checklist' : 'Actualizar Checklist' }}"
>
  <form class="main-form d-flex flex-column" [formGroup]="checkListForm">
    <div class="flex">
      <a-input label="Nombre">
        <mat-form-field appearance="outline">
          <input formControlName="name" matInput placeholder="Nombre" />
        </mat-form-field>
      </a-input>

      <a-input label="Tipo de Checklist">
        <mat-form-field appearance="outline">
          <mat-select
            formControlName="checklistTypeId"
            placeholder="Seleccione tipo de checklist"
          >
            <mat-option
              *ngFor="let checkListType of checkListTypesList"
              [value]="checkListType.id"
              >{{ checkListType.name }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </a-input>
    </div>
    <a-input label="Descripción">
      <mat-form-field appearance="outline">
        <textarea
          formControlName="description"
          matInput
          placeholder="Descripción"
        ></textarea>
      </mat-form-field>
    </a-input>

    <div class="flex">
      <div class="autocomplete">
        <controls-finder
          (onSelect)="selectControl($event)"
          (onDeselect)="selectControl($event)"
          [selected]="selectedMap"
        ></controls-finder>
      </div>
      <div>
        <h2 class="order-title">Seleccionados</h2>
        <div
          cdkDropList
          [cdkDropListData]="dragableList"
          class="dragable-list"
          (cdkDropListDropped)="drop($event)"
        >
          <div
            class="dragable-box"
            *ngFor="let control of dragableList"
            cdkDrag
            [cdkDragData]="control"
          >
            <mat-icon class="icon" style="display: table; cursor: move"
              >drag_indicator</mat-icon
            >
            <div class="control-name">{{ control.name }}</div>

            <mat-icon
              class="icon"
              (click)="removeControl(control)"
              style="display: table"
              >close</mat-icon
            >
          </div>
        </div>
      </div>
    </div>
    <div class="button-container">
      <button class="close-button" (click)="cancel()">Cancelar</button>
      <button
        [ngClass]="!checkListForm.valid ? 'disabled-button' : 'create-button'"
        [disabled]="!checkListForm.valid"
        *ngIf="!checklist"
        (click)="submit()"
        type="submit"
      >
        Crear
      </button>
      <button
        [ngClass]="!checkListForm.valid ? 'disabled-button' : 'create-button'"
        [disabled]="!checkListForm.valid"
        class="create-button"
        *ngIf="checklist"
        (click)="submit()"
        type="submit"
      >
        Actualizar
      </button>
    </div>
  </form>
</audit-modal>
