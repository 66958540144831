import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthService } from '../services';
import { AUTH } from '../utils/urls';

@Injectable()
export default class AuthenticationGuard {
  constructor(
    private router: Router,
    private service: AuthService,
  ) {}

  canActivate(): Observable<boolean> | boolean {
    const token = this.service.getToken();
    if (token) {
      return this.service.checkAuth().pipe(
        map((isAuth: boolean): boolean => {
          if (!isAuth) {
            this.router.navigate([AUTH]);
            return false;
          }
          return true;
        }),
        catchError(
          (err: any, caught: Observable<boolean>): Observable<boolean> => {
            this.router.navigate([AUTH]);
            return new Observable<boolean>();
          },
        ),
      );
    }

    this.router.navigate([AUTH]);
    return false;
  }
}
