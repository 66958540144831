import { Component, Inject, OnInit } from '@angular/core';
import { MatSnackBar, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss'],
})
export class SnackbarComponent implements OnInit {
  message!: string;

  view!: string;

  icon!: string;

  content!: string;

  constructor(
    private snackBar: MatSnackBar,
    @Inject(MAT_SNACK_BAR_DATA) public data: Record<string, string>,
  ) {}

  ngOnInit(): void {
    const { message, view, content } = this.data;
    this.message = message;
    this.view = view;
    this.content = content;
    this.setIcon();
  }

  setIcon(): void {
    switch (this.view) {
      case 'success':
        this.icon = 'task_alt';
        break;
      case 'error':
        this.icon = 'error_outline';
        break;
      case 'warn':
        this.icon = 'warning';
        break;
      case 'info':
        this.icon = 'info';
        break;
      default:
        break;
    }
  }
}
