import {
  Component,
  EventEmitter,
  Inject,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GoogleMapsComponent } from 'src/app/modules/common/google-maps/google-maps-component/google-maps.component';
import { AlertsService } from 'src/app/services/alerts.service';
import { ClientsService } from 'src/app/services/clients.service';
import { LocationService } from 'src/app/services/locations.service';
import { LocationsComponent } from '../locations.component';

@Component({
  selector: 'app-locations-create-update',
  templateUrl: './locations-create-update.component.html',
  styleUrls: ['./locations-create-update.component.scss'],
})
export class LocationsCreateUpdateComponent implements OnInit {
  @Output('onCancel') onCancel = new EventEmitter();
  locationForm = new FormGroup({
    name: new FormControl('', Validators.required),
    description: new FormControl(''),
    latitude: new FormControl(),
    longitude: new FormControl(),
    address: new FormControl(),
    clientId: new FormControl('', Validators.required),
  });
  isCreate = true;
  clientsList: any = [];
  selectedClient = false;
  private isSubmitting = false;

  @ViewChild('mapsComponent') mapsComponent: GoogleMapsComponent;

  constructor(
    private locationsService: LocationService,
    private clientService: ClientsService,
    public dialogRef: MatDialogRef<LocationsComponent>,
    private alertsService: AlertsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit(): void {
    this.clientService.read().subscribe((res: any) => {
      this.clientsList = res.clients;
    });
    if (this.data?.client) {
      this.selectedClient = true;
      this.locationForm.patchValue({
        clientId: this.data.client.id,
      });
    }

    if (this.data?.location) {
      this.isCreate = false;
      this.locationForm.patchValue({
        name: this.data.location.name,
        address: this.data.location.address,
        description: this.data.location.description,
        latitude: this.data.location.latitude,
        longitude: this.data.location.longitude,
        clientId: this.data.location.clientLocations[0].clientId,
      });
    }
  }

  cancel() {
    this.dialogRef.close();
  }

  submit(): void {
    if (this.isSubmitting) {
      return;
    }
    this.isSubmitting = true;

    if (this.data?.location) {
      this.update();
    } else {
      this.create();
    }
  }

  create() {
    const { lat, lng, address } = this.mapsComponent.getFormValues();
    this.locationForm.patchValue({
      latitude: lat,
      longitude: lng,
      address,
    });
    this.locationsService
      .create(this.locationForm.value)
      .subscribe({
        next: () => {
          this.dialogRef.close();
          this.alertsService.success('Locación creada con éxito');
        },
        error: (err) => {
          console.log(err);
          this.alertsService.error('Error al crear locación');
        },
      })
      .add(() => {
        this.isSubmitting = false;
      });
  }

  update() {
    const { lat, lng, address } = this.mapsComponent.getFormValues();
    this.locationForm.patchValue({
      latitude: lat,
      longitude: lng,
      address,
    });
    this.locationsService
      .update(this.data.id, this.locationForm.value)
      .subscribe({
        next: () => {
          this.dialogRef.close();
          this.alertsService.success('Locación actualizada con éxito');
        },
        error: (err) => {
          console.log(err);
          this.alertsService.error('Error al actualizar locación');
        },
      })
      .add(() => {
        this.isSubmitting = false;
      });
  }
}
