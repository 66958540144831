<audit-modal
  title="{{
    isCreate ? 'Crear Objeto Auditable' : 'Actualizar Objeto Auditable'
  }}"
>
  <form class="d-flex flex-column" [formGroup]="auditableObjectForm">
    <a-input label="Nombre">
      <mat-form-field appearance="outline">
        <input formControlName="name" matInput placeholder="Nombre" />
      </mat-form-field>
    </a-input>

    <a-input label="Auditado">
      <mat-form-field appearance="outline">
        <mat-select
          formControlName="clientId"
          placeholder="Seleccione auditado"
        >
          <mat-option [value]="option.id" *ngFor="let option of auditedList">
            {{ option.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </a-input>

    <a-input label="Descripción">
      <mat-form-field appearance="outline">
        <textarea
          formControlName="description"
          matInput
          placeholder="Descripción"
        ></textarea>
      </mat-form-field>
    </a-input>

    <!-- <input type="file" [accept]="validFileFormat" (change)="onSelectFile($event.target)" #file hidden>
          <div class="actions">
            <button class="common-button" mat-raised-button type="button" (click)="file.click()">
              <mat-icon>attach_file</mat-icon>Seleccione imagen
            </button> 
            <button *ngIf="image || (!isCreate && preview)" mat-mini-fab color="basic" (click)="onRemoveFile()">
              <mat-icon>clear</mat-icon>
            </button>
          </div>
          <div *ngIf="isCreate && image || !isCreate && preview" class="image-div">
            <img class="image-preview" [src]="preview">
          </div> -->

    <div class="button-container">
      <button class="close-button" (click)="cancel()">Cancelar</button>
      <button
        [ngClass]="
          !auditableObjectForm.valid ? 'disabled-button' : 'create-button'
        "
        [disabled]="!auditableObjectForm.valid"
        *ngIf="isCreate"
        (click)="submit()"
        type="submit"
      >
        Crear
      </button>
      <button
        [ngClass]="
          !auditableObjectForm.valid ? 'disabled-button' : 'create-button'
        "
        [disabled]="!auditableObjectForm.valid"
        *ngIf="!isCreate"
        (click)="submit()"
        type="submit"
      >
        Actualizar
      </button>
    </div>
  </form>
</audit-modal>
