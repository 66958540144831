<audit-modal title="{{data?.vehicle ? 'Editar vehículo' : 'Crear vehículo'}}">
    <form [formGroup]="vehicleForm">

        <a-input label="Nombre">
            <mat-form-field appearance="outline">
                <input formControlName="name" matInput placeholder="Nombre">
            </mat-form-field>
        </a-input>

        <a-input label="Matrícula">
            <mat-form-field appearance="outline">
                <input formControlName="license" matInput placeholder="Matrícula">
            </mat-form-field>
        </a-input>

        <a-input label="Descripción">
            <mat-form-field appearance="outline">
                <input formControlName="description" matInput placeholder="Descripción">
            </mat-form-field>
        </a-input>
        <div class="button-container">
            <button class="close-button" (click)="cancel()">Cancelar</button>
            <button [ngClass]="!vehicleForm.valid ? 'disabled-button' : 'create-button'" [disabled]="!vehicleForm.valid" (click)="submit()">{{data?.vehicle ? 'Editar':'Crear'}}</button>
        </div>
    </form>

</audit-modal>