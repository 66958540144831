import { Component, Input } from '@angular/core';
import { CorrectiveActionsApprovalComponent } from '../../audits/corrective-actions/corrective-actions-approval/corrective-actions-approval.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CorrectiveActionsService } from 'src/app/services/corrective-actions.service';
import { AUDITS, PAGES } from 'src/app/utils/urls';

@Component({
  selector: 'app-corrective-actions-dashboard',
  templateUrl: './corrective-actions.component.html',
  styleUrls: ['./corrective-actions.component.scss']
})
export class CorrectiveActionsDashboardComponent {
  @Input('correctiveAction') correctiveAction: any;
  @Input('isExpired') isExpired: boolean = false;
  @Input('isClient') isClient: boolean = false;
  @Input('rejected') rejected: boolean = false;

  constructor(
    private correctiveActionsService: CorrectiveActionsService,
    private dialog: MatDialog,
    private router: Router,
  ) { }

  onAuditCode() {
    return `/${PAGES}/${AUDITS}/${this.correctiveAction.auditId}`;
  }

  evaluate() {
    this.correctiveActionsService.readOne(this.correctiveAction.id).subscribe((res: any) => {
      this.dialog.open(
        CorrectiveActionsApprovalComponent,
        {
          data: { correctiveAction: res, id: this.correctiveAction.id },
        },
      );
    });
  }

  seeMore(){
    this.router.navigate([`/${PAGES}/${AUDITS}/${this.correctiveAction.auditId}`], { queryParams: { tab: 'corrective-actions' } });
  }
}
