import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';
import { AuditableObject } from '../interfaces/auditable-object.interface';
import { IQuery } from '../interfaces';
import { AuditableObjectResponse } from '../interfaces/auditable-object-response.interface';

@Injectable({
  providedIn: 'root',
})
export class AuditableObjectService extends BaseService {
  constructor(private http: HttpClient, private router: Router) {
    super();
  }

  create(data: any & {image: File}): Observable<any> {
    // const form = this.form(data);
    return this.http.post<any>(`${this.url}/auditable-object`, data);
  }

  read(params: IQuery = {}): Observable<AuditableObjectResponse> {
    return this.http.get<AuditableObjectResponse>(`${this.url}/auditable-object`, {
      params,
    });
  }

  readOne(id: number): Observable<any> {
    return this.http.get<any>(`${this.url}/auditable-object/${id}`, this.options());
  }

  update(id: number, data: any & {image: File}): Observable<any> {
    const form = this.form(data);
    return this.http.put<any>(`${this.url}/auditable-object/${id}`, form);
  }

  delete(id: number) {
    return this.http.delete(`${this.url}/auditable-object/${id}`, this.options());
  }

  readByClientId(clientId: number) {
    return this.http.get(`${this.url}/auditable-object/client/${clientId}`, this.options());
  }

  private form({ image, ...ao }: AuditableObject & { image: File }): FormData {
    const form = new FormData();
    Object.entries(ao).forEach(([key, value]) => form.append(key, value as any));
    if (image) {
      form.append('image', image);
    }
    return form;
  }
}
