import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-audit-dashboard',
  templateUrl: './audit-dashboard.component.html',
})
export class AuditDashboardComponent implements OnInit{
  // This list is used for all child components, change with matTabSelected
  genericList: any = [];
  params: { limit: number } = { limit: 25 };
  selectedIndex: number = 0;

  constructor(
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (params['tab'] === 'findings') {
        this.selectedIndex = 1;
      } else if (params['tab'] === 'corrective-actions') {
        this.selectedIndex = 2;
      } else {
        this.selectedIndex = 0;
      }
    });
  }
}
