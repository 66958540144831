import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CorrectiveActionsService } from 'src/app/services/corrective-actions.service';
import { IPaginatorEvent } from 'src/app/modules/common/table/table.component';
import { DateTransformService } from 'src/app/services/date-transform.service';
import { CommunicationService } from 'src/app/services/communication.service';
import { Subscription } from 'rxjs';
import { MatSelect } from '@angular/material/select';
import { AuthService } from 'src/app/services';
import { Roles } from 'src/app/enums/enums';
import { CorrectiveActionsCreateUpdateComponent } from './corrective-actions-create-update/corrective-actions-create-update.component';
import { CorrectiveActionsApprovalComponent } from './corrective-actions-approval/corrective-actions-approval.component';

@Component({
  selector: 'app-corrective-actions',
  templateUrl: './corrective-actions.component.html',
  styleUrls: ['./corrective-actions.component.scss'],
})
export class CorrectiveActionsComponent implements OnInit, OnDestroy {
  @ViewChild(MatSelect) statusSelect: MatSelect;

  columns: string[] = [
    'Auditoría',
    'Hallazgo',
    'Control',
    'Descripción de la acción',
    'Creado por',
    'Modificado por',
    'Evidencia',
    'Estado',
  ];
  columnsNames: string[] = [
    'finding.auditControl.auditChecklist.audit.name',
    'finding',
    'finding.auditControl.control.name',
    'description',
    'createdBy',
    'updatedBy',
    'fileUrl',
    'approved',
  ];
  searchText = '';
  allCorrectiveActionsList: any[] = [];
  correctiveActionsList: any = [];
  @Input() isAuditDetails: boolean;
  @Input() auditId: number;
  showLoading = false;
  total = 0;
  offset = 0;
  limit = 25;
  resultsSubscription: Subscription;
  statusFilter = '';
  showActionCol = true;

  constructor(
    private dialog: MatDialog,
    private correctiveActionsService: CorrectiveActionsService,
    private dateTransformService: DateTransformService,
    private communicationService: CommunicationService,
    private authService: AuthService,
  ) {}

  ngOnInit(): void {
    if (this.isAuditDetails) {
      this.columns.shift();
      this.columnsNames.shift();
    }
    this.read();
    this.resultsSubscription = this.communicationService
      .getCorrectiveActionsObservable()
      .subscribe((next) => {
        if (next) {
          this.read();
        }
      });
    this.showActionButton();
  }

  ngOnDestroy() {
    this.resultsSubscription.unsubscribe();
  }

  handlePageChange(event: IPaginatorEvent) {
    this.offset = event.offset;
    this.limit = event.limit;
    this.read();
  }

  read() {
    const params = {
      offset: this.offset,
      limit: this.limit,
      param: this.searchText,
      status: this.statusFilter,
    };
    if (!this.auditId) {
      this.correctiveActionsService.read(params).subscribe((res: any) => {
        this.showLoading = false;
        this.total = res.total;
        this.correctiveActionsList = this.dateTransformService.transformDates(
          res.correctiveActions,
        );
        this.allCorrectiveActionsList = this.correctiveActionsList;
      });
    } else {
      this.correctiveActionsService
        .readByAudit(this.auditId, params)
        .subscribe((res: any) => {
          this.showLoading = false;
          this.total = res.total;
          this.correctiveActionsList = this.dateTransformService.transformDates(
            res.correctiveActions,
          );
          this.allCorrectiveActionsList = this.correctiveActionsList;
        });
    }
  }

  update(id: any) {
    this.correctiveActionsService.readOne(id).subscribe((res: any) => {
      const dialogRef = this.dialog.open(
        CorrectiveActionsCreateUpdateComponent,
        {
          data: {
            res,
            id,
            isAuditDetails: this.isAuditDetails,
            auditId: this.auditId,
            autoFocus: false,
          },
        },
      );
      dialogRef.afterClosed().subscribe((result: any) => {
        this.read();
      });
    });
  }

  delete(id: number) {
    this.correctiveActionsService.delete(id).subscribe(() => {
      this.read();
    });
  }

  create() {
    const dialogRef = this.dialog.open(CorrectiveActionsCreateUpdateComponent, {
      autoFocus: false,
      maxHeight: '90%',
      panelClass: 'modal-styles',
      minWidth: '30%',
    });
    dialogRef.afterClosed().subscribe(() => {
      this.read();
    });
  }

  applyFilter() {
    const filterValue = this.searchText.toLowerCase();
    if (filterValue === '') {
      this.read();
    } else {
      const keywords = filterValue.split(' ');
      this.correctiveActionsList = this.allCorrectiveActionsList.filter(
        (item: any) =>
          keywords.every((keyword) =>
            item.name.toLowerCase().includes(keyword),
          ),
      );
    }
  }

  search(query: string) {
    this.searchText = query;
    this.showLoading = true;
    this.read();
  }

  approval(id: number) {
    this.correctiveActionsService.readOne(id).subscribe((res: any) => {
      const dialogRef = this.dialog.open(CorrectiveActionsApprovalComponent, {
        data: { correctiveAction: res, id },
        autoFocus: false,
        maxHeight: '90%',
        panelClass: 'modal-styles',
        minWidth: '30%',
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        this.read();
      });
    });
  }

  onStatusChange() {
    this.statusFilter = this.statusSelect.value;
    this.read();
  }

  showActionButton() {
    const user = this.authService.get();
    if (user.role === Roles.AUDITOR || user.role === Roles.CLIENT) {
      this.showActionCol = false;
    }
  }
}
