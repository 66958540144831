import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { IPaginatorEvent } from 'src/app/modules/common/table/table.component';
import { Audit } from 'src/app/interfaces/audit.interface';
import { AlertsService } from 'src/app/services/alerts.service';
import { AuditsService } from 'src/app/services/audits.service';
import { DateTransformService } from 'src/app/services/date-transform.service';
import { indexOf } from 'lodash';
import { Roles } from 'src/app/enums/enums';
import { AuthService } from 'src/app/services';
import { AuditsCreateUpdateComponent } from './audits-create-update/audits-create-update.component';

@Component({
  selector: 'app-audits',
  templateUrl: './audits.component.html',
  styleUrls: ['./audits.component.scss'],
})
export class AuditsComponent implements OnInit {
  @ViewChild(MatSelect) statusSelect: MatSelect;
  columns: string[] = [
    'Nombre',
    'Código',
    'Auditado',
    'Auditor',
    'Fecha',
    'Objeto Auditado',
    'Locación',
    'Estado',
  ];
  columnsNames: string[] = [
    'name',
    'code',
    'client',
    'auditor',
    'date',
    'auditableObject',
    'location',
    'completed',
  ];
  searchText = '';
  statusFilter = '';
  showLoading = false;
  allAuditsList: Audit[] = [];
  auditsList: Audit[] = [];
  total = 0;
  offset = 0;
  limit = 25;
  textAudit = 'Explica cómo crear una auditoría.';
  columnName = '';
  desc = true;
  showActionCol = true;

  constructor(
    private auditService: AuditsService,
    private alertsService: AlertsService,
    private dialog: MatDialog,
    private dateTransformService: DateTransformService,
    private authService: AuthService,
  ) {}

  ngOnInit(): void {
    this.read();
    this.showActionButton();
  }

  handlePageChange(event: IPaginatorEvent) {
    this.offset = event.offset;
    this.limit = event.limit;
    this.read();
  }

  read() {
    const params = {
      offset: this.offset,
      limit: this.limit,
      param: this.searchText,
      status: this.statusFilter,
      columnName: this.columnName,
      desc: this.desc,
    };
    this.auditService.read(params).subscribe((res: any) => {
      this.showLoading = false;
      this.total = res.total;
      this.auditsList = this.dateTransformService.transformDates(res.audits);
    });
  }

  update(id: number) {
    const params = {
      details: false,
    };
    this.auditService.readOne(id, params).subscribe((res) => {
      const dialogRef = this.dialog.open(AuditsCreateUpdateComponent, {
        data: { res },
        autoFocus: false,
        maxHeight: '90%',
      });
      dialogRef.afterClosed().subscribe(() => this.read());
    });
  }

  duplicate(id: number) {
    this.auditService.readOne(id).subscribe((res) => {
      const dialogRef = this.dialog.open(AuditsCreateUpdateComponent, {
        data: { res, isCopy: true },
        autoFocus: false,
        maxHeight: '90%',
      });
      dialogRef.afterClosed().subscribe(() => this.read());
    });
  }

  create() {
    const dialogRef = this.dialog.open(AuditsCreateUpdateComponent, {
      autoFocus: false,
      disableClose: true,
      maxHeight: '90%',
      panelClass: 'modal-styles',
    });
    dialogRef.afterClosed().subscribe(() => this.read());
  }

  async delete(id: number) {
    const confirmed = await this.alertsService.confirm(
      '¿Confirma la eliminación de esta auditoría?',
    );
    if (confirmed) {
      this.auditService.delete(id).subscribe({
        next: () => {
          this.alertsService.success('Auditoría eliminada con éxito');
          this.read();
        },
        error: (error) => {
          console.log(error);
          this.alertsService.error('Error al eliminar la auditoría');
        },
      });
    }
  }

  search(query: string) {
    this.searchText = query;
    this.showLoading = true;
    this.read();
  }

  onStatusChange() {
    this.statusFilter = this.statusSelect.value;
    this.read();
  }

  sort(name: string) {
    const i = indexOf(this.columns, name);

    if (this.columnName === this.columnsNames[i]) {
      if (this.desc) {
        this.desc = false;
      } else {
        this.desc = true;
      }
    } else {
      this.desc = true;
    }
    this.columnName = this.columnsNames[i];
    this.read();
  }

  showActionButton() {
    const user = this.authService.get();
    if (user.role === Roles.CLIENT) {
      this.showActionCol = false;
    }
  }
}
