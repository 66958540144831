<audit-modal title="{{ isCreate ? 'Crear Auditado' : 'Actualizar Auditado' }}">
  <form class="form-container" [formGroup]="clientForm">
    <a-input label="Nombre">
      <mat-form-field appearance="outline">
        <input formControlName="name" matInput placeholder="Nombre" />
      </mat-form-field>
    </a-input>

    <a-input label="Rut">
      <mat-form-field appearance="outline">
        <input formControlName="rut" matInput placeholder="Rut" />
      </mat-form-field>
    </a-input>

    <a-input label="Email">
      <mat-form-field appearance="outline">
        <input formControlName="email" matInput placeholder="Email" />
      </mat-form-field>
    </a-input>

    <a-input label="Telefono">
      <mat-form-field appearance="outline">
        <input formControlName="phone" matInput placeholder="Telefono" />
      </mat-form-field>
    </a-input>

    <a-input label="Dirección">
      <mat-form-field appearance="outline">
        <input formControlName="address" matInput placeholder="Dirección" />
      </mat-form-field>
    </a-input>

    <a-input label="Servicios">
      <mat-form-field appearance="outline">
        <mat-select
          multiple
          formControlName="serviceIds"
          placeholder="Seleccione servicios"
        >
          <mat-option *ngFor="let option of serviceList" [value]="option.id">
            {{ option.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </a-input>

    <a-input label="Administradores">
      <mat-form-field appearance="outline">
        <mat-select
          multiple
          formControlName="adminIds"
          placeholder="Seleccione administradores"
        >
          <mat-option *ngFor="let option of adminList" [value]="option.id">
            {{ option.firstName }} {{ option.lastName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </a-input>
  </form>
  <div class="button-container">
    <button class="close-button" (click)="cancel()">Cancelar</button>
    <button
      [ngClass]="!clientForm.valid ? 'disabled-button' : 'create-button'"
      [disabled]="!clientForm.valid"
      *ngIf="isCreate"
      type="submit"
      (click)="submit()"
    >
      Crear
    </button>
    <button
      [ngClass]="!clientForm.valid ? 'disabled-button' : 'create-button'"
      [disabled]="!clientForm.valid"
      *ngIf="!isCreate"
      type="submit"
      (click)="submit()"
    >
      Actualizar
    </button>
  </div>
</audit-modal>
