import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from 'src/app/interfaces';
import { AuthService } from 'src/app/services';

@Component({
  selector: 'app-admin-toast',
  templateUrl: './admin-toast.component.html',
  styleUrls: ['./admin-toast.component.scss'],
})
export class AdminToastComponent implements OnInit {
  companyName: string | undefined;
  userLogged: User;
  isSuperAdmin = false;

  constructor(
    private auth: AuthService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.userLogged = this.auth.get();
    this.companyName = this.userLogged.selectedCompany?.name;
    if (this.userLogged.role === 1) {
      this.isSuperAdmin = true;
    }
  }

  changeCompany() {
    this.router.navigate(['/admin/companies']);
  }
}
