<mat-card class="list-card">
        <span class="audit-code" [routerLink]="onAuditCode()">{{ correctiveAction.auditCode }}</span>
        <span class="audit-audited" *ngIf="!isClient"><mat-icon>work_outline</mat-icon>{{ correctiveAction.audited
                }}</span>
        <span class="audit-audited"><mat-icon fontSet="material-icons-outlined">check_box</mat-icon>{{
                correctiveAction.control
                }}</span>
        <span class="finding-description"><mat-icon fontSet="material-icons-outlined">error_outline</mat-icon><span
                        class="finding-description-text">{{ correctiveAction.description }}</span></span>
        <span [ngClass]="{'rejected-description': rejected, 'finding-description': !rejected}"><mat-icon
                        fontSet="material-icons-outlined">check_circle</mat-icon><span
                        [ngClass]="{'rejected-description': rejected, 'finding-description-text': !rejected}">{{
                        correctiveAction.solution }}</span></span>
        <button class="evaluate-button" *ngIf="!isClient" (click)="evaluate()">Evaluar</button>
        <!-- <a class="see-more-finding" *ngIf="isClient" (click)="seeMore()">Ver más <mat-icon>arrow_drop_down</mat-icon></a> -->
</mat-card>