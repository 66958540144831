<div *ngIf="data">
    <h1 class="title-component">
        {{data.name}} - {{data.code}}
    </h1>

    <p>
        Observaciones: {{data.observations}}
    </p>
    <p>
        Reporte: {{data.report}}
    </p>
    <p>
        Responsable: {{data.responsible}}
    </p>
    <p>
        Fecha: {{data.date}}
    </p>
    <p>
        Vencimineto: {{data.expirationDate}}
    </p>
    <p>
        Completa: {{data.completed}}
    </p>
    <h2>
        Auditor
    </h2>
    <p>
        Nombre: {{data.auditor.firstName}}  {{data.auditor.lastName}} 
    </p>
    <p>
        Email: {{data.auditor.email}}
    </p>

    <h2>

    </h2>
</div>