<div class="form-container">
  <h4>{{ isCreate ? 'Crear Compañía' : 'Actualizar Compañía' }}</h4>
  <form class="d-flex flex-column" [formGroup]="companyForm">
    <mat-form-field appearance="outline">
      <mat-label>Nombre</mat-label>
      <input formControlName="name" matInput />
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Rut</mat-label>
      <input formControlName="rut" matInput />
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Telefono</mat-label>
      <input formControlName="phone" matInput />
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Dirección</mat-label>
      <input formControlName="address" matInput />
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Logo</mat-label>
      <input formControlName="companyLogo" matInput />
    </mat-form-field>
    <!-- <mat-form-field appearance="outline">
      <mat-label>Color primario</mat-label>
      <div class="input-color-container">
        <input
          formControlName="primaryColor"
          matInput
          type="color"
          class="input-color"
          #colorPicker
        />
        <span mat-button (click)="colorPicker.click()" class="clickable-text"
          >Cambiar</span
        >
      </div>
    </mat-form-field> -->

    <div class="d-flex justify-content-between">
      <button class="close-button" (click)="closeForm()">Cerrar</button>
      <button
        class="create-button"
        *ngIf="isCreate"
        (click)="submit()"
        type="submit"
      >
        Crear
      </button>
      <button
        class="create-button"
        *ngIf="!isCreate"
        (click)="submit()"
        type="submit"
      >
        Actualizar
      </button>
    </div>
  </form>
</div>
