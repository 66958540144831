import {
  Component, EventEmitter, Input, Output,
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { HeaderComponent } from '../header/header.component';

@Component({
  selector: 'app-help-modal',
  templateUrl: './help-modal.component.html',
  styleUrls: ['./help-modal.component.scss'],
})
export class HelpModalComponent {
  @Input('closeButton') closeButton: boolean = true;

  @Output('onClose') onClose = new EventEmitter();

  constructor(private dialogRef: MatDialogRef<HeaderComponent>) {}

  onCloseClick() {
    this.dialogRef.close();
  }
}
