<audit-modal title="{{ control.name }}">
  <div class="d-flex flex-column">
    <form class="d-flex flex-column" [formGroup]="controlForm">
      <mat-form-field appearance="outline">
        <mat-label>Nombre</mat-label>
        <input formControlName="name" matInput readonly />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Descripción</mat-label>
        <input formControlName="description" matInput readonly />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Requerimiento</mat-label>
        <input formControlName="requirement" matInput readonly />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Prioridad</mat-label>
        <input formControlName="priority" matInput readonly />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Parar actividad</mat-label>
        <input formControlName="stopActivity" matInput readonly />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Días recomendados</mat-label>
        <input formControlName="recommendedDays" matInput readonly />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Puntos de penalización</mat-label>
        <input formControlName="penaltyPoints" matInput readonly />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Creado</mat-label>
        <input formControlName="createdAt" matInput readonly />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Creado por</mat-label>
        <input formControlName="createdBy" matInput readonly />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Modificado</mat-label>
        <input formControlName="updatedAt" matInput readonly />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Modificado por</mat-label>
        <input formControlName="updatedBy" matInput readonly />
      </mat-form-field>
      <div class="button-only">
        <button class="close-button" (click)="cancel()">Cancelar</button>
      </div>
    </form>
  </div>
</audit-modal>
