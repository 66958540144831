import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Client } from 'src/app/interfaces/client.interface';
import { IPaginatorEvent } from 'src/app/modules/common/table/table.component';
import { AlertsService } from 'src/app/services/alerts.service';
import { ClientsService } from 'src/app/services/clients.service';
import { ClientsCreateUpdateComponent } from './clients-create-update/clients-create-update.component';
import { AuthService } from 'src/app/services';
import { Roles } from 'src/app/enums/enums';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss'],
})
export class ClientsComponent {
  columns: string[] = [
    'Nombre',
    'Rut',
    'Email',
    'Telefono',
    'Direccion',
    'Locaciones',
  ];
  columnsNames: string[] = [
    'name',
    'rut',
    'email',
    'phone',
    'address',
    'locations',
  ];
  searchText = '';
  allClientsList: Client[] = [];
  @Input() clientList: Client[] = [];
  total = 0;
  offset = 0;
  limit = 25;
  showLoading = false;
  canCreate = false;

  constructor(
    private clientService: ClientsService,
    private dialog: MatDialog,
    private alertsService: AlertsService,
    private auth: AuthService,
  ) {}

  ngOnInit(): void {
    const userRole = this.auth.get().role;
    if (userRole == Roles.SUPER_ADMIN || userRole == Roles.ADMIN) {
      this.canCreate = true;
    }
    this.read();
  }

  handlePageChange(event: IPaginatorEvent) {
    this.offset = event.offset;
    this.limit = event.limit;
    this.read();
  }

  read() {
    const params = {
      offset: this.offset,
      limit: this.limit,
      param: this.searchText,
    };
    this.clientService.read(params).subscribe((res: any) => {
      this.showLoading = false;
      this.total = res.total;
      this.clientList = res.clients;
      this.allClientsList = this.clientList;
    });
  }

  update(id: number) {
    this.clientService.readOne(id).subscribe((res) => {
      const dialogRef = this.dialog.open(ClientsCreateUpdateComponent, {
        data: { client: res, id },
        autoFocus: false,
        maxHeight: '90%',
        panelClass: 'modal-styles',
        minWidth: '30%',
      });
      dialogRef.afterClosed().subscribe(() => {
        this.read();
      });
    });
  }

  create() {
    const dialogRef = this.dialog.open(ClientsCreateUpdateComponent, {
      autoFocus: false,
      maxHeight: '90%',
      panelClass: 'modal-styles',
      minWidth: '30%',
    });
    dialogRef.afterClosed().subscribe(() => {
      this.read();
    });
  }

  async delete(id: number) {
    const confirmed = await this.alertsService.confirm(
      '¿Confirma la eliminación de este auditado?',
    );
    if (confirmed) {
      this.clientService.delete(id).subscribe({
        next: () => {
          this.alertsService.success('Auditado eliminado exitosamente');
          this.read();
        },
        error: (error) => {
          this.alertsService.error('Error al eliminar auditado');
          console.log(error);
        },
      });
    }
  }

  search(query: string) {
    this.searchText = query;
    this.showLoading = true;
    this.read();
  }
}
