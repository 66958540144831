<audit-modal title="Aprobar Acción Correctiva" (onClose)="cancel()">
  <form class="d-flex flex-column main-form" [formGroup]="correctiveActionForm">
    <div class="static-div">
      <p class="bold bg margin-right">Auditoría:</p>
      <p>{{ auditName }}</p>
    </div>
    <div class="static-div">
      <p class="bold bg margin-right">Hallazgo:</p>
      <p>{{ findingDescription }}</p>
    </div>
    <div class="static-div">
      <p class="bold bg margin-right">Descripción:</p>
      <p>{{ description }}</p>
    </div>
    <a-input label="Observaciones">
      <mat-form-field appearance="outline">
        <textarea
          formControlName="observations"
          matInput
          placeholder="Ingrese sus observaciones"
        ></textarea>
      </mat-form-field>
    </a-input>
    <div class="d-flex justify-content-between">
      <div>
        <button
          class="create-button reject-button"
          (click)="approve(false)"
          type="submit"
        >
          Rechazar
        </button>
      </div>
      <div>
        <button
          class="create-button approve-button"
          (click)="approve(true)"
          type="submit"
        >
          Aprobar
        </button>
      </div>
    </div>
  </form>
</audit-modal>
