import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Audit } from 'src/app/interfaces/audit.interface';

@Component({
  selector: 'app-audit-view',
  templateUrl: './audit-view.component.html',
  styleUrls: ['./audit-view.component.scss'],
})
export class AuditViewComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: Audit) {}
}
