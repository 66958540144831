import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { User } from '../interfaces';
import { AuthService } from '../services';
import { AUTH, LOGIN } from '../utils/urls';

@Injectable()
export default class AuthorizationGuard {
  constructor(
    private service: AuthService,
    private router: Router,
  ) {}

  canActivateChild(route: ActivatedRouteSnapshot) {
    const { roles } = route.data;
    const user: User = this.service.get();
    const userRole = user.role;
    if (!roles || (userRole && roles.includes(userRole))) {
      return true;
    }
    this.router.navigate([AUTH]);
    return false;
  }
}
