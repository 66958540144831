import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CommunicationService {
  private updateFindingsSource = new BehaviorSubject<boolean>(false);
  private updateCorrectiveActionsSource = new BehaviorSubject<boolean>(false);
  updateFindings$ = this.updateFindingsSource.asObservable();
  updateCorrectiveActions$ = this.updateCorrectiveActionsSource.asObservable();

  getFindingsObservable(): Observable<boolean> {
    return this.updateFindings$;
  }

  updateFindings() {
    this.updateFindingsSource.next(true);
  }

  getCorrectiveActionsObservable(): Observable<boolean> {
    return this.updateCorrectiveActions$;
  }

  updateCorrectiveActions() {
    this.updateCorrectiveActionsSource.next(true);
  }
}
