import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';
import { Client, CreateClient } from '../interfaces/client.interface';
import { IQuery } from '../interfaces';
import { ClientsResponse } from '../interfaces/clients-response.interface';

@Injectable({
  providedIn: 'root',
})
export class ClientsService extends BaseService {
  constructor(private http: HttpClient) {
    super();
  }

  create(data: any) {
    return this.http.post(`${this.url}/clients`, data);
  }

  read(params: IQuery = {}): Observable<ClientsResponse> {
    return this.http.get<ClientsResponse>(`${this.url}/clients`, {
      params,
    });
  }

  readOne(id: number) {
    return this.http.get(`${this.url}/clients/${id}`);
  }

  update(id: number, data: any) {
    return this.http.put(`${this.url}/clients/${id}`, data);
  }

  delete(id: number) {
    return this.http.delete(`${this.url}/clients/${id}`);
  }
}
