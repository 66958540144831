import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { CompaniesComponent } from './companies/companies.component';
import { AdminComponent } from './admin.component';
import { COMPANIES } from 'src/app/utils/urls';
import { Roles } from 'src/app/enums/enums';

const routes: Route[] = [
  {
    path: '',
    component: AdminComponent,
    children: [
      {
        path: COMPANIES,
        component: CompaniesComponent,
        data: {
          roles: [Roles.SUPER_ADMIN],
        },
      },
      {
        path: '**',
        redirectTo: COMPANIES,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AdminRoutingModule {}
